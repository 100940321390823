import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../partials/loading';
import { BottomCard, Button, H2, Input, Radio, TinyButton } from '../partials/ui'
import { QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back';
import arrowLeft from '../assets/img/arrow-left.svg';

const steps = [
  'countryOfTaxResidency',
  'firstName',
  'lastName',
  'countryOfCitizenship',
  'fundingSource',
  'dateOfBirth',
];

export default function AccountIdentity({ client, onIdentityCreate }) {
  const { returnTo } = QUERY.toObject(useLocation().search);
  const history = useHistory();
  const { step = steps[0] } = useParams();

  const [user, setUser] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingVisaCard, setIsShowingVisaCard] = useState(false);
  const [citizenshipFilter, setCitizenshipFilter] = useState('');
  const [visaData, setVisaData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
        try {
          const identity = await client.identities.get({ userId: user.id });
          setIdentity(identity);

          if (identity.countryOfCitizenship) {
            setCitizenshipFilter(identity.countryOfCitizenship);
          }
        } catch {} finally {
            setIsLoading(false);
        }
    })();
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const form = new FormData(e.target);

    if (step === 'countryOfTaxResidency' && form.get('countryOfTaxResidency') !== 'USA') {
      return setError('Sorry, but Share is only available for US tax residents at this time.');
    }

    const idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      return history.push(`/account/onboarding/identity/${steps[idx + 1]}`);
    }

    setIsDisabled(true);
    const data = {
      ...visaData,
      userId: user.id,
      legalFirstName: form.get('legalFirstName'),
      legalLastName: form.get('legalLastName'),
      countryOfCitizenship: form.get('countryOfCitizenship'),
      countryOfTaxResidency: form.get('countryOfTaxResidency'),
      fundingSource: [form.get('fundingSource')],
      dateOfBirth: form.get('dateOfBirth'),
    };

    try {
      const identity = await client.identities.update(data);
      if (onIdentityCreate) {
        onIdentityCreate({ identity });
      } else {
        history.push(`/account/onboarding${returnTo ? '?section=' + returnTo : ''}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  const onChangeCitizenship = (e) => {
    const { target: { value } } = e;
    if (value !== 'USA') {
      setIsShowingVisaCard(true);
    } else {
      setVisaData({});
    }
  }

  const onSubmitVisaCard = (data) => {
    setVisaData(data);
    setIsShowingVisaCard(false);
  }

  if (isLoading) return <Loading />;

  let title;
  switch (step) {
    case 'firstName':
      title = 'Legal first name.';
      break;
    case 'lastName':
      title = 'Legal last name.'; 
      break;
    case 'countryOfTaxResidency':
      title = 'Do you pay taxes in the United States?';
      break;
    case 'countryOfCitizenship':
      title = 'Where are you a citizen?';
      break;
    case 'fundingSource':
      title = 'Where does your money come from?';
      break;
    case 'dateOfBirth':
      title = 'When\'s your birthday?';
      break;
  }

  let subtitle = 'We are required to ask these question by the US government.';

  const stepIdx = steps.indexOf(step);

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Identity" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb5">
          <H2>{title}</H2>
          {subtitle && <div className="near-black">{subtitle}</div>}
        </div>

        <div className={`mv4 ${step === 'countryOfTaxResidency' ? '' : 'dn'}`}>
          <div className="pv3 bb b--light-gray">
            <Radio name="countryOfTaxResidency" value="USA" label="Yes" required={true} defaultChecked={identity?.countryOfTaxResidency === 'USA'} />
          </div>
          <div className="pv3 bb b--light-gray">
            <Radio name="countryOfTaxResidency" value="NA" label="No" required={true} />
          </div>
        </div>

        <div className={`mv4 ${step === 'firstName' ? '' : 'dn'}`}>
          <Input type="text" name="legalFirstName" label="First name" placeholder="Enter first name" autoFocus={!identity?.legalFirstName} defaultValue={identity?.legalFirstName || user.firstName} required={step >= 1} hint="This is usually the name on your driver's license." />
        </div>

        <div className={`mv4 ${step === 'lastName' ? '' : 'dn'}`}>
          <Input type="text"name="legalLastName" label="Last name" placeholder="Enter last name" defaultValue={identity?.legalLastName || user.lastName} required={step >= 2} hint="This is usually the name on your driver's license." />
        </div>

        <div className={`mv4 ${step === 'countryOfCitizenship' ? '' : 'dn'}`}>
          <Input type="text" name="countrySearch" placeholder="Start typing your country" defaultValue={identity?.countryOfCitizenship} onChange={(e) => setCitizenshipFilter(e.target.value.trim())} />
          {filterCountries(citizenshipFilter).map(({ name, value }) =>
            <div key={value} className="pv3 bb b--light-gray">
              <Radio name="countryOfCitizenship" value={value} label={name} required={stepIdx >= 3} defaultChecked={identity?.countryOfCitizenship === value} onChange={onChangeCitizenship} />
            </div>)}
        </div>

        <div className={`mv4 ${step === 'fundingSource' ? '' : 'dn'}`}>
          {fundingSources.map((source) => <div key={source} className="pv3 bb b--light-gray">
            <Radio name="fundingSource" value={source} label={_.startCase(source)} required={stepIdx >= 4} defaultChecked={identity?.fundingSource[0] === source} />
          </div>)}
        </div>

        <div className={`mv4 ${step === 'dateOfBirth' ? '' : 'dn'}`}>
          <Input type="date" name="dateOfBirth" label="When's your birthday?" required={stepIdx >= 5} defaultValue={identity?.dateOfBirth} /> 
        </div>

        <div className="mt4">
          <Button isDisabled={isDisabled} width="w-100">Continue</Button>
          {error && <div className="f7 mt1 dark-red">{error}</div>}
        </div>
      </form>
  </div>
  {isShowingVisaCard && <BottomCard dim onDismiss={() => setIsShowingVisaCard(false)}>
    <VisaCard onSubmit={onSubmitVisaCard} />
  </BottomCard>}
</div>;
}

function VisaCard({ onSubmit }) {
  const [departureDate, setDepartureDate] = useState();
  const [isPermanentResident, setIsPermanentResident] = useState();
  const [visaType, setVisaType] = useState();
  const [visaExpirationDate, setVisaExpirationDate] = useState();
  const [state, setState] = useState('greenCard');

  const onBack = () => {
    if (state === 'visaType') {
      setState('greenCard');
    } else if (state === 'expirationDate') {
      setState('visaType');
    } else if (state === 'departureDate') {
      setState('expirationDate');
    }
  }

  const next = () => {
    switch (state) {
      case 'greenCard':
        if (isPermanentResident === false) {
          return setState('visaType');
        } else {
          return onSubmit({ isPermanentResident });
        } 
      case 'visaType':
        return setState('expirationDate');
      case 'expirationDate':
        if (visaType === 'B1' || visaType === 'B2') {
          return setState('departureDate');
        } else {
          return onSubmit({ isPermanentResident, visaExpirationDate, visaType });
        }
      case 'departureDate':
        return onSubmit({ departureDate, isPermanentResident, visaExpirationDate, visaType });
    }
  }

  const isDisabled = (() => {
    switch (state) {
      case 'departureDate':
        return !departureDate;
      case 'greenCard':
        return isPermanentResident === undefined;
      case 'visaType':
        return !visaType;
      case 'expirationDate':
        return !visaExpirationDate;
    }
  })();

  return <div className="mw6 center">
    <div className="flex flex-row items-center justify-between mb4">
      <img src={arrowLeft} className={`pointer ${state === 'greenCard' ? 'o-0' : ''}`} onClick={onBack} />
      <div>
        <div className="tc f4 bold">Visa Status</div>
      </div>
      <div></div>
    </div>

    {state === 'greenCard' && <div className="">
      <div className="mb3">Are you a US permanent resident?</div>
      <div className="mb2">
        <Radio name="isPermanentResident" value="true" label="Yes" required={true} onChange={() => setIsPermanentResident(true)} defaultChecked={isPermanentResident === true} />
      </div>
      <div className="mb2">
        <Radio name="isPermanentResident" value="false" label="No" required={true} onChange={() => setIsPermanentResident(false)} defaultChecked={isPermanentResident === false} />
      </div>
    </div>}

    {state === 'visaType' && <div className="">
      <div className="mb3">What type of visa do you have?</div>
      {visaTypes.map((type) => <div key={type} className="mb2">
        <Radio name="visaType" value={type} label={type} required={true} onChange={() => setVisaType(type)} defaultChecked={visaType === type} />
      </div>)}
    </div>}

    {state === 'expirationDate' && <div className="">
      <div className="mb3">When does your visa expire?</div>
      <div className="mb2">
        <Input type="date" name="expirationDate" label="Expiration Date" required={true} onChange={(e) => setVisaExpirationDate(e.target.value)} defaultValue={visaExpirationDate} />
      </div>
    </div>}
    
    {state === 'departureDate' && <div className="">
      <div className="mb3">When is your departure date?</div>
      <div className="mb2">
        <Input type="date" name="departureDate" label="Departure Date" required={true} onChange={(e) => setDepartureDate(e.target.value)} defaultValue={departureDate} />
      </div>
    </div>}

    <div className="tc mt2">
      <TinyButton isDisabled={isDisabled} onClick={next}>Next</TinyButton>
    </div>
  </div>
}

const fundingSources = [
  'employment_income',
  'investments',
  'inheritance',
  'business_income',
  'savings',
  'family',
];

const filterCountries = (search) => {
  const searchLower = search.toLowerCase();
  return countries.filter((c) => {
    return c.name.toLowerCase().includes(searchLower) || c.value.toLowerCase().includes(searchLower);
  });
};

const countryListAlpha3 = {
  "AFG": "Afghanistan",
  "ALB": "Albania",
  "DZA": "Algeria",
  "ASM": "American Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarctica",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ABW": "Aruba",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivia (Plurinational State of)",
  "BES": "Bonaire, Sint Eustatius and Saba",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BVT": "Bouvet Island",
  "BRA": "Brazil",
  "IOT": "British Indian Ocean Territory",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CYM": "Cayman Islands",
  "CAF": "Central African Republic",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Christmas Island",
  "CCK": "Cocos (Keeling) Islands",
  "COL": "Colombia",
  "COM": "Comoros",
  "COD": "Congo (the Democratic Republic of the)",
  "COG": "Congo",
  "COK": "Cook Islands",
  "CRI": "Costa Rica",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CUW": "Curaçao",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "CIV": "Côte d'Ivoire",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FLK": "Falkland Islands [Malvinas]",
  "FRO": "Faroe Islands",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GUF": "French Guiana",
  "PYF": "French Polynesia",
  "ATF": "French Southern Territories",
  "GAB": "Gabon",
  "GMB": "Gambia",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Greece",
  "GRL": "Greenland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island and McDonald Islands",
  "VAT": "Holy See",
  "HND": "Honduras",
  "HKG": "Hong Kong",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran (Islamic Republic of)",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "PRK": "Korea (the Democratic People's Republic of)",
  "KOR": "Korea (the Republic of)",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Lao People's Democratic Republic",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MAC": "Macao",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands",
  "MTQ": "Martinique",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexico",
  "FSM": "Micronesia (Federated States of)",
  "MDA": "Moldova (the Republic of)",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands",
  "NCL": "New Caledonia",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolk Island",
  "MNP": "Northern Mariana Islands",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palestine, State of",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines",
  "PCN": "Pitcairn",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Qatar",
  "MKD": "Republic of North Macedonia",
  "ROU": "Romania",
  "RUS": "Russian Federation",
  "RWA": "Rwanda",
  "REU": "Réunion",
  "BLM": "Saint Barthélemy",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "MAF": "Saint Martin (French part)",
  "SPM": "Saint Pierre and Miquelon",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SXM": "Sint Maarten (Dutch part)",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "SGS": "South Georgia and the South Sandwich Islands",
  "SSD": "South Sudan",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan",
  "SUR": "Suriname",
  "SJM": "Svalbard and Jan Mayen",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syrian Arab Republic",
  "TWN": "Taiwan",
  "TJK": "Tajikistan",
  "TZA": "Tanzania, United Republic of",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TKM": "Turkmenistan",
  "TCA": "Turks and Caicos Islands",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates",
  "GBR": "United Kingdom of Great Britain and Northern Ireland",
  "UMI": "United States Minor Outlying Islands",
  "URY": "Uruguay",
  "USA": "United States of America",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela (Bolivarian Republic of)",
  "VNM": "Viet Nam",
  "VGB": "Virgin Islands (British)",
  "VIR": "Virgin Islands (U.S.)",
  "WLF": "Wallis and Futuna",
  "ESH": "Western Sahara",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe",
};

const countries = _.map(countryListAlpha3, (name, value) => ({ name, value }));

const visaTypes = [
  'B1',
  'B2',
  'DACA',
  'E1',
  'E2',
  'E3',
  'F1',
  'G4',
  'H1B',
  'J1',
  'L1',
  'O1',
  'TN1',
  'Other',
]