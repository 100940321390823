import moment from 'moment';

//shortTime takes a unix timestamp
export function shortTime(time) {
  let out = moment.unix(time).fromNow();
  out = out.replace(/^a few seconds ago/gi, 'now');
  out = out.replace(' ago', '');
  out = out.replace(/^a(n?)/, '1');
  out = out.replace(/second(s?)/g, 's');
  out = out.replace(/minute(s?)/g, 'min');
  out = out.replace(/hour(s?)/g, 'h');
  out = out.replace(/day(s?)/g, 'd');
  out = out.replace(/month(s?)/g, 'm')
  out = out.replace(/year(s?)/g, 'y')
  out = out.replace(/\s/g, '');
  return out;
}