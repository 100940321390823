import React, { useEffect, useState } from 'react';
import { Button, Modal } from './ui';

export default function NotificationsModal({ client, device, onDismiss, title, subtitle }) {
  const [isPending, setIsPending] = useState(false);
  const [result, setResult] = useState('');

  useEffect(() => {
    window.localStorage.setItem('notificationsModalShownAt', (new Date()).getTime());
  }, []);

  const onAllow = (e) => {
    setIsPending(true);

    let i = 0;
    const checkDevice = async () => {
      try {
        const d = await client.devices.getOne({ token: device.token });
        if (d?.status !== 'authorized') {
          throw new Error('not authorized');
        } else {
          setIsPending(false);
          setResult('success');
        }
      } catch {
        if (++i === 3) {
          //3 attempts, consider failed
          setIsPending(false);
          setResult('failure');
        } else {
          window.setTimeout(checkDevice, 2000);
        }
      }
    };

    window.setTimeout(checkDevice, 2000);
  };

  return <Modal>
    {!result && <div className="pa4 bg-white">
      <div className="f3">{title}</div>
      <div className="f5 lh-copy mt3">{subtitle}</div>
      <div className="mt">
        <a href="share:enable-notifications" onClick={onAllow}>
          <Button width="w-100" isDisabled={isPending}>Allow Notifications</Button>
        </a>
        <Button width="w-100" outline="none" className="mt3" onClick={onDismiss} isDisabled={isPending}>Not now</Button>
      </div>
    </div>}
    {result === 'success' && <div className="pa4 bg-white">
      <div className="f3">Notifications enabled!</div>
      <Button width="w-100" className="mt3" onClick={onDismiss}>Ok</Button>
    </div>}
    {result === 'failure' && <div className="pa4 bg-white">
      <div className="f3">Notifications failed.</div>
      <Button width="w-100" className="mt3" onClick={onDismiss}>Ok</Button>
      </div>}
  </Modal>
}