import React, { useCallback, useState } from 'react';
import { Button } from './ui';
import Logo from './logo-mark';
import Nav from './nav';
import xIcon from '../assets/img/x.svg';

export default function EnableNotifications({ client, msg, onDismiss, title }) {
  const [isPending, setIsPending] = useState(false);
  const [result, setResult] = useState(null);

  const onAllow = (e) => {
    setIsPending(true);
    let i = 0;
    const checkDevice = async () => {
      try {
        const [device] = await client.devices.get();
        if (!device) {
          throw new Error(`no device`);
        } else {
          setIsPending(false);
          setResult('success');
        }
      } catch {
        if (++i === 3) {
          //3 attempts, consider failed
          setIsPending(false);
          setResult('failure');
        } else {
          window.setTimeout(checkDevice, 2000);
        }
      }
    };

    window.setTimeout(checkDevice, 2000);
  };

  const RightButton = useCallback(() => {
    return <img src={xIcon} onClick={onDismiss} />;
  }, [onDismiss]);

  return <div className="w-100 min-vh-100">
    <Nav client={client} title="" rightButton={RightButton} footer={false} />
    <div className="w-100-ns w-90 center flex flex-column align-center justify-center pt6">
      <div className="tc mb2">
        <Logo scale={5} />
      </div>
      {result === null && <>
        <div className="f3 bold tc lh-title">{title || 'Stay up to date on your investments.'}</div>
        <div className="f6 lh-copy tc mt2">{msg || `Get notified about proposals, investments, and dividends.`}</div>
        <div className="tc mt4 ba b--black br4 overflow-hidden">
          <div className="ph3">
            <div className="tc f4 lh-copy mt3">
              Share would like to send you notifications.
            </div>
            <div className="mt2 f5 lh-copy">
              Get notified about transfers, investments, and dividends. No spam, ever!
            </div>
          </div>
          <div className="flex flex-row bt mt">
            <div className="br w-50 tc pv" onClick={onDismiss}>
              Don't Allow
            </div>
            <a className="w-50 pv bg-light-blue black no-underline" href="share:enable-notifications" onClick={onAllow}>
              <div className="tc">
                Allow
              </div>
            </a>
          </div>
        </div>
      </>}
      {result === 'success' && <>
        <div className="f3 bold tc lh-title">Notifications enabled {String.fromCodePoint(0x2705)}</div>
        <div className="f6 lh-copy tc mt2">{msg}</div>
        <div className="tc mt4">
          <Button width="w-100" onClick={onDismiss}>Done</Button>
        </div>
      </>}
      {result === 'failure' && <>
        <div className="f4 bold tc">Notifications failed</div>
        <div className="f6 lh-copy tc mt2">Permission was not granted. You can enable in your device settings.</div>
        <div className="tc mt4">
          <Button onClick={onDismiss}>Done</Button>
        </div>
        </>}
    </div>
  </div>;
}