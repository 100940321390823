import { request } from '../http';

export async function getObject({ bucket, path }) {
  if (!bucket || !path) throw new Error(`bucket and path are required`);

  const { response, data } = await request({
    url: `https://${bucket}.s3.amazonaws.com${path}`,
    method: 'GET',
  });

  if (process.env.NODE_ENV === 'development') {
    console.log({
      bucket,
      path,
    });

    console.log(data);
  }

  if (response.ok) {
    return data;
  }

  throw new Error(data.error || 'Unknown error');
}

export async function getMeta({ bucket, path }) {
  if (!bucket || !path) throw new Error(`bucket and path are required`);

  const response = await request({
    url: `https://${bucket}.s3.amazonaws.com${path}`,
    method: 'HEAD',
    json: false,
  });

  const meta = {};
  for (let key of response.headers.keys()) {
    if (key.startsWith(`x-amz-meta-`)) {
      meta[key.replace(`x-amz-meta-`, ``)] = response.headers.get(key);
    }
  }

  return meta;
}