import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Autolinker from 'autolinker';
import _ from 'lodash';
import AssetLogo from './asset-logo';
import Nav from './nav';
import SignUpModal from './sign-up-modal';
import { BigNumber, BottomCard, BubbleNumber, Button, H2, Loader, Modal } from './ui';
import arrowLeft from '../assets/img/arrow-left.svg';
import chevronRight from '../assets/img/chevron-right-white.svg';
import xIcon from '../assets/img/x.svg';
import { MONEY, S3 } from '../../utils';

const prettyDuration = (d) => {
  const value = parseInt(d[0]);
  const unit = d[1] === 'y' ? 'year' : 'month';
  return value === 1 ? `${value} ${unit}` : `${value} ${unit}s`;
}

export default function Template({ client, cta = 'Invest', canEditAmount = true, nav = true, signUpGate = false, template, onBack = _.noop, onClone = _.noop, onClose }) {
  const { id } = template;
  const [user, setUser] = useState(false);
  const [benchmarkCompany, setBenchmarkCompany] = useState(null);
  const [benchmarkData, setBenchmarkData] = useState(null);
  const [simulation, setSimulation] = useState(null);
  const [simulationData, setSimulationData] = useState([]);
  const [duration, setDuration] = useState('5y');
  const [isLoading, setIsLoading] = useState(true);
  const [isShowingSignUpModal, setIsShowingSignUpModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch (e) {
        console.debug(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const simulation = await client.simulations.get({ templateId: template.id });
        const simulationData = await S3.getObject({ bucket: simulation.bucket, path: simulation.path });
        setSimulation(simulation);
        setSimulationData(simulationData);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    const { benchmarkTicker: ticker } = template;
    if (!ticker) return;

    (async () => {
      try {
        client.companies.get(ticker).then(setBenchmarkCompany);
        const simulation = await client.simulations.get({
          reinvestDividends: true,
          tickers: [ticker],
        });

        setBenchmarkData(await S3.getObject({ bucket: simulation.bucket, path: simulation.path }));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [template]);

  const BackButton = useCallback(() => {
    return  <img src={arrowLeft} className="pointer" onClick={onBack} />;
  }, [onBack]);

  const _onClone = (e) => {
    if (!user && signUpGate) {
      setIsShowingSignUpModal(true);
    } else {
      onClone(e);
    }
  };

  const data = simulationData.find(s => s.duration === duration);
  const positions = _.keyBy(data?.positions, 'ticker');

  return <div className="w-100">
    {nav && <Nav client={client} leftButton={BackButton} footer={false} />}
    {!nav && _.isFunction(onClose) && <div className="pa tr">
      <img src={xIcon} className="pointer" onClick={onClose} />
    </div>}
    <div className={`w-100 center mt2 mt${nav ? 6 : 0}-ns ph ph0-ns`} style={{maxWidth: 700, paddingBottom: 100}}>
      <H2>{template.name}</H2>
      <div className="f5-ns f6 lh-copy mid-gray measure-wide-ns" dangerouslySetInnerHTML={{__html: Autolinker.link(template.description)}}></div>

      <div className="mt3">
        <PortfolioPerformance
          benchmark={benchmarkData?.find(s => s.duration === duration)}
          benchmarkCompany={benchmarkCompany}
          isLoading={isLoading}
          simulation={data}
          duration={duration}
          durations={['5y', '3y', '1y']}
          onChangeDuration={setDuration} />
      </div>

      <div style={{marginTop: 32}}>
        <div className="f4 bold mb1">Stocks {template.templateCompanies.some(tc => tc.company.isETF) ? 'and ETFs' : ''}</div>
        <div className="f6 mid-gray lh-copy mb3">
          If you invest in this strategy, the securities below will be bought in equal weight every Monday.
        </div>
        {template.templateCompanies.map((tc, idx) =>
          <Position templateCompany={tc} position={positions[tc.ticker]} key={`${tc.id}`} idx={idx} />)}
      </div>
      {Boolean(data) && <div className="mid-gray f7 lh-copy mt2">
          These returns are a backwards looking simulation for informational purposes only, and are not a performance projection. Past performance does not guarantee future returns.
      </div>}
    </div>

    <div className="fixed w-100 bg-white bottom-0 left-0 pa" style={{paddingBottom: 20}}>
      <div className="center" style={{maxWidth: 700}}>
        <Button width="w-100" onClick={_onClone}>{cta}</Button>
      </div>
    </div>
    {isShowingSignUpModal && <Modal fullscreen fade={false} onDismiss={() => setIsShowingSignUpModal(false)}>
      <SignUpModal
        client={client}
        simulation={simulation}
        template={template}
        onDismiss={() => setIsShowingSignUpModal(false)}
      />
    </Modal>}
  </div>
}

export function PortfolioPerformance({ benchmark, benchmarkCompany, canEditAmount, duration, durations = ['5y', '3y', '1y'], isLoading, onChangeDuration = _.noop, simulation }) {
  const [weekly, setWeekly] = useState(2000);
  const [details, setDetails] = useState('');

  if (!simulation) {
    return <div className="w-100 flex flex-column tc">
      <div className={`bg-white ba b--light-gray br5 pv4`}>
        <BigNumber color="silver">-.--%</BigNumber>
        <div className={`mt2 f5 center silver`}>Past {prettyDuration(duration)}</div>
      </div>
    {isLoading && <Loader className="w-50 center" />}
  </div>;
  }

  const isUp = parseFloat(simulation.dollarReturn) >= 0;
  let bgColor;
  if (simulation.costBasis === '0') {
    bgColor = 'light-gray';
  } else if (isUp) {
    bgColor = 'cyan'
  } else {
    bgColor = 'orange';
  }

  const factor = weekly / simulation.weeklyInvestment;

  const percentFormatter = (n, usePrefix = true) => {
    const decimals = Math.abs(n) < 10 ? 2 : 1;
    return `${n < 0 || !usePrefix ? '' : '+'}${n.toFixed(decimals)}%`;
  }

  const setSummaryDetails = () => {
    setDetails({
      title: `Performance Summary`,
      text: `If you had invested ${MONEY.formattedDollars(simulation.weeklyInvestment/100, { wholeDollar: true })} evenly in these stocks every Monday for the past ${prettyDuration(duration)}, you'd have invested ${MONEY.formattedDollars(parseFloat(simulation.costBasis))} and have ${MONEY.formattedDollars(parseFloat(simulation.marketValue))} today. These historical returns are for informational purposes only and do not represent the performance of any actual investment. Past performance does not guarantee future returns.`,
    });
  }

  const onIncrement = (e) => {
    e.stopPropagation();
    setWeekly(Math.min(weekly + 500));
  }

  const onDecrement = (e) => {
    e.stopPropagation();
    setWeekly(Math.max(weekly - 500, 500));
  }
  
  return <div>
    <div className="f6 gray mb1">Summary</div>
    <div className="flex flex-column items-center justify-center bg-hot-pink tc pa3" style={{borderRadius: 20}} onClick={setSummaryDetails}>
      <div className="mt4">
        <div className="white f6 relative">If you had invested</div>

        <div className="flex flex-row items-center justify-center">
          {canEditAmount && <div className="bg-white br-100 flex items-center justify-center bold f3 pointer" style={{width: 40, height: 40}} onClick={onDecrement}>
            -
          </div>}
          <div className="bg-white black bold f3 br-pill pa2 ma2">
            {MONEY.formattedDollars(weekly/100, { wholeDollar: true })}/week
          </div>
          {canEditAmount && <div className="bg-white br-100 flex items-center justify-center bold f3 pointer" style={{width: 40, height: 40}} onClick={onIncrement}>
            +
          </div>}
        </div>
      </div>

      <div className="mt4">
        <div className="white f6 relative">today you'd have</div>
        <div className="bg-white black bold f3 br-pill pa2 mv2">{MONEY.formattedDollars(parseFloat(simulation.marketValue) * factor)}</div>
      </div>

      <div className="w-100 f6 white mt3 flex items-center justify-between">
        <div>Based on 5 years</div>
        <div className="f6 bold flex flex-row items-center justify-center">
          <div>See details</div>
          <img className="ml1" src={chevronRight} style={{marginTop: 2, width: 6}} />
        </div>
      </div>
    </div>

    <div className="f6 gray mb1 mt">Breakdown</div>
    <div className="flex flex-row mb1">
      <BubbleNumber
        color={bgColor}
        number={percentFormatter(simulation.percentReturn * 100)}
        subtitle={`in ${prettyDuration(duration)}`}
        onClick={() => setDetails({ title: 'Percent return', text: `This is the total return on your investment. Calculated as the total profit (or loss) divided by the total amount invested, expressed as a percent.` })}
        width="w-50"
      />
      <BubbleNumber
        color={bgColor}
        number={percentFormatter(simulation.annualizedReturn * 100)}
        subtitle="annually"
        onClick={() => setDetails({ title: 'Annualized return', text: `This is the percent return expressed on a per-year basis. Because new investments are being made each week, it takes into account the duration of each investment.` })}
        width="w-50"
      />
    </div>

    {simulation.dividendReturn != "0" && <div>
      <div className="flex flex-row mb1">
        <BubbleNumber
          color="bright-mint"
          number={MONEY.formattedDollars(simulation.dividendReturn)}
          subtitle="in dividends"
          onClick={() => setDetails({ title: 'Dividend return', text: `This is the total amount you'd have received in dividend payments after ${prettyDuration(simulation.duration)} of investing ${MONEY.formattedDollars(simulation.weeklyInvestment / 100)} per week.` })}
          width="w-50"
        />
        <BubbleNumber
          color="bright-mint"
          number={percentFormatter(simulation.dividendYield * 100, false)}
          subtitle="dividend yield"
          onClick={() => setDetails({ title: 'Dividend yield', text: `This is the annualized return applied to dividend payments. The annual dividend payment expressed as a percent of the capital invested.` })}
          width="w-50"
        />
      </div>  
    </div>}

    {simulation.history.length > 0 && <>
      <PortfolioChart benchmark={benchmark?.history} duration={duration} durations={durations} history={simulation.history} onChangeDuration={onChangeDuration} />
      {Boolean(benchmarkCompany) && <div className="gray mb1 mt tr" style={{fontSize: 13}}>Benchmark: {benchmarkCompany.displayName}</div>}
    </>}

    {Boolean(details) && <BottomCard dim>
      <div className="center-ns" style={{maxWidth: 500}}>
        <div className="f4 bold">{details.title}</div>
        <div className="f5 lh-copy mv3">{details.text}</div>
        <Button outline={true} width="w-100" onClick={() => setDetails(null)}>dismiss</Button>
      </div>
    </BottomCard>}
  </div>
}

function PortfolioChart({ backgroundColor = 'white', benchmark, duration, durations, history: chart, mode = 'percent', onChangeDuration = _.noop }) {
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        type: benchmark ? 'time' : undefined,
      }],
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: (tooltipItem) => {
          const historicalValue = chart[tooltipItem.index];
          return `${MONEY.formattedDollars(parseFloat(historicalValue.marketValue))} (${tooltipItem.value >= 0 ? '+' : ''}${tooltipItem.value}%)`;
        },
      }
    }
  };

  const borderWidth = (() => {
    if (chart.length > 200) {
      return 1;
    }
    if (chart.length > 100) {
      return 2;
    }
    return 3;
  })();

  const getData = (chart) => {
    if (benchmark) {
      return chart.map(d => ({
        x: d.at,
        y: (d.percentReturn * 100).toFixed(2),
      }));
    }

    return chart.map(d => parseFloat(d.percentReturn * 100).toFixed(2));
  }

  const color = 'black';
  const data = {
    labels: chart.map(d => d.at),
    datasets: [{
        label: 'one',
        fill: false,
        lineTension: 0.3,
        backgroundColor: color,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: getData(chart),
      },
    ]
  };

  if (benchmark) {
    data.datasets.push({
      label: 'two',
      fill: false,
      lineTension: 0.3,
      backgroundColor: '#CCCCCC',
      borderColor: '#CCCCCC',
      borderCapStyle: 'butt',
      borderDash: [1],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      borderWidth,
      pointBorderColor: color,
      pointBackgroundColor: color,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 10,
      data: getData(benchmark),
    });
  }

  const { max, maxPosition, min, minPosition } = (() => {
    if (!chart || chart.length <= 1) return {};

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let maxIdx, minIdx;
    chart.forEach((d, idx) => {
      const value = parseFloat(d.percentReturn);
      if (value < min) {
        min = value;
        minIdx = idx;
      }
      if (value > max) {
        max = value;
        maxIdx = idx;
      }
    });

    const bound = (x) => Math.max(Math.min(x, 90), 5);
    const maxPosition = Boolean(max) ? `${bound(maxIdx / (chart.length - 1) * 100)}%` : undefined;
    const minPosition = Boolean(min) ? `${bound(minIdx / (chart.length - 1) * 100)}%` : undefined;

    const prettify = (idx) => {
      const d = chart[idx];
      const signPrefix = d.dollarReturn >= 0 ? '+' : '';
      return mode === 'dollars' ? `${MONEY.formattedDollars(d.marketValue)}` : `${signPrefix}${(d.percentReturn * 100).toFixed(2)}%`;
    }

    return {
      max: prettify(maxIdx), 
      maxPosition,
      min: prettify(minIdx),
      minPosition,
    };
  })();

  return <div className="mt4">
    <div className={`f6 mono tl ${maxPosition ? 'near-black' : backgroundColor}`} style={{paddingLeft: maxPosition}}>
      {max}
    </div>
    <div style={{height: 150}}>
      <Line data={data} options={options} />
    </div>
    <div className={`f6 mono tl ${minPosition ? 'near-black' : backgroundColor}`} style={{paddingLeft: minPosition}}>
      {min}
    </div>
    {duration && <div className="flex flex-row items-center justify-around mt3 mh3" onClick={e => e.stopPropagation()}>
      {durations.map((d) =>
        <div key={d} className={`pointer f6 ph3 pv2 bb bw2 ${duration === d ? `b--black` : `b--${backgroundColor}`}`} onClick={() => onChangeDuration(d)}>{d}</div>)}
    </div>}
  </div>
}

function Position({ display, idx, position, templateCompany }) {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const { company, notes } = templateCompany;

  const text = (() => {
    if (!position) return '$-.--';

    let text;
    const isUp = position.percentReturn >= 0;
    const prefix = isUp ? '+' : '';
    if (display === 'marketValue') {
      text = MONEY.formattedDollars(position.marketValue);
    } else if (display === 'percentReturn') {
      text = `${prefix}${(position.percentReturn * 100).toFixed(2)}%`;
    } else if (display === 'dollarReturn') {
      text = `${prefix}${MONEY.formattedDollars(position.dollarReturn)}`;
    } else {
      text = `${prefix}${(position.percentReturn * 100).toFixed(2)}%`;
    }

    return text;
  })();

  const backgroundColor = (() => {
    if (!position) return 'light-gray';
    if (position.percentReturn.startsWith('-')) return 'orange';
    return 'cyan';
  })();

  const toggleNotes = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsNotesOpen(!isNotesOpen);
  }

  return <div className="ba br5 ph3 pv b--light-gray" style={{marginTop: idx > 0 ? -1 : 0}}>
    <Link className="black no-underline" to={`/stocks/${company.ticker}`} rel="nofollow">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center justify-start">
          <div className="flex mr2">
            <AssetLogo company={company} size={40} />
          </div>
          <div>
            <div className="f7 mb1 mid-gray">{company.ticker}</div>
            <div className="near-black f5 bold">{company.displayName}</div>
          </div>
        </div>
        <div className="tr">
          <div className={`bg-${backgroundColor} pa2 f6 br3`}>{text}</div>
        </div>
      </div>
      {notes && <div className={`f6 mid-gray lh-copy mt2  ${isNotesOpen ? '' : 'clamp-3'}`} onClick={toggleNotes}>{notes}</div>}
    </Link>
  </div>;
}
