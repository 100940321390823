import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import BackButton from './partials/button-back';
import Loading from './partials/loading';
import Nav from './partials/nav';
import { Button, Loader, NumberPad, TinyButton } from './partials/ui';
import { MONEY, QUERY } from '../utils';

const displayAmount = (amount) => {
  let text = amount || '0';
  return <>{text}<sup style={{ fontSize: '60%', top: '-0.75em' }}>%</sup></>;
}

export default function NewGroupSell({ client }) {
  const history = useHistory();
  const { search } = useLocation();
  const query = QUERY.toObject(search);
  const params = useParams();
  const { groupId, groupSellId } = params;
  const [group, setGroup] = useState(null);
  const [groupPositions, setGroupPositions] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [hasPendingGroupSell, setHasPendingGroupSell] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [amount, setAmount] = useState('');
  const [state, setState] = useState('amount');
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { isMarketOpen } = await client.calendar.isMarketOpen();
        setIsMarketOpen(isMarketOpen);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const [
        group,
        groupPositions,
      ] = await Promise.all([
        client.groups.getOne({ id: groupId }),
        client.positions.get({ groupId }),
      ]);

      setGroup(group);
      setGroupPositions(groupPositions);
      setPortfolio(await client.portfolios.getSummary({ groupId }));

      try {
        const [groupSell] = await client.groupSells.get({ groupId, limit: 1 });
        setHasPendingGroupSell(groupSell.status === 'pending_orders' || groupSell.status === 'orders_pending');
      } catch {}

      //make sure they don't have an active rule
      const rules = await client.rules.get({ groupId, });
      if (rules.length > 0 && rules[0].status === 'active') {
        history.push(`/`);
      }
    })();
  }, [groupId]);

  useEffect(() => {
    if (!groupSellId) return;
    setTimeout(async () => {
      const next = `/history/group-sells/${groupSellId}?returnTo=${query.returnTo || '/portfolio'}`;
      history.replace(next);
    }, isMarketOpen ? 3000 : 1000);
  }, [groupSellId, isMarketOpen]);

  const next = async () => {
    if (state === 'amount') {
      setState('review');
      return;
    }

    try {
      setIsSending(true);

      const params = {
        groupId: group.id,
        percent: parseInt(amount, 10),
      };

      const groupSellUser = await client.groupSells.create(params);
      history.replace(`/group-sells/new/${groupId}/${groupSellUser.id}`);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  if (!group) return <Loading />;

  if (groupSellId) {
    return <div className="w-100 vh-100">
      <Nav leftButton={BackButton} client={client} title={group.name} footer={false} />
      <div style={{height: 'calc(100% - 40px)'}}>
        <div className="w-50 mt6 center">
          <Loader />
        </div>
        <div className="mt6 f3 bold tc">Sale processing.</div>
      </div>
    </div>
  }


  const canSell = groupPositions?.length > 0;

  //determine the minimum amount they can sell
  const percentages = [100, 75, 50, 25, 10, 1];
  let minimum = 100;
  for (let i = 1; i < percentages.length; i++) {
    const percent = percentages[i];
    if (groupPositions?.some((p) => p.quantity * (percent / 100) < .001)) {
      break;
    }
    minimum = percent;
  }

  const options = [25, 50, 75, 100].filter((p) => p >= minimum);

  const isAmountValid = (() => {
    if (!canSell) return false;
    if (amount === '' || amount === 0) return false;
    const intAmount = parseInt(amount, 10);
    return intAmount >= minimum && intAmount <= 100;
  })();

  return <div className="absolute absolute--fill">
    <Nav leftButton={BackButton} client={client} title={`Sell ${group.name}`} footer={false} />
    <div className="pt3 pt6-ns relative center" style={{height: 'calc(100% - 40px)', maxWidth: 700}}>
      <div className="flex flex-column h-100 w-100 justify-between" style={{bottom: 20}}>
        <div className="mt3">
          <div className={`flex flex-row items-center justify-center mt4`} style={{paddingLeft: 24, paddingRight: 24}}>
            <div>
              <div className={`bold`} style={{fontSize: 72}}>{displayAmount(amount)}</div>
              {state === 'amount' && <div className="mid-gray mt2 tc">{minimum}% minimum</div>}
              {state === 'review' && <div className="mid-gray mt2 tc">sale</div>}
            </div>
          </div>
        </div>

        <div style={{paddingLeft: 24, paddingRight: 24}}>
          {state === 'amount' && <>
            {Boolean(portfolio) && <div className="f5 tc mid-gray mb4">
              {Boolean(amount) && <>{MONEY.formattedDollars(portfolio.marketValue * (amount/100))} estimated proceeds.</>}
              {!amount && <>{MONEY.formattedDollars(portfolio.marketValue)} in {group.name}.</>}
            </div>}
            {options?.length > 0 && <div className="flex flex-row items-center justify-around mb3">
              {options.map((p) =>
                <TinyButton key={p} width="auto" onClick={() => setAmount(`${p}`)}>{p}%</TinyButton>)}
              </div>}
            <NumberPad allowDecimal={false} amount={amount} onChange={setAmount} max={100} isDisabled={!canSell} />
          </>}
          <div className="center mt3 mb">
            {state === 'review' && <div className="f5 lh-copy tc mid-gray mb">
              You are selling {displayAmount(amount)} of each of your {group.name} positions. Each position will be sold at the best available market price. Proceeds will be deposited to your account and available for withdrawal or reinvestment.
            </div>}
            {hasPendingGroupSell && <div className="f5 lh-copy tc mid-gray mb4">
              You already have a pending sale for {group.name}. Please wait until it completes.
            </div>}
            {!hasPendingGroupSell && <Button isDisabled={!isAmountValid || isSending} width={`w-100`} onClick={next}>
              {state === 'amount' ? 'Review' : 'Confirm'}
            </Button>}
            {state === 'review' && <div className="mt2">
              <Button width="w-100" outline="none" onClick={() => setState('amount')}>Cancel</Button> 
            </div>}
            {state === 'amount' && !hasPendingGroupSell && <>
              {canSell && isMarketOpen === false && <div className="mt2 tc f7 silver">Market is currently closed, orders will be queued.</div>}
              {!canSell && <div className="mt2 tc f7 silver">You don't have any positions in this strategy.</div>}
            </>}
            {error && <div className="mt2 tc f7 red">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  </div>
}
