import React from 'react';

export default function Tabs({ tabs, onSelect, selectedIdx }) {
  const onClick = (e) => {
    const { target: { dataset: { idx } } } = e;
    if (onSelect) {
      onSelect(parseInt(idx));
    }
  }

  return <div className="flex flex-row items-center justify-start overflow-scroll bb b--moon-gray">
    {tabs.map((t, idx) => 
      <div key={idx} className={`f6 ph3 pv2 bb bw2 ${idx === selectedIdx ? 'bold b--black' : 'gray b--white'}`} data-idx={idx} onClick={onClick}>{t}</div>
    )}
  </div>
}