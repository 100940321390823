import _ from 'lodash';

export function toObject(query) {
  if (!query) {
    return {};
  }
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      return {
        ...params,
        [key]: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
      };
    }, {});
}

// This function takes an object and turns it into a query string starting with ?
export function fromObject(object) {
  if (_.isEmpty(object)) {
    return '';
  }
  const clean = o => _.pickBy(o, value => value !== undefined);
  return `?${_.join(_.map(clean(object), (value, key) => `${key}=${value}`), '&')}`;
}

export function addKey(query, key, value) {
  const object = toObject(query);
  object[key] = value;
  return fromObject(object);
}

export function removeKey(query, key) {
  const object = toObject(query);
  if (object.hasOwnProperty(key)) {
    delete object[key];
  }
  return fromObject(object);
}

export function get(key) {
  const q = toObject(window.location.search);
  if (q.hasOwnProperty(key)) {
    return q[key];
  }
  return null;
}