import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Decimal from 'decimal.js';
import moment from 'moment';
import useInfoCard from './hooks/info-card';
import AssetLogo from './partials/asset-logo';
import BackButton from './partials/button-back';
import Loading from './partials/loading';
import Nav from './partials/nav';
import { PortfolioPerformance } from './partials/template';
import { EditGroup } from './investments';
import VoteButton from './partials/vote-button';
import arrow from './assets/img/arrow-black.svg';
import arrowLeft from './assets/img/arrow-left.svg';
import chevronDown from './assets/img/chevron-down.svg';
import chevronRight from './assets/img/chevron-right.svg';
import edit from './assets/img/edit.svg';
import info from './assets/img/info.svg';
import moneyBag from './assets/img/money-bag-black.svg';
import moneyBagPink from './assets/img/money-bag-pink.svg';
import plus from './assets/img/plus.svg';
import trash from './assets/img/trash.svg';
import vote from './assets/img/vote.svg';
import x from './assets/img/x.svg';
import { Avatar, BigNumber, BottomCard, Button, Input, NumberPad, RatingBadge, TinyButton } from './partials/ui';
import { MONEY, QUERY, S3 } from '../utils';
import NotificationsModal from './partials/notifications-modal';

export default function Group({ client, isMobile }) {
  const history = useHistory();
  const { groupId } = useParams();
  const [device, setDevice] = useState(null);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const group = await client.groups.getOne({ id: groupId });
        if (group.groupUsers?.length > 0) {
          group.groupUsers.sort((a, b) => b.investingStreak - a.investingStreak);
        }
        setGroup(group);
      } catch (e) {
        console.error(e);
        history.replace('/');
      }
      if (isMobile) {
        try {
          const [device] = await client.devices.get();
          setDevice(device);
        } catch {}
      }
    })();
  }, []);

  if (!group) return <Loading />;
  if (!group.membership) return <GroupNonMemberView client={client} group={group} />;
  return <GroupMemberView client={client} group={group} setGroup={setGroup} device={device} />;
}

function GroupMemberView({ client, device, group, setGroup }) {
  const history = useHistory();
  const { propose: proposeQuery } = QUERY.toObject(useLocation().search);
  const { groupId } = useParams();
  const [account, setAccount] = useState(null);
  const [groupCompanies, setGroupCompanies] = useState(null);
  const [positions, setPositions] = useState({});
  const [isLeaving, setIsLeaving] = useState(false);
  const [isShowingContributionModal, setIsShowingContributionModal] = useState(false);
  const [isShowingLeaveCard, setIsShowingLeaveCard] = useState(false);
  const [isUpdatingContribution, setIsUpdatingContribution] = useState(false);
  const [isShowingNotificationsPrompt, setIsShowingNotificationsPrompt] = useState(false);
  const [isShowingRuleCard, setIsShowingRuleCard] = useState(false);
  const [proposing, setProposing] = useState(null);
  const [proposals, setProposals] = useState(null);
  const [rules, setRules] = useState(null);
  const [user, setUser] = useState(null);

  const [ProposalsInfo, ProposalsInfoButton] = useInfoCard('Proposals');
  const [RatingsInfo, RatingsInfoButton] = useInfoCard('Stocks');

  useEffect(() => {
    (async () => {
      try {
        setAccount(await client.accounts.getMine());
      } catch {
        //assume this means brokerage account isn't setup yet
      }

      setUser(await client.users.getMe());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const [
        groupCompanies,
        positions,
        proposals,
      ] = await Promise.all([
        client.groupCompanies.get({ groupId }),
        client.positions.get({ groupId }),
        client.proposals.get({ groupId, isLive: true }),
      ]);

      setGroupCompanies(groupCompanies);
      setPositions(_.keyBy(positions, 'ticker'));
      setProposals(proposals);
    })();
  }, [groupId]);

  useEffect(() => {
    if (!groupCompanies) return;
    if (group?.membership && proposeQuery) {
      const groupCompany = groupCompanies.find((gc) => (gc.ticker === proposeQuery));
      if (groupCompany) {
        setProposing(groupCompany);
      }
    }
  }, [group, groupCompanies]);

  useEffect(() => {
    if (!isShowingContributionModal) {
      setIsUpdatingContribution(false);
    }
  }, [isShowingContributionModal]);

  useEffect(() => {
    (async () => {
      setRules(await client.rules.get({ groupId }));
    })();
  }, [isShowingRuleCard]);

  if (!group || !user) return <Loading />;

  const onProposeSuccess = (proposal) => {
    setProposals(proposals => [proposal, ...proposals]);
    setProposing(null);
  }

  const onRateSuccess = async () => {
    setProposing(null);
    setGroupCompanies(await client.groupCompanies.get({ groupId }));
  }

  const leaveGroup = async () => {
    try {
      setIsLeaving(true);
      await client.groups.leave({ groupId: group.id, userId: user.id });
      history.goBack();
    } catch (err) {
      setDeleteError(err.message)
    } finally {
      setIsLeaving(false);
    }
  }

  const {
    buyMessage,
    canBuy,
  } = (() => {
    return {
      buyMessage: `Investing is currently restricted to liquidation only.`,
      canBuy: false,
    };

    const hasCompanies = groupCompanies?.some(gc => gc.rating === 'buy');
    const canBuy = account?.isActive && account?.isFundable && hasCompanies;
    let buyMessage;

    if (!canBuy) {
      if (!account) {
        buyMessage = 'You need to complete your account setup to invest.';
      } else if (!account.isActive) {
        buyMessage = 'Your brokerage account is pending activation.';
      } else if (!account.isFundable) {
        buyMessage = 'Your account does not yet have an active payment method.';
      } else if (!hasCompanies) {
        buyMessage = `${group.name} has no stocks to buy.`;
      }
    }

    return {
      buyMessage,
      canBuy,
    };
  })();

  const {
    canSell,
    sellMessage,
  } = (() => {
    const hasRule = rules?.length > 0;
    const hasPositions = Object.keys(positions).length > 0;
    const hasCompanies = groupCompanies?.some(gc => gc.rating !== 'sell');
    const canSell = !hasRule && hasPositions && hasCompanies;
    let sellMessage;

    if (!canSell) {
      if (hasRule) {
        sellMessage = 'Disable automatic profit taking to sell manually.';
      } else if (!hasPositions) {
        sellMessage = 'You have no stocks to sell.';
      } else if (!hasCompanies) {
        sellMessage = `${group.name} has no stocks to sell.`;
      }
    }

    return {
      canSell,
      sellMessage,
    };
  })();

  const RightButton = () => {
    return <img src={trash} style={{width: 16}} onClick={() => setIsShowingLeaveCard(true)} />
  }

  const isBottomCardOpen = Boolean(proposing);
  const isSolo = group.numMembers === 1;
  return <div className={`w-100 min-vh-100 ${isBottomCardOpen ? 'vh-100 overflow-hidden' : ''}`}>
    <Nav client={client} leftButton={BackButton} title={group.name} rightButton={RightButton} />
    <div className="w-100 pt5-ns center" style={{maxWidth: 700, paddingBottom: 80}}>
      <div className="pv4" style={{paddingLeft: 24, paddingRight: 24}}>
        <InfoCard
          account={account}
          group={group}
          rules={rules}
          user={user}
          showRuleCard={() => setIsShowingRuleCard(true)}
        />

        {proposals?.length > 0 && <div>
          <div className="flex flex-row items-center">
            <div className="bold f4 mr2">Proposals</div>
            <ProposalsInfoButton/>
          </div>
          {proposals?.length > 0 && proposals.map((p, idx) =>
            <Proposal
              client={client}
              key={`proposal-${p.id}`}
              proposal={p}
              userId={account?.userId}
              isLast={idx === proposals.length - 1}
            />)}
        </div>}

        <div className="flex flex-row items-center mb">
          <TinyButton width="w-100" onClick={() => setIsShowingContributionModal(true)}>
            <div className="flex flex-row items-center justify-center">
              {!group.membership.isPaused && <>{MONEY.formattedDollars(group.membership.monthlyContribution / 400, { wholeDollar: true })} / week</>}
              {group.membership.isPaused && <>investment paused</>}
              <img src={chevronDown} className="ml2 mt1" style={{width: 18}} />
            </div>
          </TinyButton>
        </div>

        {Array.isArray(groupCompanies) && <>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row">
              <div className="bold f4 mr2">Stocks</div>
              <RatingsInfoButton />
            </div>
            {groupCompanies.length > 0 && 
              <img src={plus} onClick={() => history.replace(`/groups/${group.id}/add-companies`)} />}
          </div>
          {groupCompanies?.length > 0 && groupCompanies.map((gc, idx) =>
            <GroupCompany
              key={`group-company-${gc.id}`}
              groupCompany={gc}
              isLast={idx + 1 === groupCompanies.length}
              isSolo={isSolo}
              onPropose={setProposing}
              position={positions[gc.ticker]}
              proposal={proposals?.find(p => p.companyId === gc.companyId)}
            />)}
          {groupCompanies?.length === 0 && <div style={{paddingTop: 24}}>
            <div className="bg-bright-mint pa3 br4 tc">
              <div className="f5">Add some stocks to start investing.</div>
              <div className="mt3">
                <TinyButton onClick={() => history.replace(`/groups/${group.id}/add-companies`)}>
                  <div className="flex flex-row items-center justify-center">
                    <div className="mb1">Add stocks</div>
                    <img src={arrow} className="ml2" />
                  </div>
                </TinyButton>
              </div>
            </div>
          </div>}
        </>}
      </div>
    </div>
    {isShowingLeaveCard && <BottomCard dim onDismiss={() => setIsShowingLeaveCard(false)}>
      <div className="f3 bold tc mb2">Leave strategy?</div>
      <div className="f6 lh-copy tc">This will move your positions into your main portfolio where you can manage them individually. You cannot undo this action.</div>

      <div className="mt4 mb2">
        <Button width="w-100" onClick={leaveGroup} isDisabled={isLeaving}>yes, leave</Button>
      </div>
      <Button width="w-100" onClick={() => setIsShowingLeaveCard(false)} isDisabled={isLeaving}>no, cancel</Button>
    </BottomCard>}
    {isShowingRuleCard && <BottomCard dim onDismiss={() => setIsShowingRuleCard(false)}>
      <RuleCard
        client={client}
        group={group}
        onDismiss={() => setIsShowingRuleCard(false)}
      />
    </BottomCard>}
    {isShowingNotificationsPrompt && <NotificationsModal
      client={client}
      device={device}
      title={`Don't miss a vote ${String.fromCodePoint(0x1F5F3)}`}
      subtitle="Part of being in a group strategy is voting on proposals. Get notified when group members propose new stocks to buy."
      onDismiss={() => setIsShowingNotificationsPrompt(false)}
      />}
    {/* {!isShowingNotificationsPrompt && canBuy && <BottomCard>
      <div className="center-ns" style={{maxWidth: 700}}>
        <Button width="w-100" onClick={() => history.push(`/group-buys/new/${group.id}`)}>One-time Buy</Button> 
        {buyMessage && <div className="mt2 f7 silver tc">{buyMessage}</div>}
      </div>
    </BottomCard>} */}
    {isShowingContributionModal && <BottomCard dim onDismiss={() => setIsShowingContributionModal(false)}>
      <div>
        <div className="tr mb3">
          <img src={x} className="pointer" onClick={() => setIsShowingContributionModal(false)} style={{width: 14}} />
        </div>
      {isUpdatingContribution &&
        <EditGroup
          client={client}
          group={group}
          onDismiss={() => setIsUpdatingContribution(false)}
          onUpdate={setGroup} />}
      {!isUpdatingContribution && <div className="center-ns" style={{maxWidth: 700}}>
        <div>
          <TinyButton width="w-100" onClick={() => setIsUpdatingContribution(true)}>
            manage investment
          </TinyButton>
        </div>
        <div className="mt2">
          <TinyButton width="w-100" isDisabled={!canBuy} onClick={() => history.push(`/group-buys/new/${group.id}`)}>One-time Buy</TinyButton>
          {buyMessage && <div className="mt2 f7 orange tc">{buyMessage}</div>}
        </div>
        <div className="mt2">
          <TinyButton width="w-100" isDisabled={!canSell} onClick={() => history.push(`/group-sells/new/${group.id}`)}>One-time Sell</TinyButton>
          {sellMessage && <div className="mt2 f7 orange tc">{sellMessage}</div>}
        </div>
      </div>}
      </div>
    </BottomCard>}
    {Boolean(proposing) && <BottomCard dim onDismiss={() => setProposing(null)}>
      <div className="center-ns" style={{maxWidth: 700}}>
        {isSolo && <SetRating client={client} groupCompany={proposing} onClose={() => setProposing(null)} onRateSuccess={onRateSuccess} />}
        {!isSolo && <NewProposal client={client} groupCompany={proposing} onCancel={() => setProposing(null)} onProposeSuccess={onProposeSuccess} />}
      </div>
    </BottomCard>}
    <ProposalsInfo>
      <p className="lh-copy">Stocks are added to your strategy using proposals. Anyone in the strategy can make a proposal, and members will have 48 hours to vote on it.</p>
      <p className="lh-copy">For a stock not yet in your strategy, the only valid proposal is to buy. For a stock already in your strategy, use a proposal to change the rating.</p>
      <p className="lh-copy">To propose a new stock to your strategy, search for it, and tap the <span className="bold">start buying</span>.</p>
    </ProposalsInfo>
    <RatingsInfo>
      <p className="lh-copy">
        Stocks, and how you rate them, determine how your strategy will invest your money each week. Below is a description of the different ratings a stock can have.
      </p>
      <div className="bold f4">Buy</div>
      <p className="lh-copy">Any stock with a Buy rating will be purchased every Monday (or following trading day if it's a market holiday).</p>
      <div className="bold f4">Hold</div>
      <p className="lh-copy">Any stock with a Hold rating will not be purchased, and existing positions that you purchased through the strategy will be held.</p>
      <div className="bold f4">Sell</div>
      <p className="lh-copy">Any stock with a Sell rating will not be purchased, and existing positions that you purchased through the strategy will be sold on the next trading day.</p>
    </RatingsInfo>
  </div>;
}

function GroupNonMemberView({ client, group }) {
  const history = useHistory();
  const [groupCompanies, setGroupCompanies] = useState(null);
  const [simulationData, setSimulationData] = useState(null);
  const [duration, setDuration] = useState('5y');

  useEffect(() => {
    if (!group) return;
    (async () => {
      setGroupCompanies(await client.groupCompanies.get({ groupId: group.id }));
    })();
  }, [group]);

  useEffect(() => {
    if (!groupCompanies?.length) return;
    (async () => {
      const simulation = await client.simulations.get({
        groupId: group.id,
      });
      setSimulationData(await S3.getObject({ bucket: simulation.bucket, path: simulation.path }));
    })();
  }, [groupCompanies]);

  const timeToDuration = (timestamp) => {
    return moment.duration(moment.unix(timestamp).diff(moment())).humanize();
  }

  const simulation = simulationData?.find(s => s.duration === duration);

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title={group.name} footer={false} />
    <div className="w-100 pt5-ns center" style={{maxWidth: 700, paddingBottom: 80}}>
      <div style={{padding: 24, paddingTop: 32}}>
        {Boolean(simulation) && <PortfolioPerformance duration={duration} onChangeDuration={setDuration} simulation={simulation} />}

        {group.description && <div style={{marginTop: 24}}>
          <div className="bold f3 mb2 ttl">Description</div>
          <div className="f6 lh-copy">{group.description}</div>
        </div>}

        <div style={{marginTop: 24}}>
          <div className="bold f3 mb3 ttl">Investors</div>
          <div className="flex flex-row overflow-scroll">
            {group.users.map((user) =>
              <div key={`member-${user.id}`} className="flex flex-column items-center justify-start mh3" style={{flexShrink: 0}}>
                <Avatar user={user} link={`/profile/${user.id}`} size={50} shape={'square'} />
                <div className="f7 mt2 truncate w-100 tc">{user.firstName}</div>
              </div>)}
          </div>
        </div>

        <div style={{marginTop: 24}}>
          <div className="bold f3 mb3 ttl">Stocks</div>
          {groupCompanies?.length > 0 && <div>
            {groupCompanies.filter(gc => gc.rating === 'buy' || gc.rating === 'hold').map((gc) =>
              <Link key={gc.id} className="black no-underline" to={`/companies/${gc.ticker}`}>
                <div className="flex flex-row items-center justify-start" style={{marginBottom: 24}}>
                  <div className="mr2 pointer">
                    <AssetLogo company={gc.company} size={40} />
                  </div>
                  <div className="w-80 flex flex-column items-start justify-around" style={{height: 40}}>
                    <div className="f7 mid-gray">{`${_.startCase(gc.rating)}ing`} for {timeToDuration(gc.createdAt)}</div>
                    <div className="near-black f5 bold w-100 truncate pr" style={{height: 20}}>{gc.company.displayName}</div>
                  </div>
                </div>
              </Link>)}
            </div>}
        </div>

        {Boolean(simulation) && <div className="mid-gray f7">
          These returns are a backwards looking simulation for informational purposes only, and are not a recommendation to buy any of the securites included in any simulation. Past performance does not guarantee future returns.
        </div>}
      </div>
    </div>
  </div>;
}

function RuleCard({ client, group, onDismiss }) {
  const [rules, setRules] = useState(null);
  const [rule, setRule] = useState(null);
  const [canConfirm, setCanConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState(null);
  const [mode, setMode] = useState('percent');
  const [step, setStep] = useState('rules');
  const [isSending, setIsSending] = useState(false);
  const isProfit = rule?.type === 'take_profit_all'

  useEffect(() => {
    (async () => {
      try {
        const rules = await client.rules.get({ groupId: group.id });
        setRules(rules.filter(r => r.status === 'active'));
        setGroups(await client.groups.getMine());
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!rule) {
      setCanConfirm(false);
      return;
    }

    const { percent } = rule;

    if (mode === 'percent') {
      setCanConfirm(percent >= 10 && percent <= (isProfit ? 1000 : 50));
    } else if (mode === 'multiple') {
      //should not happen
      setCanConfirm(percent >= 1.1 && percent <= 100);
    }
  }, [rule]);

  const displayAmount = () => {
    let defaultAmount;
    if (!rule) {
      defaultAmount = '0';
    } else if (mode === 'percent') {
      defaultAmount = rule.percent.toString() || '0';
    } else if (mode === 'multiple') {
      const d = new Decimal(rule.percent).div(100).add(1);
      defaultAmount = d.toString();
    }

    return `${defaultAmount}${mode === 'percent' ? '%' : 'x'}`;
  }

  const displayDestination = () => {
    switch (rule.destination) {
      case 'group':
        const dg = groups.find(g => g.id === rule.destinationGroupId);
        return dg.name;
      case 'groups':
        return 'All strategies';
      case 'account':
        return 'My account';
      default:
        return '';
    }
  }

  const onConfirm = async () => {
    setIsSending(true);
    try {
      const params = {
        destination: rule.destination,
        groupId: group.id,
        percent: rule.percent,
        type: rule.type,
      }

      if (params.destination === 'group') {
        params.destinationGroupId = rule.destinationGroupId;
      }

      if (rule.id) {
        params.id = rule.id;
      }

      if (params.id) {
        await client.rules.update(params);
      } else {
        await client.rules.create(params);
      }

      onDismiss();
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setIsSending(false);
    }
  }

  const {
    title,
    subtitle,
   } = (() => {
    const action = isProfit ? 'take profit' : 'stop loss';
    switch (step) {
      case 'rules':
        return { title: 'Rules', subtitle: 'Manage your profit and loss targets.' };
      case 'threshold':
        return { title: `Set Target`, subtitle: `When do you want to ${action}?` };
      case 'destination':
        return { title: 'Destination', subtitle: 'What should we do with the proceeds?' };
      case'destinationGroup':
        return { title: 'Choose Strategy', subtitle: 'Which strategy should we move the proceeds to?' };
      case 'confirm':
        return { title: 'Confirm', subtitle: 'Review rule details.' };
      default:
        return { title: 'Edit Target', subtitle: `When do you want to ${action}?` };
    }
  })();

  const next = () => {
    if (step === 'rules') {
      setStep('threshold');
    } else if (step === 'threshold') {
      if (isProfit) {
        setStep('destination');
      } else {
        setStep('confirm');
      }
    } else if (step === 'destination') {
      if (rule.destination === 'group' && rule.destinationGroupId !== group.id) {
        setStep('destinationGroup');
      } else {
        setStep('confirm');
      }
    } else if (step === 'destinationGroup') {
      setStep('confirm');
    }
  }

  const previous = () => {
    if (step === 'threshold') {
      setStep('rules');
      setRule(null);
    } else if (step === 'destination') {
      setStep('threshold');
    } else if (step === 'destinationGroup') {
      setStep('destination');
    } else if (step === 'confirm') {
      if (isProfit) {
        if (rule.destination === 'group') {
          if (rule.destinationGroupId === group.id) {
            setStep('destination');
          } else {
            setStep('destinationGroup');
          }
        } else {
          setStep('destination');
        }
      } else {
        setStep('threshold');
      }
    }
  }

  const onSelectType = (type) => {
    const rule = rules.find(r => r.type === type);
    if (rule) {
      setRule(rule);
    } else {
      //creating a new rule, set up default values
      setRule({
        destination: 'group',
        destinationGroupId: group.id,
        percent: '',
        type,
      });
    }
    next();
  }

  const merge = (updates) => setRule({
    ...rule,
    ...updates,
  });

  const onChangeThreshold = (threshold) => {
    setRule({
      ...rule,
      percent: parseInt(threshold) || 0,
    });
  }

  const onDeleteRule = async (rule) => {
    const { id } = rule;
    try {
      setIsSending(true);
      await client.rules.update({ id, status: 'inactive' });
      setRules(rules.filter(r => r.id !== id));
    } catch (e) {
      console.error(e);
    }
  }

  const takeProfitRule = rules?.find(r => r.type === 'take_profit_all');
  const stopLossRule = rules?.find(r => r.type === 'stop_loss');

  return <div className="tc center-ns" style={{maxWidth: 500}}>
    <div className="flex flex-row items-center justify-between">
      <div className="tl" style={{width: 25}}>
        {step === 'rules' && <img src={x} onClick={onDismiss} style={{width: 14}} />}
        {step !== 'rules' && <img src={arrowLeft} onClick={previous} style={{width: 14}} />}
      </div>
      <div className="f4 bold mb2">{title}</div>
      <div className={`f6 bold flex flex-row items-start justify-center`} style={{width: 25}}></div>
    </div>
    {Boolean(subtitle) && <div className="f6 mid-gray mb4">{subtitle}</div>}

    {step === 'rules' && <>
      <div className="flex flex-row items-center justify-between pv bb b--light-gray">
        <div className="tl pr3">
          <div className="f5 bold">Take Profit</div>
          <div className="lh-copy mid-gray mt1" style={{fontSize: 13}}>
            {!takeProfitRule && <>Automatically sell stocks in this strategy when profit is available.</>}
            {takeProfitRule && <>You're capturing profit at {takeProfitRule.percent}%.</>}
          </div>
        </div>
        {!takeProfitRule && <TinyButton onClick={() => onSelectType('take_profit_all')}>Add</TinyButton>}
        {Boolean(takeProfitRule) && <TinyButton onClick={() => onDeleteRule(takeProfitRule)}>Delete</TinyButton>}
      </div>

      <div className="flex flex-row items-center justify-between pv bb b--light-gray">
        <div className="tl pr3">
          <div className="f5 bold">Stop Loss</div>
          <div className="lh-copy mid-gray mt1" style={{fontSize: 13}}>
            {!stopLossRule && <>Automatically sell stocks in this strategy when losses occur.</>}
            {stopLossRule && <>Stop losses at -{stopLossRule.percent}%.</>}
          </div>
        </div>
        {!stopLossRule && <TinyButton onClick={() => onSelectType('stop_loss')}>Add</TinyButton>}
        {Boolean(stopLossRule) && <TinyButton onClick={() => onDeleteRule(stopLossRule)}>Delete</TinyButton>}
      </div>

    </>}

    {step === 'threshold' && <>
      <div className={`mv4 ${canConfirm ? '' : 'o-50'}`}>
        <BigNumber>
          {displayAmount()}
        </BigNumber>
        {isProfit && <div className={`pv2 f6`}>Between 10% and 1000%</div>}
        {!isProfit && <div className={`pv2 f6`}>Between 10% and 50%</div>}
      </div>

      <NumberPad allowDecimal={mode === 'multiple'} amount={rule.percent} onChange={onChangeThreshold} max={isProfit ? 1000 : 50} />
      <div className="mt3">
        {Boolean(error) && <div className="f7 mb2 red">{error}</div>}
        <Button width="w-100" isDisabled={isSending || !canConfirm} onClick={next}>next</Button>
      </div>
    </>}

    {step === 'destination' && <>
      <div>
        <div className="flex flex-row items-center justify-start pv3 bb b--light-gray" onClick={() => merge({ destination: 'group', destinationGroupId: group.id })}>
          <div className="br-100 ba b--mid-gray pa1 bg-white">
            <div className={`br-100 bg-${rule.destination === 'group' && rule.destinationGroupId === group.id ? 'black' : 'white'}`} style={{ width: 14, height: 14 }}></div>
          </div>
          <div className="ml3 tl">
            <div className="f6">This strategy</div>
            <div className="mt1 f7 mid-gray lh-copy">Proceeds will be reinvested into all the stocks in this strategy.</div>
          </div>
        </div>
        {groups.length > 0 && <div className="flex flex-row items-center justify-start pv3 bb b--light-gray" onClick={() => merge({ destination: 'group', destinationGroupId: undefined })}>
          <div className="br-100 ba b--mid-gray pa1 bg-white">
            <div className={`br-100 bg-${rule.destination === 'group' && rule.destinationGroupId !== group.id ? 'black' : 'white'}`} style={{ width: 14, height: 14 }}></div>
          </div>
          <div className="ml3 tl">
            <div className="f6">Another strategy</div>
            <div className="mt1 f7 mid-gray lh-copy">Proceeds will be added to your weekly investment in a strategy of your choosing.</div>
          </div>
        </div>}
        <div className="flex flex-row items-center justify-start pv3 bb b--light-gray" onClick={() => merge({ destination: 'groups', destinationGroupId: undefined })}>
          <div className="br-100 ba b--mid-gray pa1 bg-white">
            <div className={`br-100 bg-${rule.destination === 'groups' ? 'black' : 'white'}`} style={{ width: 14, height: 14 }}></div>
          </div>
          <div className="ml3 tl">
            <div className="f6">All strategies</div>
            <div className="mt1 f7 mid-gray lh-copy">Proceeds will be deposited in your Share account and used to fund your weekly investments.</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start pv3 bb b--light-gray mb3" onClick={() => merge({ destination: 'account', destinationGroupId: undefined })}>
          <div className="br-100 ba b--mid-gray pa1 bg-white">
            <div className={`br-100 bg-${rule.destination === 'account' ? 'black' : 'white'}`} style={{ width: 14, height: 14 }}></div>
          </div>
          <div className="ml3 tl">
            <div className="f6">Cash</div>
            <div className="mt1 f7 mid-gray lh-copy">Proceeds will be deposited in your Share account and available for withdrawal.</div>
          </div>
        </div>
        <div>
          <Button width="w-100" outline="true" onClick={next}>Next</Button>
        </div>
      </div>
    </>}

    {step === 'destinationGroup' && <div className="bt b--light-gray">
      {groups.filter(g => g.id !== group.id).map((g) =>
        <div key={g.id} className="flex flex-row items-center justify-start pv bb b--light-gray" onClick={() => merge({ destinationGroupId: g.id })}>
          <div className="br-100 ba b--mid-gray pa1 bg-white">
            <div className={`br-100 bg-${rule.destinationGroupId === g.id ? 'black' : 'white'}`} style={{ width: 14, height: 14 }}></div>
          </div>
          <div className="ml3 tl">
            <div className="f5">{g.name}</div>
          </div>
        </div>
      )}
      <div className="mt3">
        <Button width="w-100" isDisabled={!rule.destinationGroupId} onClick={next}>Next</Button>
      </div>
    </div>}
    {step === 'confirm' && <>
      <div>
        <div className="pv3 bb bt b--light-gray flex flex-row items-center justify-between">
          <div className="f5">Strategy</div>
          <div className="f6 mid-gray mt2">{group.name}</div>
        </div>
        <div className="pv3 bb b--light-gray flex flex-row items-center justify-between">
          <div className="f5">{isProfit ? `Profit Target ${String.fromCodePoint(0x1F4B0)}` : `Stop Loss ${String.fromCodePoint(0x1F6D1)}`}</div>
          <div className="f6 mid-gray mt2">{displayAmount()}</div>
        </div>
        <div className="pv3 bb b--light-gray flex flex-row items-center justify-between">
          <div className="f5">Destination</div>
          <div className="f6 mid-gray mt2">{displayDestination()}</div>
        </div>
      </div>
      <div className="mt3 tl f6 silver lh-copy">
        {isProfit && <>Setting a profit target will result in automatic sale of your positions when the market price exceeds this target. Profit targets do not ensure a profit or guarantee against loss.</>}
        {!isProfit && <>Setting a stop loss target will result in automatic sale of your positions. Stop loss targets are executed on a best effort basis and do not guarantee against loss.</>}
      </div>
      <div className="mt3">
        <Button width="w-100" isDisabled={isSending} onClick={onConfirm}>confirm</Button> 
        <div className="mt2"></div>
        <Button width="w-100" isDisabled={isSending} onClick={onDismiss}>cancel</Button> 
      </div> 
    </>}
  </div>;
}

function InfoCard({ account, group, rules, showRuleCard, user }) {
  const [isShowingRuleInfo, setIsShowingRuleInfo] = useState(false);

  if (!account) {
    return <Link to="/account/onboarding" className="black no-underline">
      <div className="bg-white ba b--light-gray br4 pa3 f7 lh-copy mb3 flex flex-row items-center justify-between">
        <img src={info} style={{width: 16}} />
        <div className="mh3">Complete your account setup to start your weekly investment in {group.name}.</div>
        <img src={arrow} style={{width: 16}} />
      </div>
    </Link>;
  }

  if (!user.paymentMethodId) {
    return <Link to="/payment-method" className="black no-underline">
      <div className="bg-white ba b--light-gray br4 pa3 f7 lh-copy mb3 flex flex-row items-center justify-between">
        <img src={info} style={{width: 16}} />
        <div className="mh3">Link a bank account to start your weekly investment in {group.name}.</div>
        <img src={arrow} style={{width: 16}} />
      </div>
    </Link>; 
  }

  if (rules?.length > 0) {
    return <div className="bg-white shadow-4 br4 pa3 f7 lh-copy mb3 flex flex-row items-center justify-between" onClick={showRuleCard}>
      <div className="flex flex-row items-center justify-start">
        <img src={moneyBagPink} />
        <div className="mh2 w-50">Profit/Loss Rules</div>
      </div>
      <div className="flex items-center justify-start f6">
        Manage
        <img className="ml2" src={chevronRight} style={{width: 6}} />
      </div>
    </div> 
  } else if (rules?.length === 0) {
    return <div className="bg-white shadow-4 br4 pa3 f7 lh-copy mb3 flex flex-row items-center justify-between" onClick={showRuleCard}>
      <div className="flex flex-row items-center justify-start">
        <img src={moneyBag} />
        <div className="mh2 w-50">Profit/Loss Rules</div>
      </div>
      <div className="flex items-center justify-start f6">
      Manage
        <img className="ml2" src={chevronRight} style={{width: 6}} />
      </div>
    </div>
  }

  return null;

  return <>
    <div className="bg-near-white br4 pa3 f7 lh-copy mb3 flex flex-row items-center justify-start" onClick={() => setIsShowingRuleInfo(true)}>
      <img src={moneyBag} style={{width: 16}} />
      <div className="mh3">New! Set a profit target to capture profits when they are available. <span className="pink underline">More info.</span></div>
    </div>
    {isShowingRuleInfo && <BottomCard dim onDismiss={() => setIsShowingRuleInfo(false)}>
      <div className="tc center-ns" style={{maxWidth: 500}}>
        <div className="f4 bold mv3">About Profit Targets</div>
        <div className="mv4">
          <div className="f5 tl">
            <div className="f5 bold">What are profit targets?</div>
            <p className="f6 lh-copy ">Profit targets are a way to automatically capture profits in your investments, if and when they are available.</p>
            <div className="f5 bold">How do they work?</div>
            <p className="f6 lh-copy ">For each stock in your strategy, Share will monitor the market price, and compare it to your purchase price. If the market price rises above your profit target, the position will be sold.</p>
            <div className="f5 bold">How much will be sold?</div> 
            <p className="f6 lh-copy">Since each weekly buy has a different entry price, <span className="bold">only shares that have hit your profit target will be sold.</span></p>
            <div className="f5 bold">Where does the cash go?</div>
            <p className="f6 lh-copy">Proceeds from the sales can be added to your account, and available for withdrawal or used for reinvestment in your strategies if you leave them in Share. You can also direct them to a specific strategy.</p>
          </div>
        </div>
        <div className="mt3">
          <Button width="w-100" onClick={() => setIsShowingRuleInfo(false)}>Done</Button> 
        </div>
      </div>
    </BottomCard>}
  </>;
}

function GroupCompany({ groupCompany, isLast, isSolo, onPropose, proposal, position, hideProposeButton }) {
  const { company } = groupCompany;
  const percentReturn = position ? parseFloat(position.percentReturn) : undefined;
  const isProposalDisabled = Boolean(proposal);

  return <div className={`${isLast ? '' : 'bb b--light-gray'}`} style={{paddingTop: 24, paddingBottom: 24}}>
    <div className={`flex flex-row justify-between items-center`}>
      <div className="w-75">
        <div className="tl f7 mid-gray lh-copy">{company.ticker}</div>
        <div className="flex flex-row items-center justify-start mb1">
          <Link to={`/companies/${company.ticker}`} className="f5 bold black no-underline truncate" style={{height: 20}}>
            {company.displayName}
          </Link>
          <RatingBadge type={groupCompany.rating} />
        </div>
        <div className="flex flex-row items-center justify-start">
          {percentReturn !== undefined && <>
            <div className="f7 gray">
              {percentReturn > 0 ? '+' : '-'}
              {Math.abs(percentReturn * 100).toFixed(2)}%
              lifetime return
            </div>
          </>}
          {!groupCompany.initialEntryPrice && <div className="f7 gray">
            {groupCompany.rating === 'buy' && <>First buy pending.</>}
            {groupCompany.rating === 'sell' && <>Sale pending.</>}
            {groupCompany.rating === 'hold' && <>Holding.</>}
          </div>}
        </div>
      </div>
      {!hideProposeButton && <TinyButton outline="true" isDisabled={isProposalDisabled} onClick={() => onPropose(groupCompany)}>
        <div className="flex flex-row items-center justify-start">
          <img src={isSolo ? edit : vote} style={{width: 20, height: 20}} />
        </div>
      </TinyButton>}
    </div>
  </div>;
}

function Proposal({ client, isLast, proposal, userId }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [myVote, setMyVote] = useState(proposal.votes?.find(v => v.userId === userId));

  const onVote = async ({ value }) => {
    if (isDisabled) return;

    try {
      setIsDisabled(true);
      const vote = await client.proposals.vote({
        proposalId: proposal.id,
        vote: value,
      });
      setMyVote(vote);
    } catch(err) {
      console.log(err);
    } finally {
      setIsDisabled(false);
    }
  };

  return <div className={`bb b--light-gray ${isLast ? 'mb' : ''}`} style={{paddingTop: 24, paddingBottom: 24}}>
    <Link className="black no-underline" to={`/companies/${proposal.ticker}`}>
      <div className={`flex flex-row justify-start items-center`}>
        <div className="w-100">
          <div className="flex flex-row items-start justify-between mb1">
            <div className="w-70 flex flex-row items-start justify-start">
              <div className="f4 bold">
                {proposal.company.displayName}
              </div>
              <div className="flex" style={{flexShrink: 0}}>
                <RatingBadge type={proposal.type} />
              </div>
            </div>
            {proposal.isLive && <Countdown proposal={proposal} />}
          </div>
          <div className="f6 silver">Proposed by {proposal.user.firstName}</div>
          {Boolean(proposal.comment) && <div className="mt3 f5 lh-copy" style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: proposal.comment}}></div>}
        </div>
      </div>
    </Link>
    {proposal.isLive && <div className={`pt4`}>
      <div className={`flex flex-row items-center justify-around f3`}>
        {[-1, 1].map((value, idx) =>
          <VoteButton
            key={`${proposal.id}-vote-${value}`}
            isSell={proposal.type === 'sell'}
            value={value}
            vote={myVote}
            onVote={({ value }) => onVote({ proposalId: proposal.id, value })}
            onRevote={() => onVote({ proposalId: proposal.id, value: 0 })}
          />
        )}
      </div>
    </div>}
  </div>;
}

function Countdown({ proposal }) {
  const [timeLeft, setTimeLeft] = useState((proposal.expiresAt * 1000) - (new Date()).getTime());

  useEffect(() => {
    if (!proposal.isLive) return;
    const refreshIn = (timeLeft / 1000) < 3600 ? 1000 : (1000 * 60 * 5);
    const timer = setTimeout(() => {
      const now = new Date();
      const timeLeft = (proposal.expiresAt * 1000) - now.getTime();
      setTimeLeft(timeLeft);
    }, refreshIn);

    return () => clearTimeout(timer);
  });

  const duration = moment.duration(timeLeft, 'milliseconds');
  const hours = Math.floor(duration.asHours());
  return <>
    {timeLeft > 0 && <>
      <div className="f7 silver">
        {hours > 0 && <>{hours} hours left</>}
        {hours === 0 && <div className="flex flex-row items-center">
          <div style={{width: 23}}>{duration.minutes()}m</div>
          <div className="ph1">:</div>
          <div style={{width: 23}}>{duration.seconds()}s</div>
        </div>}
      </div>
    </>}
  </>
}

const typeDescriptions = {
  buy: 'Start buying this stock every Monday.',
  hold: 'Stop buying this stock, keep what you have.',
  sell: 'Stop buying this stock, sell what you have.',
};

function NewProposal({ client, groupCompany: groupCompany, onCancel, onProposeSuccess }) {
  const [type, setType] = useState(null);
  const [proposal, setProposal] = useState(null);
  const availableTypes = ['buy', 'hold', 'sell'].filter(t => t != groupCompany.rating);

  const onPropose = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = new FormData(e.target);
    try {
      const proposal = await client.proposals.create({
        comment: form.get('comment'),
        groupId: groupCompany.groupId,
        ticker: groupCompany.ticker,
        type,
        weight: type === 'buy' ? 1 : 0,
      });
      setProposal(proposal);
    } catch (err) {
      console.error(err);
    }
  }
  
  return <div>
    <div className="flex items-center justify-center">
      <AssetLogo company={groupCompany.company} />
    </div>
    <div className="tc mt2 mb3 bold">{type ? `${_.capitalize(type)} ` : ''}{groupCompany.company.displayName}</div>
    {!type && <RatingOptions availableTypes={availableTypes} setType={setType} isProposal={true} />}
    {Boolean(type) && <>
      <div>
        {!proposal && <>
          <div className="f6 lh-copy mt1 dark-gray pb3">
            {typeDescriptions[type]} Voting will be open for 48 hours.
          </div>
          <form onSubmit={onPropose}>
            <Input
            type="textarea"
            name="comment"
            label=""
            placeholder={`Add an explanation.`} />
            <Button width="w-100" data-value="1" data-ga-event="Suggest">Confirm</Button>
          </form>
        </>}
        {Boolean(proposal) && <>
          <div className="mv3">Your proposal has been recorded.</div>
          <Button width="w-100" onClick={() => onProposeSuccess(proposal)}>Done</Button>
        </>}
      </div>
    </>}
    {!proposal && <div className="pt2 bt b--light-gray">
      <Button width="w-100" outline="none" onClick={onCancel}>Cancel</Button>
    </div>}
  </div>
}

function SetRating({ client, groupCompany: currentGroupCompany, onClose, onRateSuccess }) {
  const [type, setType] = useState(null);
  const [groupCompany, setGroupCompany] = useState(null);
  const availableTypes = ['buy', 'hold', 'sell'].filter(t => t != currentGroupCompany.rating);

  const onRate = async () => {
    try {
      const groupCompany = await client.groupCompanies.update({ id: currentGroupCompany.id, type });
      setGroupCompany(groupCompany);
      onRateSuccess(groupCompany);
    } catch (err) {
      console.error(err);
    }
  }
  
  return <div>
     <div className="flex items-center justify-center">
      <AssetLogo company={currentGroupCompany.company} />
    </div>
    <div className="tc mt2 mb3 bold">{type ? `${_.capitalize(type)} ` : ''}{currentGroupCompany.company.displayName}</div>
    {!type && <RatingOptions availableTypes={availableTypes} setType={setType} />}
    {Boolean(type) && <>
      <div>
        {!groupCompany && <>
          <div className="f6 lh-copy mt1 dark-gray pb3">{typeDescriptions[type]}</div>
          <Button width="w-100" onClick={onRate}>Confirm</Button></>}
        {Boolean(groupCompany) && <>
          <div className="mv3">Your rating has been recorded.</div>
          <Button width="w-100" onClick={onClose}>Done</Button>
        </>}
      </div>
    </>}
    {!groupCompany && <div className="pt2 bt b--light-gray">
      <Button width="w-100" outline="none" onClick={onClose}>Cancel</Button>
    </div>}
  </div>
}

function RatingOptions({ availableTypes, setType, isProposal }) {
  return <div>
    {availableTypes.includes('buy') && <div className="pv3 bt b--light-gray">
      <TinyButton width="w-100" onClick={() => setType('buy')}>{isProposal ? 'Propose buying' : 'Buy'}</TinyButton>
      <div className="f6 tc lh-copy mt1 dark-gray">
        {typeDescriptions['buy']}
      </div>
    </div>}
    {availableTypes.includes('hold') && <div className="pv3 bt b--light-gray">
      <TinyButton onClick={() => setType('hold')} width="w-100">{isProposal ? 'Propose holding' : 'Hold'}</TinyButton>
      <div className="f6 tc lh-copy mt1 dark-gray">
        {typeDescriptions['hold']}
      </div>
    </div>}
    {availableTypes.includes('sell') && <div className="pv3 bt b--light-gray">
     <TinyButton onClick={() => setType('sell')} width="w-100">{isProposal ? 'Propose selling' : 'Sell'}</TinyButton>
     <div className="f6 tc lh-copy mt1 dark-gray">
       {typeDescriptions['sell']}
      </div>
    </div>}
  </div>;
}
