import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Nav from './partials/nav';
import PortfolioReview from './partials/portfolio-review';
import BackButton from './partials/button-back';
import { BottomCard } from './partials/ui';

export default function PortfolioReviews({ client }) {
  const [portflioReviews, setPortfolioReviews] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    (async () => {
      setPortfolioReviews(await client.portfolioReviews.get());
    })();
  }, []);

  useEffect(() => {
    if (!selectedId) return;
    client.portfolioReviews.update({ id: selectedId, isSeen: true });
  }, [selectedId]);

  const onComplete = () => {
    try {
      client.portfolioReviews.update({ id: selectedId, isComplete: true });
    } catch {}
    onDismiss();
  }

  const onDismiss = () => setSelectedId(null);

  return <div>
    <Nav client={client} leftButton={BackButton} title="Portfolio Reviews" footer={false} />
    <div className="center w-100-ns w-90 pt6-ns pt3" style={{maxWidth: 500, paddingBottom: 80}}>
    <div className="pb3">
      {portflioReviews?.map((portflioReview, idx) =>
        <div key={`portfolio-review-${portflioReview.id}`} onClick={() => setSelectedId(portflioReview.id)} className={`pv4 ${idx < portflioReviews.length - 1 ? 'bb' : 'bn'} b--light-gray`}>
          <div className="flex flex-row items-center justify-start">
            <div className="f5 mr1">{portflioReview.title}</div>
            {!portflioReview.isSeen && <div className="bg-lightest-blue pa1 ph2 br2 f7">New!</div>}
          </div>
        </div>)}
      </div>
    </div>
    {selectedId && <BottomCard dim height="90%" onDismiss={onDismiss}>
        <PortfolioReview client={client} id={selectedId} onDismiss={onDismiss} onComplete={onComplete} />
    </BottomCard>}
  </div>;
}
