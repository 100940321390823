import React, { useEffect, useState } from 'react';

export default function BottomCard({ backgroundColor, children, dim, height = 'auto', onDismiss, padding = 'pa' }) {
  const [maxHeight, setMaxHeight] = useState(0);
  useEffect(() => {
    setMaxHeight('90vh');
    if (dim) {
      document.body.style.overflow = 'hidden';
      return () => document.body.style.overflow = 'unset';
    }
  }, []);

  const onClickOff = () => {
    if (onDismiss) onDismiss();
  }

  const style = {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    height,
    maxHeight,
    transition: `max-height 0.5s ease-out`,
  }

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  return <>
    {Boolean(dim) && <div className="z-999 fixed absolute--fill bg-black o-50" onClick={onClickOff}></div>}
    <div className={`fixed w-100 left-0 bottom-0 bg-white ${padding} z-9999 bt b--moon-gray overflow-scroll`} style={style}>
      {children}
    </div>
  </>
}