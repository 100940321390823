const clientAtMentionRegex = /\@[a-z0-9]+/g;
const serverAtMentionRegex = /{at-mention:(.*?)}/g;
const tickerRegex = /\$[A-Z]+/ig;

// parseClientAtMentions takes a string containing @username mentions and converts it to the server-side {at-mention:x} syntax
export function parseClientAtMentions(string, usersUsernameMap) {
  let out = string;
  const mentions = string.match(clientAtMentionRegex);
  if (Array.isArray(mentions)) {
    for (const match of mentions) {
      const username = match.substring(1).toLowerCase();
      const user = usersUsernameMap[username];
      if (user) {
        out = out.replace(match, `{at-mention:${user.id}}`) 
      }
    }
  }

  return out;
}

const defaultRender = ({ href, text }) => `<a href="${href}">${text}</a>`;

// parseServerAtMentions takes a string containing {at-mention:id} mentions and converts it to the client-side @username syntax
export function parseServerAtMentions(string, usersIdsMap, render = defaultRender) {
  const out = string.replace(serverAtMentionRegex, (wholeMatch, userIdString) => {
    const userId = Number(userIdString);
    const user = usersIdsMap[userId];

    let atMention;
    if (user) {
      atMention = render({ href: `/profile/${user.id}`, text: `@${user.username}` });
    } else {
      atMention = `@anonymous`
    }

    return atMention;
  });

  return out;
}

// parseCompanyMentions takes a string containing $TICKER mentions and a group ID and wraps it in a link to the company page
export function parseCompanyMentions(string, groupId, render = defaultRender) {
  const linkQueryParams = `?groupId=${groupId}`

  let out = string;
  const links = out.match(tickerRegex);
  if (Array.isArray(links)) {
    for (const match of links) {
      out = out.replace(match, render({ href: `/companies/${match.replace('$', '').toUpperCase()}${linkQueryParams}`, text: match }));
    } 
  }

  return out;
}