import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Loading from './partials/loading';
import Nav from './partials/nav';
import { BottomCard, TinyButton } from './partials/ui';
import arrowIcon from './assets/img/arrow-black.svg';
import plus from './assets/img/plus.svg';
import trash from './assets/img/trash.svg';

export default function Appointments({ client }) {
  const [upcoming, setUpcoming] = useState(null);
  const [past, setPast] = useState(null);
  const [isAppointmentCardOpen, setIsAppointmentCardOpen] = useState(false);
  const [isBottomCardOpen, setIsBottomCardOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  
  useEffect(() => {
    (async () => {
      setUpcoming(await client.appointments.get({ time: 'upcoming' }));
      setPast(await client.appointments.get({ time: 'past' }));
      setMenuItems(await client.menuItems.get());
    })();
  }, []);


  if (!menuItems) return  <Loading />;

  return <div className={`w-100 min-vh-100 mt6-ns overflow-x-hidden ${isBottomCardOpen ? 'vh-100' : ''}`}>
    <Nav client={client} title="Appointments" />
    <div className="mh center-ns" style={{maxWidth: 500}}>
      <div className="flex flex-row items-center justify-between mt4 mb3">
        <div className="f4 bold">Upcoming</div>
        <img src={plus} className="pointer" onClick={() => setIsAppointmentCardOpen(true)} />
      </div>
      {upcoming.length === 0 && <div className="tc pa4 mb4 bg-bright-mint br5 mt">
        <div className="f5 mb3">You don't have any upcoming appointments.</div>
        <TinyButton onClick={() => setIsAppointmentCardOpen(true)}>
          <div className="flex flex-row items-center justify-center">
            <div>Book Now</div>
            <img src={arrowIcon} className="ml2" style={{width: 16}} />
          </div>
        </TinyButton>
      </div>}
      {upcoming.map((a, idx) => <div key={a.uuid} className="flex flex-row items-center justify-between ba b--light-gray br5 ph3 pv" style={{marginTop: idx > 0 ? -1 : 0}}>
        <div>
          <div className="f5 bold">{a.menuItem.name} {a.menuItem.emoji} with {a.scheduledWithUser.firstName}</div>
          <div className="mt1 f6">{moment.unix(a.startTime).format('MMM D [at] h:mm A')}</div>
        </div>
        <a href={a.cancelUrl} className="black no-underline">
          <TinyButton outline="none">
            <img src={trash} style={{width: 20}} />
          </TinyButton>
        </a>
      </div>)}

      {past.length > 0 && <div className="f4 bold mt4 mb3">Past</div>}
      {past.map((a, idx) => <div key={a.uuid} className="ba b--light-gray br5 ph3 pv" style={{marginTop: idx > 0 ? -1 : 0}}>
        <div>
          <div className="f5 bold">{a.menuItem.name} {a.menuItem.emoji}</div>
          <div className="mt1 f6">Call with {a.scheduledWithUser.firstName}</div>
        </div>
        <div className="mt1 f6">{moment.unix(a.startTime).format('MMM D, YYYY h:mm A')}</div>
      </div>)}
    </div>

    {isAppointmentCardOpen && <BottomCard dim onDismiss={() => setIsAppointmentCardOpen(false)}>
      <div className="center" style={{maxWidth: 500}}>
        <div className="bold f4 mt3 mb2 tc">
          New Appointment
        </div>
        <div className="f5 tc mid-gray">What can we help with?</div>

        <div className="mt4">
        {menuItems?.map((mi, idx) =>
          <div key={mi.uuid} className={`ba b--light-gray br5 ph3 pv flex flex-row items-center justify-between`} style={{marginTop: idx > 0 ? -1 : 0}}>
            <div>
              <div className="f4 bold">{mi.name} {mi.emoji}</div>
              <div className="f6 mid-gray mt1">{mi.duration} minutes</div>
            </div>

            <div>
              {mi.isComingSoon && <TinyButton isDisabled>coming soon</TinyButton>}
              {!mi.isComingSoon && <a href={mi.bookingLink} className="black no-underline">
                <TinyButton>Book</TinyButton>
              </a>}
            </div>

          </div>)}
        </div>
      </div>
    </BottomCard>}
  </div>;
}