export const getSelection = (window) => {
  if (window.getSelection) {
    return window.getSelection();
  } else if (window.document.selection) {
    return window.document.selection;
  }
  return null;
}

export const clearSelection = (window) => {
  const selection = getSelection(window);
  if (selection) {
    if (selection.empty) {
      selection.empty();
    } else if (selection.removeAllRanges) {
      selection.removeAllRanges();
    }
  }
}

export const getRange = (window) => {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.type === 'Range' && sel.rangeCount === 1) {
      return sel.getRangeAt(0);
    }
  } else if (window.document.selection && window.document.selection.createRange) {
    return window.document.selection.createRange();
  }
  return null;
}