import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash'
import Loading from '../partials/loading';
import { Button, H2, Input, Radio } from '../partials/ui'
import { QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back';

const steps = [
  'employmentStatus',
  'employerName',
  'jobTitle',
  'employerAddress',
];


export default function AccountEmployment({ client, onEmploymentCreate }) {
  const { step = steps[0] } = useParams();
  const { returnTo } = QUERY.toObject(useLocation().search);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [employment, setEmployment] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
      try {
        const employment = await client.employments.get({ userId: user.id });
        setEmployment(employment);
      } catch {} finally {
        setIsLoading(false);
      }
    })();
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      switch (idx) {
        case 0:
          const form = new FormData(e.target);
          const employmentStatus = form.get('employmentStatus');
          if (employmentStatus !== 'employed') {
            break;
          }
        default:
          return history.push(`/account/onboarding/employment/${steps[idx + 1]}`);
      }
    }

    setIsDisabled(true);

    const form = new FormData(e.target);
    const data = {
      userId: user.id,
      employmentStatus: form.get('employmentStatus'),
    };

    if (data.employmentStatus === 'employed') {
      data.employerName = form.get('employerName');
      data.jobTitle = form.get('jobTitle');
      data.employerAddress = form.get('employerAddress');
    };

    try {
      const employment = await client.employments.update(data);
      if (onEmploymentCreate) {
        onEmploymentCreate({ employment });
      } else {
        history.push(`/account/onboarding${returnTo ? '?section=' + returnTo : ''}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  if (isLoading) return <Loading />;

  let title;
  switch (step) {
    case 'employmentStatus':
      title = 'What\'s your employment status?';
      break;
    case 'employerName':
      title = 'Where do you work?';
      break;
    case 'jobTitle':
      title = 'What\'s your job title?';
      break;
    case 'employerAddress':
      title = 'Where are they located?';
      break;
    default:
  }

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Employment" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb5">
          <H2>{title}</H2>
          <div className="near-black">We are required to ask these questions by the US government.</div>
        </div>

        <div className={`mv4 ${step === 'employmentStatus' ? '' : 'dn'}`}>
          {employmentStatuses.map(({ name, value }) =>
            <div key={value} className="pv3 bb b--light-gray">
              <Radio name="employmentStatus" value={value} label={name} required={true} defaultChecked={employment?.employmentStatus === value} />
            </div>)}
        </div>

        <div className={`mv4 ${step === 'employerName' ? '' : 'dn'}`}>
          <Input name="employerName" type="text" placeholder="Acme Inc." required={step >= 1} defaultValue={employment?.employerName} />
        </div>

        <div className={`mv4 ${step === 'jobTitle' ? '' : 'dn'}`}>
          <Input name="jobTitle" type="text" placeholder="Head of Things" required={step >= 2} defaultValue={employment?.jobTitle} />
        </div>

        <div className={`mv4 ${step === 'employerAddress' ? '' : 'dn'}`}>
          <Input name="employerAddress" type="text" placeholder="123 Fake Street, New York, NY, 11111" required={step >= 3} defaultValue={employment?.employerAddress} />
        </div>
        
        <div className="mt4">
          <Button isDisabled={isDisabled} width="w-100">Continue</Button>
          {error && <div className="f7 mt1 dark-red">{error}</div>}
        </div>
      </form>
  </div>
</div>;
}

const employmentStatuses = [
  { name: 'Employed', value: 'employed' },
  { name: 'Unemployed', value: 'unemployed' },
  { name: 'Student', value: 'student' },
  { name: 'Retired', value: 'retired' },
];