import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { MONEY } from '../../utils';

//PortfolioChart charts a portfolio.
//chart should be an array of objects.
//valueKey is the attribute that holds values, labelKey is the attribute that holds labels.
//valid keys are costBasis, dollarReturn, marketValue, percentReturn.
export default function PortfolioChart({ backgroundColor = 'white', chart, compare, duration, durations, height = 150, labelKey, onChangeDuration, valueKey }) {
  const getData = (chart, valueKey) => {
    if (compare) {
      //multiple datasets means we need labels
      //https://stackoverflow.com/questions/58622820/chart-js-date-x-axis-with-sparse-datapoints
      return chart.map((d) => ({
        x: d.at,
        y: (d[valueKey] * 100).toFixed(2),
      }));
    }

    return chart.map((d) => (d[valueKey] * 100).toFixed(2));
  };
  
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        type: compare ? 'time' : undefined,
      }],
    },
    tooltips: {
      enabled: false,
    },
  };

  const borderWidth = (() => {
    if (chart.length > 200) {
      return 1;
    }
    if (chart.length > 100) {
      return 2;
    }
    return 3;
  })();

  const color = 'black';
  const data = {
    labels: chart.map(d => d.at),
    datasets: [{
        id: 'main',
        fill: false,
        lineTension: 0.3,
        backgroundColor: color,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: getData(chart, valueKey),
      }
    ]
  };

  if (compare) {
    data.datasets.push({
        id: 'compare',
        fill: false,
        lineTension: 0.3,
        backgroundColor: '#CCCCCC',
        borderColor:'#CCCCCC',
        borderCapStyle: 'butt',
        borderDash: [3],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: getData(compare, valueKey),
    });
  }

  const { max, maxPosition, min, minPosition } = (() => {
    if (!chart || chart.length <= 1) return {};

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let maxIdx, minIdx;
    chart.forEach((d, idx) => {
      const value = parseFloat(d[valueKey]);
      if (value < min) {
        min = value;
        minIdx = idx;
      }
      if (value > max) {
        max = value;
        maxIdx = idx;
      }
    });

    const bound = (x) => Math.max(Math.min(x, 90), 5);
    const maxPosition = Boolean(max) ? `${bound(maxIdx / (chart.length - 1) * 100)}%` : undefined;
    const minPosition = Boolean(min) ? `${bound(minIdx / (chart.length - 1) * 100)}%` : undefined;

    const prettify = (idx) => {
      const d = chart[idx];
      const value = d[labelKey];
      const decimals = Math.abs(value) >= 0.1 ? 1 : 2; //max 3 digits
      const signPrefix = d[valueKey] >= 0 ? '+' : '';
      const prefix = labelKey.includes('Return') ? signPrefix : '';
      return labelKey === 'percentReturn' ? `${prefix}${(value * 100).toFixed(decimals)}%` : `${prefix}${MONEY.formattedDollars(value)}`;
    }

    return {
      max: prettify(maxIdx), 
      maxPosition,
      min: prettify(minIdx),
      minPosition,
    };
  })();

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { target : { dataset: { duration } } } = e;
    if (duration) {
      onChangeDuration(duration);
    }
  }

  const borderActive = 'b--black';
  const borderInactive = `b--${backgroundColor}`;
  return <div className="mt4" onClick={onClick}>
    <div className={`f6 mono tl ${maxPosition ? 'near-black' : backgroundColor}`} style={{paddingLeft: maxPosition}}>
      {max}
    </div>
    <div style={{height}}>
      <Line datasetKeyProvider={d => d.id} data={data} options={options} />
    </div>
    <div className={`f6 mono tl ${minPosition ? 'near-black' : backgroundColor}`} style={{paddingLeft: minPosition}}>
      {min}
    </div>
    {durations?.length > 0 && <div className="flex flex-row items-center justify-around mt3 mh3">
      {durations.map((d) =>
        <div key={d} className={`pointer f6 ph3 pv2 bb bw2 ${duration === d ? borderActive : borderInactive}`} data-duration={d} onClick={onClick}>{d.toLowerCase()}</div> 
      )}
    </div>}
  </div>
}