export const isValid = (payload) => {
  if (payload && payload.hasOwnProperty('data')) {
    if (Array.isArray(payload.data)) {
      return payload.data.length === 0 || (Boolean(payload.data[0].type) && Boolean(payload.data[0].id));
    }
    return Boolean(payload.data.type) && Boolean(payload.data.id);
  }
}

export const parse = (payload) => {
  let result;
  if (Array.isArray(payload.data)) {
    result = payload.data.map(d => parseOne(d, payload.included))
  } else {
    result = parseOne(payload.data, payload.included);
  }

  if (payload.hasOwnProperty('meta')) {
    result._meta = payload.meta;
  }

  return result;
}

export const parseOne = (payload, included = []) => {
  const result = { ...payload.attributes };
  if (payload.id) {
    result.id = parseInt(payload.id, 10);
  }
  
  if (payload.relationships && included) {
    for (let k in payload.relationships) {
      const v = payload.relationships[k];
      if (Array.isArray(v.data)) {
        result[k] = v.data.map(({ type, id }) => {
          const relation = included.find(r => r.type === type && r.id === id);
          if (relation) {
            return parseOne(relation, included);
          }
        });
      } else {
        const relation = included.find(r => v.data && r.type === v.data.type && r.id === v.data.id);
        if (relation) {
          result[k] = parseOne(relation, included);
        } else {
          result[k] = null;
        }
      }
    }
  }

  return result;
}