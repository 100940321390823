import React, { useState } from 'react';
import chevronDown from '../../assets/img/chevron-down.svg';

export default function Dropdown({ items, isDisabled, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const item = items[selectedIndex];

  const _onSelect = (idx) => {
    setSelectedIndex(idx);
    setIsOpen(false);

    if (idx !== selectedIndex && onSelect) {
      onSelect(items[idx]);
    }
  }

  return <div className="relative">
    <div className={`flex flex-row items-center justify-start f6 ${isDisabled ? 'o-50' : 'pointer'}`} onClick={() => setIsOpen(!isOpen)} style={{pointerEvents: isDisabled ? 'none' : 'auto'}}>
      <div className="link no-underline black hover-blue">{item}</div>
      <img className="ml1" src={chevronDown} style={{width: 12}} />
    </div>
    {isOpen && <div className="pa3 mt1 br2 ba b--light-gray bg-white shadow-4 absolute right-0 tl">
      {items.map((item, idx) => 
        <div key={item} className={`f6 link no-underline black hover-blue pointer ${idx > 0 ? 'pt2' : ''}`} onClick={() => _onSelect(idx)}>{item}</div>)}  
    </div>}
  </div>

}