import React from 'react';
import { Link } from 'react-router-dom';

const firstInitial = user => user?.firstName ? user.firstName[0] : '?';

export default function Avatar({ user, className = '', link, size = 32, shape = 'square', ...rest }) {
  const font = (() => {
    if (size < 26) return 'f5';
    if (size < 36) return 'f4';
    if (size < 51) return 'f3';
    if (size < 61) return 'f2';
    return 'f1';
  })();
  const br = shape === 'square' ? 'br4' : 'br-100';
  return <React.Fragment>
    {link && <Link to={link} className="no-underline" style={{flexShrink: 0}}>
      {user.profilePictureUrl && <img title={`${user.firstName} ${user.lastName}`} src={user.profilePictureUrl} className={`${br} ${className}`} style={{width: size, height: size}}  {...rest} />}
      {!user.profilePictureUrl && <div className={`${br} bg-light-gray silver flex items-center justify-center ttu ${font} ${className}`} title={`${user.firstName} ${user.lastName}`} style={{boxSizing: 'content-box', width: size, height: size}} {...rest}>{firstInitial(user)}</div>}
    </Link>}
    {!link && <React.Fragment>
      {user.profilePictureUrl && <img title={`${user.firstName} ${user.lastName}`} src={user.profilePictureUrl} className={`${br} ${className}`} style={{width: size, height: size, flexShrink: 0}} {...rest} />}
      {!user.profilePictureUrl && <div className={`${br} bg-light-gray silver flex items-center justify-center ttu ${font} ${className}`} title={`${user.firstName} ${user.lastName}`} style={{boxSizing: 'content-box', width: size, height: size, flexShrink: 0}} {...rest}>{firstInitial(user)}</div>}
    </React.Fragment>}
  </React.Fragment>;
}