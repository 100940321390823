const text = {
  '-2': 'Hell No',
  '-1': 'No',
  '0': 'Not Sure',
  '1': 'Yes',
  '2': 'Hell Yes',
};

const sellText = {
  '-1': 'Hold',
  '0': 'Not Sure',
  '1': 'Sell',
};

export function getText(voteValue, isSell) {
  const map = isSell ? sellText : text;
  if (map.hasOwnProperty(voteValue)) return map[voteValue];
  return '';
}