import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loading from '../partials/loading';
import { Button, H2, Input, Radio } from '../partials/ui';
import { QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back';

const steps = [
  'streetAddress',
  'city',
  'state',
  'zip',
];

export default function AccountContact({ client, onBack, onContactCreate }) {
  const { step = steps[0] } = useParams();
  const { returnTo } = QUERY.toObject(useLocation().search);

  const history = useHistory();
  const formRef = useRef(null);
  const [state, setState] = useState('');
  const [user, setUser] = useState(null);
  const [contact, setContact] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        //need to bust cache incase they just added their phone number
        setUser(await client.users.getMe({ noCache: true }));
      } catch {
        //this is fatal, render an error later
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
        try {
          const contact = await client.contacts.get({ userId: user.id });
          setContact(contact);
          if (contact.state) {
            setState(contact.state)
          }
        } catch {} finally {
            setIsLoading(false);
        }
    })();
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(formRef.current);

    const idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      return history.push(`/account/onboarding/contact/${steps[idx + 1]}`);
    }

    setIsDisabled(true);
    const data = {
      userId: user.id,
      email: user.email,
      phone: user.phoneNumber,
      addressOne: form.get('streetAddress'),
      addressTwo: form.get('apartment'),
      city: form.get('city'),
      state: form.get('state'),
      postalCode: form.get('zip'),
      country: 'USA',
    };

    try {
      const updatedContact = await client.contacts.update(data);
      if (onContactCreate) {
        onContactCreate({ contact: updatedContact });
      } else {
        history.push(`/account/onboarding${returnTo ? '?section=' + returnTo : ''}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  const filteredStates = (() => {
    if (!state) return states;
    return states.filter((s) => {
      return s.name.toLowerCase().startsWith(state.toLowerCase()) ||
        s.abbreviation.toLowerCase().startsWith(state.toLowerCase());
    });
  })();

  if (isLoading) return <Loading />;

  let title;
  switch (step) {
    case 'streetAddress':
      title = 'What is your permanent address?';
      break;
    case 'city':
      title = 'What city do you live in?';
      break;
    case 'state':
      title = 'In what state?';
      break;
    case 'zip':
      title = 'What is your zip code?';
      break;
    default:
      title = 'What is your permanent address?';
  }

  const stepIdx = steps.indexOf(step);
  
  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Contact Info" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>
      
        <form name="signUp" onSubmit={onSubmit} ref={formRef}>
            <div className="mb5">
              <H2>{title}</H2>
            </div>
            <div className={`mb5 ${step === 'streetAddress' ? '' : 'dn'}`}>
              <div className="mv4">
                <Input type="text" name="streetAddress" placeholder="Enter street address" required={true} defaultValue={contact?.addressOne} />
              </div>
              <div className="mv4">
                <Input type="text" name="apartment" placeholder="Apt, suite, unit, building (optional)" defaultValue={contact?.addressTwo} />
              </div>
            </div>
            <div className={`mb5 ${step === 'city' ? '' : 'dn'}`}>
              <div className="mv4">
                <Input type="text" name="city" placeholder="Enter city" required={stepIdx >= 1} defaultValue={contact?.city} />
              </div>
            </div>
            <div className={`mb5 ${step === 'state' ? '' : 'dn'}`}>
              <div className="mv4">
                <Input type="text" name="stateSearch" placeholder="Start typing your state" defaultValue={contact?.state} onChange={(e) => setState(e.target.value.trim())} />
                {filteredStates.map(({ abbreviation, name }) =>
                  <div key={abbreviation} className="pv3 bb b--light-gray">
                    <Radio name="state" value={abbreviation} label={name} required={stepIdx >= 2} defaultChecked={contact?.state === abbreviation} />
                  </div>)}
              </div>
            </div>
            <div className={`mb5 ${step === 'zip' ? '' : 'dn'}`}>
              <div className="mv4">
                <Input type="text" name="zip" label="Zipcode" placeholder="Enter zipcode" required={stepIdx >= 3} defaultValue={contact?.postalCode} />
              </div>
            </div>
            <div className="mt4">
              <Button isDisabled={isDisabled} width="w-100" >Continue</Button>
              {error && <div className="f7 mt1 dark-red">{error}</div>}
            </div>
        </form>
    </div>
</div>;
}

const states = [
  {
      name: "Alabama",
      abbreviation: "AL"
  },
  {
      name: "Alaska",
      abbreviation: "AK"
  },
  {
      name: "American Samoa",
      abbreviation: "AS"
  },
  {
      name: "Arizona",
      abbreviation: "AZ"
  },
  {
      name: "Arkansas",
      abbreviation: "AR"
  },
  {
      name: "California",
      abbreviation: "CA"
  },
  {
      name: "Colorado",
      abbreviation: "CO"
  },
  {
      name: "Connecticut",
      abbreviation: "CT"
  },
  {
      name: "Delaware",
      abbreviation: "DE"
  },
  {
      name: "District Of Columbia",
      abbreviation: "DC"
  },
  {
      name: "Federated States Of Micronesia",
      abbreviation: "FM"
  },
  {
      name: "Florida",
      abbreviation: "FL"
  },
  {
      name: "Georgia",
      abbreviation: "GA"
  },
  {
      name: "Guam",
      abbreviation: "GU"
  },
  {
      name: "Hawaii",
      abbreviation: "HI"
  },
  {
      name: "Idaho",
      abbreviation: "ID"
  },
  {
      name: "Illinois",
      abbreviation: "IL"
  },
  {
      name: "Indiana",
      abbreviation: "IN"
  },
  {
      name: "Iowa",
      abbreviation: "IA"
  },
  {
      name: "Kansas",
      abbreviation: "KS"
  },
  {
      name: "Kentucky",
      abbreviation: "KY"
  },
  {
      name: "Louisiana",
      abbreviation: "LA"
  },
  {
      name: "Maine",
      abbreviation: "ME"
  },
  {
      name: "Marshall Islands",
      abbreviation: "MH"
  },
  {
      name: "Maryland",
      abbreviation: "MD"
  },
  {
      name: "Massachusetts",
      abbreviation: "MA"
  },
  {
      name: "Michigan",
      abbreviation: "MI"
  },
  {
      name: "Minnesota",
      abbreviation: "MN"
  },
  {
      name: "Mississippi",
      abbreviation: "MS"
  },
  {
      name: "Missouri",
      abbreviation: "MO"
  },
  {
      name: "Montana",
      abbreviation: "MT"
  },
  {
      name: "Nebraska",
      abbreviation: "NE"
  },
  {
      name: "Nevada",
      abbreviation: "NV"
  },
  {
      name: "New Hampshire",
      abbreviation: "NH"
  },
  {
      name: "New Jersey",
      abbreviation: "NJ"
  },
  {
      name: "New Mexico",
      abbreviation: "NM"
  },
  {
      name: "New York",
      abbreviation: "NY"
  },
  {
      name: "North Carolina",
      abbreviation: "NC"
  },
  {
      name: "North Dakota",
      abbreviation: "ND"
  },
  {
      name: "Northern Mariana Islands",
      abbreviation: "MP"
  },
  {
      name: "Ohio",
      abbreviation: "OH"
  },
  {
      name: "Oklahoma",
      abbreviation: "OK"
  },
  {
      name: "Oregon",
      abbreviation: "OR"
  },
  {
      name: "Palau",
      abbreviation: "PW"
  },
  {
      name: "Pennsylvania",
      abbreviation: "PA"
  },
  {
      name: "Puerto Rico",
      abbreviation: "PR"
  },
  {
      name: "Rhode Island",
      abbreviation: "RI"
  },
  {
      name: "South Carolina",
      abbreviation: "SC"
  },
  {
      name: "South Dakota",
      abbreviation: "SD"
  },
  {
      name: "Tennessee",
      abbreviation: "TN"
  },
  {
      name: "Texas",
      abbreviation: "TX"
  },
  {
      name: "Utah",
      abbreviation: "UT"
  },
  {
      name: "Vermont",
      abbreviation: "VT"
  },
  {
      name: "Virgin Islands",
      abbreviation: "VI"
  },
  {
      name: "Virginia",
      abbreviation: "VA"
  },
  {
      name: "Washington",
      abbreviation: "WA"
  },
  {
      name: "West Virginia",
      abbreviation: "WV"
  },
  {
      name: "Wisconsin",
      abbreviation: "WI"
  },
  {
      name: "Wyoming",
      abbreviation: "WY"
  }
]