import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Loading from './partials/loading';
import { MONEY, QUERY } from '../utils';
import { BigNumber, BottomCard, Button, RatingBadge, TinyButton } from './partials/ui';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import chevronRight from './assets/img/chevron-right.svg';
import infoIcon from './assets/img/info.svg';
import warningIcon from './assets/img/warning.svg';

export default function GroupBuy({ client }) {
  const history = useHistory();
  const { groupBuyUserId } = useParams();
  const { returnTo = '/' } = QUERY.toObject(useLocation().search);
  const [groupBuy, setGroupBuy] = useState(null);
  const [isShowingMinimumInfo, setIsShowingMinimumInfo] = useState(null);

  useEffect(() => {
    (async () => {
      setGroupBuy(await client.groupBuys.getOne({ groupBuyUserId }));
    })();
  }, []);

  if (!groupBuy) return <Loading />;

  return <div>
    <Nav client={client} leftButton={BackButton} title={groupBuy.group.name} footer={false} />
    <div className="center w-100 pt6-ns pt3" style={{maxWidth: 500, paddingLeft: 24, paddingRight: 24}}>
      <div style={{paddingBottom: 120}}>
        <div className="tc mt2 mb4">
          <BigNumber >{MONEY.formattedDollars((groupBuy.amount + groupBuy.reinvestedAmount) / 100)}</BigNumber>
          {groupBuy.reinvestedAmount > 0 && <div className="mt2 f6 dark-gray">
            {MONEY.formattedDollars(groupBuy.amount / 100, { wholeDollar: true })} + {MONEY.formattedDollars(groupBuy.reinvestedAmount / 100, { wholeDollar: true })} reinvested
          </div>}
        </div>
        {groupBuy.skippedTickers?.length > 0 && <div className="flex flex-row items-center justify-start bg-near-white f6 lh-copy br4 pa3 mb3" onClick={() => setIsShowingMinimumInfo(true)}>
          <img src={warningIcon} className="mr2" style={{width: 18}} />
          <div>{groupBuy.skippedTickers.length} asset{groupBuy.skippedTickers.length === 1 ? ' was' : 's were'} skipped because your investment is less than $1 per asset. <span className="underline">Learn more.</span></div>
        </div>}
        {!groupBuy.skippedTickers?.length && groupBuy.status !== 'orders_filled' && <div className="flex flex-row items-center justify-start bg-near-white f6 lh-copy br4 pa3 mb3">
          <img src={infoIcon} className="mr2" style={{width: 18}} />
          <div>
            {groupBuy.status === 'pending_orders' && `Orders are pending and will be sent when the market opens.`}
            {groupBuy.status === 'orders_pending' && `Orders have been sent to the market and are waiting to be filled.`}
            {groupBuy.status === 'orders_failed' && `Something went wrong. Please contact support@tryshare.app.`}
          </div>
        </div>}
        {groupBuy.orders.map((order, idx) => 
          <Link key={`order-${order.id}`} to={`/history/orders/${order.id}`} className="black no-underline">
            <div className={`ph3 ba br5 b--light-gray flex flex-row items-center justify-between`} style={{paddingTop: 24, paddingBottom: 24, marginTop: idx > 0 ? -1 : 0}}>
                <div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="f5 bold">
                      {order.ticker}
                    </div>
                    <RatingBadge type={order.side} />
                  </div>
                  <div className="f6 mt2 silver flex flex-row items-center justify-start">
                    {!order.submittedAt && <div>Pending</div>}
                    {order.submittedAt && <div>{moment.unix(order.submittedAt).format('MMM D, YYYY')}</div>}
                    {Boolean(order.status) && <>
                      <div className="ph1">{String.fromCharCode(183)}</div>
                      <div>{_.startCase(order.status)}</div>
                    </>}
                  </div>
                </div>
                <div className="flex flex-row items-center f4 mono">
                  {Boolean(order.notional) && MONEY.formattedDollars(order.notional/100)}
                  {!Boolean(order.notional) && Boolean(order.filledQuantity) && MONEY.formattedDollars(parseFloat(order.filledAveragePrice) * parseFloat(order.filledQuantity))}
                  <img className="ml2" src={chevronRight} style={{width: 15, height: 15}} />
                </div>
            </div>
            </Link>)}
      </div>
    </div>
    <div className="fixed dn-ns w-100 center bg-white" style={{bottom: 0, padding: 24}}>
      <Link to={returnTo}>
        <Button width="w-100">Done</Button>
      </Link>
    </div>
    {isShowingMinimumInfo && <BottomCard dim>
      <div className="mv3 f4 bold">
        About minimums
      </div>
      <div className="f5 lh-copy">
        The smallest amount of stock Share will purchase is $1. Any strategy that has more stocks than dollars being invested each week will alternate stocks weekly.
        For example, if you are investing $5 in 10 stocks, 5 will be bought one week, and the other five the next week. To invest in all stocks every week, set your investment to at least $1 per stock.
      </div>
      <div className="mv2">
        <TinyButton width="w-100" className="nl1" onClick={() => history.push(`/investments`)}>Update investment</TinyButton>
      </div>
      <TinyButton width="w-100" className="nl1" onClick={() => setIsShowingMinimumInfo(false)}>Dismiss</TinyButton>
    </BottomCard>}
  </div>;
}
