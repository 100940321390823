import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import { COOKIES } from '../utils';

export default function AccountStatements({ client }) {
  const [accountStatements, setAccountStatements] = useState(null);

  useEffect(() => {
    (async () => {
      setAccountStatements(await client.accountStatements.get());
    })();
  }, []);

  return <div>
    <Nav client={client} leftButton={BackButton} title="Account Statements" footer={false} />
    <div className="center w-100-ns w-90 pt6-ns pt3" style={{maxWidth: 500, paddingBottom: 80}}>
    <div className="pb3">
    {Array.isArray(accountStatements) && accountStatements.map((accountStatement, idx) =>
    <div key={`account-statement-${accountStatement.uuid}`} className={`pv4 ${idx < accountStatements.length - 1 ? 'bb' : 'bn'} b--light-gray flex flex-row items-center justify-between`}>
      <div>
        <a className="black" href={`${process.env.API_HOST}/documents/account-statement/${accountStatement.uuid}/download?token=${COOKIES.get('id_token')}`} target="_blank">
          <div className="f5">{accountStatement.date}</div>
        </a>
      </div>
    </div>)}
    </div>
    </div>
  </div>;
}
