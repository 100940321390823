import React, { useRef } from 'react';
import _ from 'lodash';

export default function Radio({ name, errors, label, hint, hintPlacement, required, value, ...rest }) {
  const ref = useRef();
  const error = errors ? errors[name] : false;

  const onClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  }

  return <div className="flex flex-row items-center justify-start radio">
    <input
    type="radio"
    className={`ba border-box ${error ? 'b--dark-red' : 'b--moon-gray'} mr3`}
    id={`${name}-${value}`}
    name={name}
    required={required === true}
    value={value}
    ref={ref}
    {...rest} />
    <span className="radio__control" onClick={onClick} style={{flexShrink: 0}}></span>
    {label !== "" && <label htmlFor={`${name}-${value}`} className={`${error ? 'dark-red' : 'black'} f5 pl2 w-100 tl`}>{label || _.startCase(name)}</label>}
    {hint && <div className={`w-100 mb3 f7 silver lh-copy ${hintPlacement || 'tl'}`}>{hint}</div>}
  </div>;
}
