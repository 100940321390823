import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import { Button, NumberPad } from './partials/ui';
import { MONEY } from '../utils';
import bankIcon from './assets/img/bank.svg';

const displayAmount = (amount) => {
  let text = '$0';
  if (amount) {
    text = MONEY.formattedDollars(parseFloat(amount), { wholeDollar: !amount.includes('.') });
  }
  return <><sup style={{fontSize: '50%', top: '-0.75em' }}>$</sup>{text.slice(1)}</>;
}

export default function NewTransfer({ client }) {
  const history = useHistory();
  const { direction = 'incoming' } = useParams();
  const [account, setAccount] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [amount, setAmount] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const user = await client.users.getMe();
        if (!user.paymentMethodId) {
          throw new Error(`user does not have payment method`);
        }
        setPaymentMethod(await client.paymentMethods.getOne({ id: user.paymentMethodId }));
        if (direction === 'outgoing') {
          setAccount(await client.accounts.getMine());
        }
      } catch (e) {
        console.error(e);
        history.push(`/`);
      }
    })();
  }, []);

  const onSubmit = async (e) => {
    if (!isReviewing) {
      setIsReviewing(true);
      return;
    }

    try {
      setIsSending(true);
      const transfer = await client.transfers.create({
        amount: parseFloat(amount) * 100,
        direction: direction.toUpperCase(),
      });
      setTransfer(transfer);
      setError(null);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };


  const isValid = (() => {
    if (paymentMethod?.status !== 'APPROVED') return false;
    if (!amount) return false;
    const amountInCents = parseFloat(amount) * 100;
    if (amountInCents <= 0) return false;
    if (direction === 'outgoing') {
      return amountInCents <= account?.nonGroupCashWithdrawable; 
    }
    if (direction === 'incoming') {
      return amountInCents >= 500;
    }

    return true;
  })();

  return <div className="w-100 vh-100">
    <Nav client={client} leftButton={BackButton} title={direction === 'incoming' ? `Transfer to Share` : `Transfer to Bank`} footer={false} />
    {!transfer && <div className="w-100-ns w-90 center flex flex-column items-center justify-between" style={{height: 'calc(100% - 40px)', maxWidth: 500}}>
      <div className="w-100 tc mt5">
        <div className={`bold pt3 ${amount.length ? 'black' : 'silver'}`} style={{fontSize: 72}}>{displayAmount(amount)}</div>
        {!isReviewing && account && <div className="mt4 pv2 mb2 flex flex-row w-100 justify-between bb b--light-gray">
          <div>Cash available</div>
          <div className="bold">{MONEY.formattedDollars(account.nonGroupCashWithdrawable / 100)}</div>
        </div>}
        {!isReviewing && direction === 'incoming' && <div className="pt3 f6 silver">
          Minimum $5.00
        </div>}
      </div>
      <div className="w-100">
        {paymentMethod && <>
          {isReviewing && <div className="mb2 tl f7">Funds will be transferred immediately.</div>}
          <div className="flex items-center justify-start pa3 ba b--moon-gray br3 mb3">
            <div className="pr3">
              <img src={bankIcon} style={{width: 24, height: 24}} />
            </div>
            <div>
              <div className="f6">{paymentMethod.name}</div>
              <div className="f7 mt1">{_.times(4, () => String.fromCharCode(0x2022))}{paymentMethod.mask}</div>
            </div>
          </div>
        </>}
        {!isReviewing && <NumberPad allowDecimal={true} amount={amount} onChange={setAmount} max={50000} />}
        <div className="mv3">
          {error && <div className="dark-red f7 mb1">{error}</div>}
          <Button isDisabled={!isValid || isSending} width="w-100" onClick={onSubmit}>{isReviewing ? 'Confirm' : 'Review'}</Button>
          {paymentMethod && paymentMethod.status !== 'APPROVED' && <div className="f7 mt1">Your payment method is currently {paymentMethod.status.toLowerCase()}.</div>}
          {isReviewing && <div className="mt2">
            <Button width="w-100" outline="none" onClick={() => setIsReviewing(false)}>Edit</Button>
          </div>}
        </div>
       </div>
    </div>}
    {transfer && <div className="w-100-ns w-90 center mt5" style={{maxWidth: 500}}>
      <div className="tr">
        <div className="f3 bold w-100 tc mv3">Transfer Scheduled</div>
        <div className="tc f6 mb2">{MONEY.formattedDollars(transfer.amount / 100)} will be withdrawn in 1-2 business days.</div>
        <div className="fixed w-100-ns w-90 pv3" style={{bottom: 0}}>
        <Button width="w-100" onClick={() => history.push('/')}>Finish</Button>
        </div>
      </div>
    </div>}
  </div>;
}