import React from 'react';
import info from '../../assets/img/info.svg';

export default function Info({ text }) {
  return <div className="w-100 pa3 bg-near-black white flex flex-row items-center justify-start">
    <div style={{flexShrink: 0}} className="mr3">
      <img src={info} style={{width: 20, height: 20}} />
    </div>
    <div className="f7 lh-copy">{text}</div>
  </div>;
}