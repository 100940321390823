import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Loading from './partials/loading';
import { COOKIES, MONEY, QUERY } from '../utils';
import { Button, H2 } from './partials/ui';
import Nav from './partials/nav';
import BackButton from './partials/button-back';

export default function Order({ client }) {
  const { id } = useParams();
  const { returnTo = '/' } = QUERY.toObject(useLocation().search);
  const [order, setOrder] = useState(null);
  const [group, setGroup] = useState(null);
  const [tradeConfirmation, setTradeConfirmation] = useState(null);

  useEffect(() => {
    (async () => {
      setOrder(await client.orders.getOne({ id }));
    })();
  }, []);

  useEffect(() => {
    if (!order) return;
    (async () => {
      if (order.groupId) {
        setGroup(await client.groups.getOne({ id: order.groupId })); 
      }

      if (order.status === 'filled') {
        try {
          const date = moment.unix(order.filledAt).format('YYYY-MM-DD');
          const [tc] = await client.tradeConfirmations.get({ date });
          setTradeConfirmation(tc);
        } catch {}
      }
    })();
  }, [order]);

  if (!order) return <Loading />;

  return <div>
    <Nav client={client} leftButton={BackButton} title={`Order ${order.id}`} footer={false} />
    <div className="center w-100 pt6-ns pt3" style={{maxWidth: 500, paddingLeft: 24, paddingRight: 24}}>
      <div style={{paddingBottom: 80}}>
        <H2>{_.startCase(order.type)} {_.startCase(order.side)}</H2>
        <div className="mt2 f5">
          {order.company.displayName}
        </div>

        {Boolean(group) && <LabelValue label="Strategy" value={group.name} />}

        <LabelValue label="Order Status" value={_.startCase(order.status || 'New')} />

        {order.submittedAt && <LabelValue label="Submitted" value={moment.unix(order.submittedAt).format('MMM D, YYYY, h:mm A')} />}
        
        {order.status === 'filled' && <LabelValue label="Filled" value={moment.unix(order.filledAt).format('MMM D, YYYY, h:mm A')} />}

        {Boolean(order.notional) && <LabelValue label="Notional" value={MONEY.formattedDollars(order.notional / 100)} />}
        
        {Boolean(order.quantity) && <LabelValue label="Quantity" value={`${order.quantity} shares`} />}

        {order.status === 'filled' && <LabelValue label="Filled Quantity" value={`${order.filledQuantity} shares at ${MONEY.formattedDollars(order.filledAveragePrice)}`} />}
      </div>
    </div>
    {tradeConfirmation && <div className="fixed w-100 tc" style={{bottom: 24, paddingLeft: 24, paddingRight: 24}}>
      <a href={`${process.env.API_HOST}/documents/trade-confirmation/${tradeConfirmation.uuid}/download?token=${COOKIES.get('id_token')}`} target="_blank">
        <Button width="w-100" outline={true} style={{maxWidth: 500}}>View trade confirmation</Button>
      </a>
    </div>}
    {!tradeConfirmation && <div className="fixed dn-ns w-100 center" style={{bottom: 24, paddingLeft: 24, paddingRight: 24}}>
      <Link to={returnTo}>
        <Button width="w-100">Done</Button>
      </Link>
    </div>}
  </div>;
}

function LabelValue({ label, value }) {
  return <div className="f5-ns f6 mt4">
    <div className="silver mb2">{label}</div>
    <div>{value}</div>
  </div>
}