import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../partials/loading';
import { Button, H2, Radio } from '../partials/ui'
import { QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back';

const steps = [
  'annualIncome',
  'liquidNetWorth',
  'totalNetWorth',
];

export default function AccountIncome({ client, onBack, onIncomeCreate }) {
  const history = useHistory();
  const { step = steps[0] } = useParams();
  const { returnTo } = QUERY.toObject(useLocation().search);

  const [user, setUser] = useState(null);
  const [income, setIncome] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
        try {
            setIncome(await client.incomes.get({ userId: user.id }));
        } catch {} finally {
            setIsLoading(false);
        }
    })();
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const idx = steps.indexOf(step);
    if (idx < steps.length - 1) {
      return history.push(`/account/onboarding/income/${steps[idx + 1]}`);
    }

    const form = new FormData(e.target);

    const annualIncome = form.get('annualIncome');
    if (!annualIncome) return;
    const { 
      min: annualIncomeMin,
      max: annualIncomeMax,
    } = _.find(incomes, ['value', annualIncome]);

    const liquidNetWorth = form.get('liquidNetWorth');
    if (!liquidNetWorth) return;
    const { 
      min: liquidNetWorthMin,
      max: liquidNetWorthMax,
    } = _.find(worths, ['value', liquidNetWorth]);

    const totalNetWorth = form.get('totalNetWorth');
    if (!totalNetWorth) return;
    const { 
      min: totalNetWorthMin,
      max: totalNetWorthMax,
    } = _.find(worths, ['value', totalNetWorth]);

    const data = {
      userId: user.id,
      annualIncomeMin,
      annualIncomeMax,
      liquidNetWorthMin,
      liquidNetWorthMax,
      totalNetWorthMin,
      totalNetWorthMax,
    };

    try {
      setIsDisabled(true);
      const income = await client.incomes.update(data);
      if (onIncomeCreate) {
        onIncomeCreate({ income });
      } else {
        history.push(`/account/onboarding${returnTo ? '?section=' + returnTo : ''}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  if (isLoading) return <Loading />;

  let title, subtitle;
  switch (step) {
    case 'annualIncome':
      title = 'What\'s your annual income?';
      subtitle = 'We are required to ask these questions by the US government.';
      break;
    case 'liquidNetWorth':
      title = 'What\'s your liquid net worth?';
      subtitle = 'How much cash could you access quickly (within 30 days)?';
      break;
    case 'totalNetWorth':
      title = 'What\'s your total net worth?';
      subtitle = 'All your assets.';
      break;
  }

  const stepIdx = steps.indexOf(step);
  
  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Income" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb5">
          <H2>{title}</H2>
          {subtitle && <div className="near-black">{subtitle}</div>}
        </div>

        <div className={`mv4 ${step === 'annualIncome' ? '' : 'dn'}`}>
          {incomes.map(({ name, value }) =>
            <div key={value} className="pv3 bb b--light-gray">
              <Radio name="annualIncome" value={value} label={name} required={true} defaultChecked={income?.annualIncomeMin == value } />
            </div>)}
        </div>

        <div className={`mv4 ${step === 'liquidNetWorth' ? '' : 'dn'}`}>
          {worths.map(({ name, value }) =>
            <div key={value} className="pv3 bb b--light-gray">
              <Radio name="liquidNetWorth" value={value} label={name} required={stepIdx >= 1} defaultChecked={income?.liquidNetWorthMin == value } />
            </div>)}
        </div>

        <div className={`mv4 ${step === 'totalNetWorth' ? '' : 'dn'}`}>
          {worths.map(({ name, value }) =>
            <div key={value} className="pv3 bb b--light-gray">
              <Radio name="totalNetWorth" value={value} label={name} required={stepIdx >= 2} defaultChecked={income?.totalNetWorthMin == value } />
            </div>)}
        </div>

        <div className="mt4">
          <Button isDisabled={isDisabled} width="w-100">Continue</Button>
          {error && <div className="f7 mt1 dark-red">{error}</div>}
        </div>
      </form>
  </div>
</div>;
}

const incomes = [
  { name: '$0 - $25,000', value: '0', min: 0, max: 25000 },
  { name: '$25,000 - $50,000', value: '25000', min: 25000, max: 50000 },
  { name: '$50,000 - $100,000', value: '50000', min: 50000, max: 100000 },
  { name: '$100,000 - $250,000', value: '100000', min: 100000, max: 250000 },
  { name: '$250,000 - $500,000', value: '250000', min: 250000, max: 500000 },
  { name: 'Over $500,000', value: '500000', min: 500000, max: -1 },
];

const worths = [
  { name: '$0 - $25,000', value: '0', min: 0, max: 25000 },
  { name: '$25,000 - $50,000', value: '25000', min: 25000, max: 50000 },
  { name: '$50,000 - $250,000', value: '50000', min: 50000, max: 250000 },
  { name: '$250,000 - $500,000', value: '250000', min: 250000, max: 500000 },
  { name: '$500,000 - $1,000,000', value: '500000', min: 500000, max: 1000000 },
  { name: 'Over $1,000,000', value: '1000000', min: 1000000, max: -1 },
];

