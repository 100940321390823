import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FullscreenLoader, H2 } from './partials/ui';
import chevronRight from './assets/img/chevron-right.svg'
import Nav from './partials/nav';
import BackButton from './partials/button-back';

export default function Invitations({ client }) {
  const [invitations, setInvitations] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    (async () => {
      try {
        const invitations = await client.invitations.get({ direction: 'incoming' });
        setInvitations(invitations.filter(i => i.status === 'pending'));
      } catch (e) {
        setInvitations([]);
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);

  if (isFetching) return <FullscreenLoader />;

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Invitations" />
    <div className="w-100 flex align-center justify-around pt6-ns pt3" style={{paddingBottom: 80}}>
      <div className="tl w-100-ns w-90" style={{maxWidth: 500}}>
        {Array.isArray(invitations) && invitations.length === 0 && <>
          <div className="f5 tc mt4 silver">No pending invitations.</div>
        </>}
        {Array.isArray(invitations) && invitations.map((i, idx) =>
          <Link key={`invitation-${i.id}`} to={`/join/${i.group.code}`} className="black no-underline">
          <div className={`${idx + 1 === invitations.length ? 'bn' : 'bb'} b--light-gray pv4 flex flex-row items-center justify-between`}>
            <div className="flex flex-column">
              <div className="f5 mb1">{i.group.name}</div>
              {i.fromUserId && i.user && <div className="f6 light-silver">Sent by @{i.user.username}</div>}
            </div>
            <img src={chevronRight} style={{width: 15, height: 15}} />
          </div>
          </Link>)}
      </div>
    </div>
  </div>;
}