import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import BackButton from './partials/button-back';
import Loading from './partials/loading';
import Nav from './partials/nav';
import { BottomCard, Button, Loader, NumberPad } from './partials/ui';
import { MONEY, QUERY } from '../utils';
import bankIcon from './assets/img/bank.svg';
import chevronRight from './assets/img/chevron-right.svg';
import shareIcon from './assets/img/logo-mark.svg';

const displayAmount = (amount) => {
  let text = !amount.length ? '$0' : MONEY.formattedDollars(parseFloat(amount), { wholeDollar: true });
  return <><sup style={{fontSize: '50%', top: '-0.75em' }}>$</sup>{text.slice(1)}</>;
}

export default function NewGroupBuy({ client }) {
  const history = useHistory();
  const { search } = useLocation();
  const query = QUERY.toObject(search);
  const params = useParams();
  const { groupId, groupBuyId } = params;
  const [account, setAccount] = useState(null);
  const [group, setGroup] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isChoosingPaymentMethod, setIsChoosingPaymentMethod] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [amount, setAmount] = useState('');
  const [state, setState] = useState('amount');
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setAccount(await client.accounts.getMine());
        const { isMarketOpen } = await client.calendar.isMarketOpen();
        setIsMarketOpen(isMarketOpen);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setGroup(await client.groups.getOne({ id: groupId }));
      setPaymentMethods(await client.paymentMethods.get());
    })();
  }, []);

  useEffect(() => {
    if (!groupBuyId) return;
    setTimeout(async () => {
      const next = `/history/group-buys/${groupBuyId}?returnTo=${query.returnTo || '/portfolio'}`;
      history.replace(next);
    }, 3000);
  }, [groupBuyId]);

  const onClickPaymentMethod = () => {
    if (state === 'review') setState('amount');
    if (!account?.isActive || !account.isFundable) return;
    setIsChoosingPaymentMethod(true);
  };

  const onChoosePaymentMethod = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    setIsChoosingPaymentMethod(false);
  }

  const next = async () => {
    if (state === 'amount') {
      setState('review');
      return;
    }

    try {
      setIsSending(true);

      const params = {
        groupId: group.id,
        notional: amount * 100,
      };

      if (paymentMethod) {
        params.paymentMethodId = paymentMethod.id;
      }

      const groupBuyUser = await client.groupBuys.create(params);
      if (!isMarketOpen || paymentMethod) {
        //if the orders can't be filled, skip the waiting period
        history.replace(`/history/group-buys/${groupBuyUser.id}?returnTo=${query.returnTo || '/portfolio'}`);
      } else {
        history.replace(`/group-buys/new/${groupId}/${groupBuyUser.id}`);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  if (!group) return <Loading />;

  if (groupBuyId) {
    return <div className="w-100 vh-100">
      <Nav leftButton={BackButton} client={client} title={group.name} footer={false} />
      <div style={{height: 'calc(100% - 40px)'}}>
        <div className="w-50 mt6 center">
          <Loader />
        </div>
        <div className="mt6 f3 bold tc">Buy processing.</div>
      </div>
    </div>
  }

  const canBuy = account?.isActive && account?.isFundable;
  const accountBuyingPower = account?.nonGroupBuyingPower || 0;
  const minimum = group.groupCompanies.filter(gc => gc.rating === 'buy').length;

  const isAmountValid = (() => {
    if (!account) return false;
    if (amount === '' || amount === 0) return false;
    if (amount < minimum) return false;
    const buyingPower = paymentMethod ? 5000000 : accountBuyingPower;
    return amount * 100 <= buyingPower; 
  })();

  return <div className="absolute absolute--fill">
    <Nav leftButton={BackButton} client={client} title={`Buy ${group.name}`} footer={false} />
    <div className="pt3 pt6-ns relative center" style={{height: 'calc(100% - 40px)', maxWidth: 700}}>
      <div className="flex flex-column h-100 w-100 justify-between" style={{bottom: 20}}>
        <div className="mt3">
          <div className={`flex flex-row items-center justify-center mt4`} style={{paddingLeft: 24, paddingRight: 24}}>
            <div>
              <div className={`bold`} style={{fontSize: amount.length >= 10 ? 48 : 72}}>{displayAmount(amount)}</div>
              {minimum > 0 && <div className="mid-gray mt2 tc">{MONEY.formattedDollars(minimum, { wholeDollar: true })} minimum</div>}
            </div>
          </div>
        </div>

        <div style={{paddingLeft: 24, paddingRight: 24}}>
          <div className="mt4 mb3 pointer" onClick={onClickPaymentMethod}>
            {!paymentMethod && <div className="flex items-center justify-between pa3 ba b--moon-gray br3 mb3">
              <div className="flex items-center">
                <div className="pr3">
                  <img src={shareIcon} style={{width: 24, height: 24}} />
                </div>

                <div>
                  <div className="f6">Share Account</div>
                  {Boolean(account) && <div className="f7 mt1">{MONEY.formattedDollars(accountBuyingPower / 100)} available</div>}
                  {!account && <div className="f7 mt1">$-.-- available</div>}
                </div>
              </div>
              <div className="flex items-center">
                <div className="f6">Switch</div>
                <img className="ml1" src={chevronRight} style={{width: 6}} />
              </div>
            </div>}
            {paymentMethod && <>
              <div className="flex items-center justify-between pa3 ba b--moon-gray br3 mb3">
                <div className="flex items-center">
                  <div className="pr3">
                    <img src={bankIcon} style={{width: 24, height: 24}} />
                  </div>
                  <div>
                    <div className="f6">{paymentMethod.name}</div>
                    <div className="f7 mt1">{_.times(4, () => String.fromCharCode(0x2022))}{paymentMethod.mask}</div>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="f6">Switch</div>
                  <img className="ml1" src={chevronRight} style={{width: 6}} />
                </div>

              </div>
            </>}
          </div>

          {state === 'amount' && <>
            <NumberPad allowDecimal={false} amount={amount} onChange={setAmount} max={50000} isDisabled={account ? !account.isActive || !account.isFundable : false} />
          </>}
          <div className="center mt3 mb3">
            {state === 'review' && <div className="f5 lh-copy tl mb">
              You are making a one-time investment in {group.name}. {MONEY.formattedDollars(amount)} will be divided equally between all stocks in the strategy. Each stock will be bought at the best available market price. {Boolean(paymentMethod) && <>Funds will be transferred immediately from {paymentMethod.name}.</>}
            </div>}
            <Button isDisabled={!isAmountValid || isSending} width={`w-100`} onClick={next}>{state === 'amount' ? 'Review' : 'Confirm'}</Button>
            {state === 'review' && <div className="mt2">
              <Button width="w-100" outline="none" onClick={() => setState('amount')}>Edit</Button> 
            </div>}
            {state === 'amount' && <>
              {canBuy && isMarketOpen === false && <div className="mt2 tc f7 silver">Market is currently closed, orders will be queued.</div>}
              {!canBuy && <div className="mt2 tc f7 silver">Your account or payment method are not yet activated.</div>}
            </>}
            {error && <div className="mt2 tc f7 red">{error}</div>}
          </div>
        </div>
      </div>
    </div>
    {isChoosingPaymentMethod && <BottomCard dim onDismiss={() => setIsChoosingPaymentMethod(false)}>
      <div className="center-ns" style={{maxWidth: 700}}>
        <div className="flex flex-row items-center justify-start pv3 pointer bb b--light-gray" onClick={() => onChoosePaymentMethod(null)}>
          <div className="pr3">
            <img src={shareIcon} style={{width: 24, height: 24}} />
          </div>
          <div>
            <div className="f6">Share Account</div>
            <div className="f7 mt1">
              {MONEY.formattedDollars(accountBuyingPower / 100)} available{account?.reservedBuyingPower > 0 && '*'}
            </div>
          </div>
        </div>
        {paymentMethods.map((paymentMethod) =>
          <div key={`${paymentMethod.id}`} className="flex flex-row items-center justify-start pv3 pointer bb b--light-gray" onClick={() => onChoosePaymentMethod(paymentMethod)}>
            <div className="pr3">
              <img src={bankIcon} style={{width: 24, height: 24}} />
            </div>
            <div>
              <div className="f6">{paymentMethod.name}</div>
              <div className="f7 mt1">{_.times(4, () => String.fromCharCode(0x2022))}{paymentMethod.mask}</div>
            </div>
          </div>)}
          <div className="pv3 f6">
            If you use a bank account, a transfer will be initiated immediately.
            Ensure you have sufficient funds in your account to cover the investment amount.
            
            {account?.reservedBuyingPower > 0 && <div className="mt2">
              *{MONEY.formattedDollars(account.reservedBuyingPower / 100,  { wholeDollar: true })} is reserved for your next weekly investment.
            </div>}
          </div>

      </div>
    </BottomCard>}
  </div>
}
