import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { BigNumber, BottomCard, Button, NumberPad, Radio, TinyButton } from './partials/ui';
import BackButton from './partials/button-back';
import editIcon from './assets/img/edit.svg';
import infoIcon from './assets/img/info.svg';
import pauseIcon from './assets/img/pause.svg';
import playIcon from './assets/img/play.svg';
import warningIcon from './assets/img/warning.svg';
import { MONEY } from '../utils';
import Nav from './partials/nav';

export default function Investments({ client }) {
  const [account, setAccount] = useState(null);
  const [groups, setGroups] = useState(null);
  const [groupCompanies, setGroupCompanies] = useState({});
  const [editingDepositFrequency, setEditingDepositFrequency] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [isPausing, setIsPausing] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    (async () => {
      const [
        account,
        groups,
      ] = await Promise.all([
        client.accounts.getMine(),
        client.groups.getMine(),
      ]);

      setAccount(account);
      setGroups(groups);
    })();
  }, []);

  useEffect(() => {
    if (!groups) return;
    (async () => {
      const ggcs = await Promise.all(groups.map((g) => 
        client.groupCompanies.get({ groupId: g.id })));
      setGroupCompanies(_.zipObject(groups.map(g => g.id), ggcs.map(gcs => gcs.filter(gc => gc.rating === 'buy'))));
    })();
  }, [groups]);

  if (!account) return null;

  const monthlyTotal = groups?.filter(g => !g.membership.isPaused).reduce((sum, g) => (sum + g.membership.monthlyContribution), 0) || 0;
  const depositTotal = _.reduce(groups, (sum, group) => {
    if (group.membership.isPaused) return sum;
    switch (account.depositFrequency) {
      case 'monthly':
        return sum + group.membership.monthlyContribution;
      case 'twice_a_month':
        return sum + (group.membership.monthlyContribution >> 1);
      case 'weekly':
        return sum + (group.membership.monthlyContribution >> 2);
    }
  }, 0);

  const onUpdate = (group) => {
    setGroups(prevGroups => prevGroups.map(g => g.id === group.id ? group : g));
    setEditingGroup(group);
  };

  const updateDepositFrequency = async (depositFrequency) => {
    if (isSending) return;

    try {
      setIsSending(true);
      setAccount(await client.accounts.update({
        uuid: account.uuid,
        depositFrequency,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const onDismiss = () => setEditingGroup(null);

  const isUnderFunded = (group) => {
    if (group.membership.isPaused || group.membership.monthlyContribution === 0) return false;
    if (!groupCompanies.hasOwnProperty(group.id)) return false;
    if (groupCompanies[group.id].length > group.membership.monthlyContribution / 400) return true;
    return false;
  }

  const activeGroups = groups?.filter(g => !g.membership.isPaused) || [];
  const pausedGroups = groups?.filter(g => g.membership.isPaused) || [];

  const pauseAll = async (e) => {
    if (!isPausing) {
      setIsPausing(true);
      return;
    }

    try {
      setIsSending(true);
      const form = new FormData(e.target);
      const pauseReason = form.get('pauseReason');

      setGroups(await Promise.all(groups.map(async (g) => {
        if (g.membership.isPaused) return Promise.resolve(g);
        const membership = await client.groups.updateMembership({
          id: g.id,
          isPaused: true,
          pauseReason,
        });
        return {
          ...g,
          membership,
        };
      })));
    } catch (e) {
      setGroups(await client.groups.getMine());
      console.error(e);
    } finally {
      setIsSending(false);
      setIsPausing(false);
    }
  }

  const resumeAll = async () => {
    try {
      setIsSending(true);
      setGroups(await Promise.all(groups.map(async (g) => {
        if (!g.membership.isPaused) return Promise.resolve(g);
        const membership = await client.groups.updateMembership({
          id: g.id,
          isPaused: false,
        });
        return {
          ...g,
          membership,
        };
      })));
    } catch (e) {
      setGroups(await client.groups.getMine());
      console.error(e);
    } finally {
      setIsSending(false);
    }
  }

  return <div className="vh-100" style={{paddingBottom: 80}}>
    <Nav client={client} leftButton={BackButton} title="My Investments" />
    <div className="w-100 pt6-ns pt4 ph center-ns" style={{maxWidth: 500, paddingBottom: 80}}>
        <div>
            <div className="tc mb3">
              <BigNumber superscriptDollarSign={true}>
                {MONEY.formattedDollars(monthlyTotal / 400, { wholeDollar: true })}
              </BigNumber>
              <div>per week</div>
            </div>

            <div className="bg-near-white ba b--light-gray br4 pa3 f7 lh-copy mb4 flex flex-row items-center justify-start" onClick={() => setEditingDepositFrequency(true)}>
              <img src={infoIcon} style={{width: 16}} />
              {depositTotal > 0 && <div className="mh3">
                {MONEY.formattedDollars(depositTotal / 100, { wholeDollar: true })} will be deposited {_.lowerCase(account.depositFrequency)}.
                <span> Tap to edit deposit schedule.</span>
              </div>}
              {depositTotal === 0 && <div className="mh3">
                You're set to {_.lowerCase(account.depositFrequency)} deposits.
                <span> Tap to edit deposit schedule.</span>
              </div>}
              <img className="ml2" src={editIcon} style={{width: 20}} />
            </div>

            {activeGroups.length > 0 && <div className="mb4">
              <div className="mb3 flex items-center justify-between">
                <div className="f3 bold">Active</div>
                <TinyButton onClick={pauseAll}>
                  <div className="flex items-center justify-center">
                    <img src={pauseIcon} style={{width: 18}} />
                  </div>
                </TinyButton>
              </div>
              {activeGroups.map((group, idx) => <div key={`group-${group.id}`}>
                <div className={`flex flex-row items-start justify-between pointer ba br4 b--light-gray ph3`} style={{paddingBottom: 24, paddingTop: 24, marginTop: idx > 0 ? -1 : 0}} onClick={() => setEditingGroup(group)}>
                  <div className="mr3">
                    <div className="f5 bold">{group.name}</div>
                    <div className="f6 mt2">
                      {(MONEY.formattedDollars(group.membership.monthlyContribution / 400, { wholeDollar: true }))}/week
                    </div>
                  </div>
                  <div className="flex flex-row items-center" style={{flexShrink: 0}}>
                    <TinyButton outline>
                      manage
                    </TinyButton>
                  </div>
                </div>
              </div>)}
            </div>}

            {pausedGroups.length > 0 && <div className="mb4">
              <div className="mb3 flex items-center justify-between">
                <div className="f3 bold">Paused</div>
                <TinyButton onClick={resumeAll}>
                  <div className="flex items-center justify-center">
                    <img src={playIcon} style={{width: 18}} />
                  </div>
                </TinyButton>
              </div>
              {pausedGroups.map((group, idx) => <div key={`group-${group.id}`}>
                <div className={`flex flex-row items-start justify-between pointer ba br4 b--light-gray ph3`} style={{paddingBottom: 24, paddingTop: 24, marginTop: idx > 0 ? -1 : 0}} onClick={() => setEditingGroup(group)}>
                  <div className="mr3">
                    <div className="f5 bold">{group.name}</div>
                    <div className="f6 mt2">
                      {(MONEY.formattedDollars(group.membership.monthlyContribution / 400, { wholeDollar: true }))}/week
                    </div>
                  </div>
                  <div className="flex flex-row items-center" style={{flexShrink: 0}}>
                    <TinyButton outline>
                      manage
                    </TinyButton>
                  </div>
                </div>
              </div>)}
            </div>}
        </div>
    </div>
    {Boolean(editingDepositFrequency) && <BottomCard dim onDismiss={() => setEditingDepositFrequency(false)}>
      <div className={`tc center-ns ${isSending ? 'o-50': ''}`} style={{maxWidth: 500}}>
        <div className="f5 bold mt2">Deposit Schedule</div>
        <div className="tl mv pb3 bb b--light-gray flex flex-row items-center justify-start" onClick={() => updateDepositFrequency('monthly')}>
          <div className="ba b--black pa2 br-100 flex items-center justify-center mr3" style={{width: 14, height: 14}}>
            {account.depositFrequency === 'monthly' && <div className="bg-black br-100" style={{width: 12, height: 12, flexShrink: 0}}></div>}
          </div>
          <div>
            <div className="f5 bold">Monthly</div>
            <div className="f6 mid-gray lh-copy">{MONEY.formattedDollars(monthlyTotal/100, { wholeDollar: true })} every 4 weeks, on Tuesday.</div>
          </div>
        </div>
        <div className="tl mv pb3 bb b--light-gray flex flex-row items-center justify-start" onClick={() => updateDepositFrequency('twice_a_month')}>
          <div className="ba b--black pa2 br-100 flex items-center justify-center mr3" style={{width: 14, height: 14}}>
            {account.depositFrequency === 'twice_a_month' && <div className="bg-black br-100" style={{width: 12, height: 12, flexShrink: 0}}></div>}
          </div>
          <div>
            <div className="f5 bold">Twice a month</div>
            <div className="f6 mid-gray lh-copy">{MONEY.formattedDollars(monthlyTotal/200, { wholeDollar: true })} every 2 weeks, on Tuesday.</div>
          </div>
        </div>
        <div className="tl mv pb3 bb b--light-gray flex flex-row items-center justify-start" onClick={() => updateDepositFrequency('weekly')}>
          <div className="ba b--black pa2 br-100 flex items-center justify-center mr3" style={{width: 14, height: 14}}>
            {account.depositFrequency === 'weekly' && <div className="bg-black br-100" style={{width: 12, height: 12, flexShrink: 0}}></div>}
          </div>
          <div>
            <div className="f5 bold">Weekly</div>
            <div className="f6 mid-gray lh-copy">{MONEY.formattedDollars(monthlyTotal/400, { wholeDollar: true })} weekly, on Tuesday.</div>
          </div>
        </div>
        <div>
          <Button width="w-100" onClick={() => setEditingDepositFrequency(false)}>done</Button>
        </div>
      </div>
    </BottomCard>}
    {Boolean(editingGroup) && <BottomCard dim onDismiss={onDismiss}>
      <EditGroup
        client={client}
        group={editingGroup} 
        groupCompanies={groupCompanies[editingGroup.id]}
        onCancel={() => setEditingGroup(null)}
        onDismiss={onDismiss}
        onUpdate={onUpdate}
      />
    </BottomCard>}
    {Boolean(isPausing) && <BottomCard dim onDismiss={() => setIsPausing(false)}>
      <PauseSurvey isDisabled={isSending} onSubmitPause={pauseAll} />
    </BottomCard>}
  </div>;
}

export function EditGroup({ client, group, onDismiss, onUpdate }) {
  const [isEditingContribution, setIsEditingContribution] = useState(false);
  const [isPausing, setIsPausing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  const onClickPause = (e) => {
    if (group.membership.isPaused) {
      return onSubmitPause(e);
    }

    setIsPausing(true);
  }

  const onSubmitPause = async (e) => {
    try {
      setIsSending(true);
      const params = {
        id: group.id,
        isPaused: !group.membership.isPaused,
      }

      if (params.isPaused) {
        const form = new FormData(e.target);
        params.pauseReason = form.get('pauseReason');
      }

      const membership = await client.groups.updateMembership(params);

      onUpdate({
        ...group,
        membership,
      });
      onDismiss();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSending(false);
    }
  }

  if (isEditingContribution) {
    return <UpdateGroupContribution
      client={client}
      group={group}
      onCancel={() => setIsEditingContribution(false)}
      onDismiss={onDismiss}
      onUpdate={onUpdate}
    />;
  }

  if (isPausing) {
    return <PauseSurvey isDisabled={isSending} onSubmitPause={onSubmitPause} />;
  }

  return <div className="tc center-ns" style={{maxWidth: 500}}>
    <div className="f5 bold mt2">{group.name}</div>
    <div className="mv3">
      <BigNumber>
        {MONEY.formattedDollars(group.membership.monthlyContribution / 400, { wholeDollar: true })}
      </BigNumber>
      <div>per week</div>
     </div>

    <div className="mv3 flex flex-row items-center justify-center">
        <TinyButton width="w-50" onClick={() => setIsEditingContribution(true)} style={{marginRight: -2}}>Edit Amount</TinyButton>
        <TinyButton width="w-50" onClick={onClickPause}>{group.membership.isPaused ? 'Resume' : 'Pause'}</TinyButton>
    </div>
  </div>
}

function UpdateGroupContribution({ client, group, groupCompanies, onDismiss, onUpdate }) {
  const [amount, setAmount] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  const onConfirm = async () => {
    const value = amount ? amount * 400 : group.membership.monthlyContribution;
    if (value === group.membership.monthlyContribution) {
      onDismiss();
    } else {
      try {
        setIsSending(true);
        const membership = await client.groups.updateMembership({
          id: group.id,
          monthlyContribution: value,
        });
        onUpdate({
          ...group,
          membership,
        });
        onDismiss();
      } catch (e) {
        setError(e.message);
      } finally {
        setIsSending(false);
      }
    }
  }

  const displayAmount = (amount) => {
    const t = MONEY.formattedDollars(amount, { wholeDollar: true });
    return <><sup style={{fontSize: '60%', top: '-0.75em' }}>$</sup>{t.slice(1)}</>
  }

  const canConfirm = (() => {
    if (amount === '') return true;
    if (amount * 400 < group.minimum) return false;
    return true;
  })();

  const isUnderFunded = (() => {
    if (amount) return amount >= 5 && amount < groupCompanies?.length;
    return group.membership.monthlyContribution / 400 < groupCompanies?.length;
  })();

  return <div className="tc center-ns" style={{maxWidth: 500}}>
    <div className="f5 bold mt2">{group.name}</div>
    <div className="mv3">
      <BigNumber>
        {displayAmount(amount ? amount : group.membership.monthlyContribution / 400)}
      </BigNumber>
      <div>per week</div>
    </div>
    {groupCompanies?.length > 5 && <div className={`bg-${isUnderFunded ? 'yellow' : 'light-gray'} br4 pv2 ph3 f6 lh-copy flex flex-row items-center justify-start mb3`}>
      <img src={warningIcon} className="mr3" style={{width: 18}} />
      <div className="tl">Must be at least {MONEY.formattedDollars(groupCompanies.length, { wholeDollar: true })} to invest in all {groupCompanies.length} stocks every week.</div>
    </div>}
    <NumberPad allowDecimal={false} amount={amount} onChange={setAmount} max={10000} />
    <div className="mt3">
      {Boolean(error) && <div className="f7 mb2 red">{error}</div>}
      {!error && <div className="f7 mb2 mid-gray">Minimum investment is {MONEY.formattedDollars(group.minimum / 400)} per week.</div>}
      <Button width="w-100" isDisabled={isSending || !canConfirm} onClick={onConfirm}>done</Button> 
    </div>
  </div>;
}

function PauseSurvey({ isDisabled, onSubmitPause }) {
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmitPause(e);
  }

  return <div className="tc center-ns" style={{maxWidth: 500}}>
    <div className="f5 bold mt2">Why are you pausing?</div>
    <div className="f6 mv2">
      Your answer helps improve Share.
    </div>

    <form onSubmit={onSubmit}>
      <div className="db mv3">
        <div className="pv2">
          <Radio name="pauseReason" required value="Expenses" label="Expenses" />
        </div>

        <div className="pv2">
          <Radio name="pauseReason" required value="Performance" label="Performance" />
        </div>

        <div className="pv2">
          <Radio name="pauseReason" required value="Moving to another strategy" label="Moving to another strategy" />
        </div>

        <div className="pv2">
          <Radio name="pauseReason" required value="Safety / security concerns" label="Safety / security concerns" />
        </div>

        <div className="pv2">
          <Radio name="pauseReason" required value="Share isn't for me" label="Share isn't for me" />
        </div>

        <div className="pv2">
          <Radio name="pauseReason" required value="Other" label="Other" />
        </div>
      </div>

      <TinyButton width="w-100" isDisabled={isDisabled}>
        Confirm Pause
      </TinyButton>
    </form>
  </div>
}