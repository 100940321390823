import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import EnableNotifications from '../partials/enable-notifications';
import Loading from '../partials/loading';
import { Button, H2 } from '../partials/ui'
import Contact from './contact';
import Disclosure from './disclosure';
import Employment from './employment';
import Identity from './identity';
import Income from './income';
import Phone from './phone';
import Review from './review';
import SSN from './ssn';
import Terms from './terms';
import checkIcon from '../assets/img/check-green.svg';

export default function AccountOnboarding({ client, isMobile }) {
  const history = useHistory();
  const { section } = useParams();
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [hasConfirmed, setHasConfirmed] = useState(window.localStorage.getItem('hasConfirmed') === 'true');
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showEnableNotifications, setShowEnableNotifications] = useState(false);

  const sections = [
    { name: 'Contact', completed: Boolean(account?.contact), time: 30, slug: 'contact' },
    { name: 'Identity', completed: Boolean(account?.identity), time: 30, slug: 'identity' },
    { name: 'Disclosure', completed: Boolean(account?.disclosure), time: 15, slug: 'disclosure' },
    { name: 'Employment', completed: Boolean(account?.employment), time: 30, slug: 'employment' },
    { name: 'Income', completed: Boolean(account?.income), time: 15, slug: 'income' },
    { name: 'Social Security Number', completed: Boolean(account?.ssn), time: 0, slug: 'ssn' },
    { name: 'Customer Agreement', completed: hasConfirmed, time: 0, slug: 'terms' },
    { name: 'Review', completed: false, time: 0, slug: 'review' },
  ];

  if (user && !user.phoneNumber) {
    sections.unshift({ name: 'Phone Number', completed: false, time: 15, slug: 'phone' });
  }

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        setError("unable to retrieve account");
      }
    })();

    if (process.env.ANALYTICS === '1') {
      analytics.track('New Brokerage Account Started');
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
      try {
        setAccount(await client.accounts.getKyc());
      } catch (e){ console.log(e); }
    })();
  }, [user]);

  const onCreate = (entity) => {
    if (section === 'phone') {
      setUser(entity);
    } else {
      setAccount({ ...account, ...entity });
    }

    if (process.env.ANALYTICS === '1') {
      try {
        analytics.track(`Brokerage Account Step ${_.startCase(Object.keys(entity)[0])}`);
      } catch (e) {
        console.error(e);
      }
    }

    const idx = sections.findIndex((s) => s.slug === section);
    const nextIdx = sections.findIndex((s, i) => i > idx && !s.completed);
    if (nextIdx === -1) return;
    history.push(`/account/onboarding/${sections[nextIdx].slug}`);
  };

  const createBrokerageAccount = async (e) => {
    e.preventDefault();
    if (isDisabled) return;
    setIsDisabled(true);
    try {
      const brokerageAccount = await client.brokerageAccounts.create({ ssn: account.ssn });
      setError(null);
      onCreate({ brokerageAccount });
      window.localStorage.removeItem('hasConfirmed');
      if (process.env.ANALYTICS === '1') {
        analytics.track('New Brokerage Account Completed');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  const onConfirm = () => {
    setHasConfirmed(true);
    window.localStorage.setItem('hasConfirmed', true);
    const idx = sections.findIndex((s) => s.slug === section);
    const nextIdx = sections.findIndex((s, i) => i > idx && !s.completed);
    history.push(`/account/onboarding/${sections[nextIdx].slug}`);
  }

  const onFinish = () => {
    if (isMobile && !showEnableNotifications) {
      setShowEnableNotifications(true);
      return;
    }

    history.push('/?isNew=true');
  }

  if (showEnableNotifications) {
    return <EnableNotifications client={client} onDismiss={onFinish} msg="Account status changes, your weekly investments, dividends, and other important information." />;
  }

  if (!account) return <Loading />;

  if (account.brokerageAccount) {
    //they've already completed the process, show the finish page
    return <div className="mv5">
      <div className="w-100 center" style={{maxWidth: 500}}>
        <div className="w-100 ph">
          <div className="tc mb5">
            <div className="f1">{String.fromCodePoint(0x1F3CE)}</div>
            <H2>Wheels are in motion.</H2>
            <div className="mt3 lh-copy">Most applications are approved within 5 minutes. Sometimes we need more information. If so we'll email you.</div>
            <div className="mt3 lh-copy">If you don't hear from us, check your spam folder or email hi@tryshare.app.</div>
          </div>

          <div className="fixed bottom-0 left-0 right-0 w-100 pa3 center" style={{maxWidth: 500}}>
            <Button width="w-100" onClick={onFinish}>ok got it</Button>
            {error && <div className="f7 mt1 dark-red">{error}</div>}
          </div>
        </div>
      </div>
    </div>;
  }

  //no section means render the start page that shows their progress
  if (!section) {
    const timeRemaining = sections.reduce((acc, step) => {
      if (!step.completed) {
        return acc + step.time;
      }
      return acc;
    }, 0);

    const prettyTimeRemaining = timeRemaining >= 60 ? `${Math.round(timeRemaining / 60)} minutes` : `${timeRemaining} seconds`;

    const nextSection = sections.findIndex((s) => !s.completed);

    const onStart = () => {
      history.push(`/account/onboarding/${sections[nextSection].slug}`);
    }

    return (
      <div className="w-100 center mw6 pt5 pa">
        <div>
          <H2>Account setup {String.fromCodePoint(0x1FAAA)}</H2>
          <div>To comply with financial regulations, we have to collect some additional information. Your progress will be saved as you go.</div>
          <div className="mt2 bold purple">Estimated time: {prettyTimeRemaining}</div>
        </div>
        <div className="mt4">
          {sections.filter(s => s.time > 0).map((step, idx) =>
            <div key={step.name} className={`pv3 bb b--light-gray flex flex-row items-center justify-between`}>
              <div className="flex items-center justify-start">
                <div className="mr3 f3 bold">{idx + 1}</div>
                <div>
                  <div className="f5">{step.name}</div>
                  <div className="f6 mt1 gray">
                    {step.completed ? 'Completed' : `Not started`}
                  </div>
                </div>
              </div>
              {step.completed && <img src={checkIcon} />}
            </div>
          )}
        </div>
        <div className="fixed bottom-0 left-0 right-0 pa3 mw6 center">
          <Button width="w-100" onClick={onStart}>{nextSection > 0 ? 'Continue' : 'Get Started'}</Button>
        </div>
      </div>
    );
  }

  switch (section) {
    case 'phone':
      return <Phone client={client} onPhoneNumberCreate={onCreate} />;
    case 'contact':
      return <Contact client={client} onContactCreate={onCreate} />;
    case 'identity':
      return <Identity client={client} onIdentityCreate={onCreate} />;
    case 'disclosure':
      return <Disclosure client={client} onDisclosureCreate={onCreate} />;
    case 'employment':
      return <Employment client={client} onEmploymentCreate={onCreate} />;
    case 'income':
      return <Income client={client} onIncomeCreate={onCreate} />;
    case 'ssn':
      return <SSN client={client} onSsnCreate={onCreate} />;
    case 'terms':
      return <Terms client={client} onAcceptTerms={onConfirm} />;
    case 'review':
      return <Review
      client={client}
      contact={account.contact}
      identity={account.identity}
      disclosure={account.disclosure}
      employment={account.employment}
      income={account.income}
      onCreateBrokerageAccount={createBrokerageAccount}
      ssn={account.ssn}
      error={error}
      />;
    default:
      return <Redirect to="/account/onboarding" />;
  }
}