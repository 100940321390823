import React, { useEffect } from 'react';
import css from './modal.css';
import xIcon from '../../assets/img/x.svg';

export default function Modal({ children, onDismiss, fullscreen, fade = true, position = 'center', width = 'w-auto' }) {
  useEffect(() => {
    const { scrollX: x, scrollY: y } = window;
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);

    return () => {
      document.body.style.overflow = 'unset';
      window.scrollTo(x, y);
    }
  }, []);

  return <div className={`absolute absolute--fill z-999 flex items-${position} justify-center pa4 ${fullscreen && fade ? 'fade-in' : ''}`} style={{backgroundColor: fullscreen ? 'rgba(255,255,255,0.98)' : 'rgba(0,0,0,0.5)'}}>
    {fullscreen && <div className="absolute top-2 right-2 pointer" onClick={onDismiss}>
      <img src={xIcon} style={{width: 20}} />
    </div>}
    <div className={`center br5 w-50 overflow-scroll ${width}`} style={{maxHeight: '100%', backgroundColor: fullscreen ? '' : 'white'}}>
      {children}
    </div>
  </div>
}