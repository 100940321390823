import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Loading from './partials/loading';
import { MONEY, QUERY } from '../utils';
import { BigNumber, Button, RatingBadge } from './partials/ui';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import chevronRight from './assets/img/chevron-right.svg';
import infoIcon from './assets/img/info.svg';

export default function GroupSell({ client }) {
  const { groupSellUserId } = useParams();
  const { returnTo = '/' } = QUERY.toObject(useLocation().search);
  const [groupSell, setGroupSell] = useState(null);

  useEffect(() => {
    (async () => {
      setGroupSell(await client.groupSells.getOne({ groupSellUserId }));
    })();
  }, [groupSellUserId]);

  if (!groupSell) return <Loading />;

  return <div>
    <Nav client={client} leftButton={BackButton} title={groupSell.group.name} footer={false} />
    <div className="center w-100 pt6-ns pt3" style={{maxWidth: 500, paddingLeft: 24, paddingRight: 24}}>
      <div style={{paddingBottom: 120}}>
        <div className="tc mt2 mb4">
          <BigNumber>{MONEY.formattedDollars(groupSell.amount)}</BigNumber>
        </div>
        {groupSell.status !== 'orders_filled' && <div className="flex flex-row items-center justify-start bg-near-white f6 lh-copy br4 pa3 mb3">
          <img src={infoIcon} className="mr2" style={{width: 18}} />
          <div>
            {groupSell.status === 'pending_orders' && `Orders are pending and will be sent when the market opens.`}
            {groupSell.status === 'orders_pending' && `Orders have been sent to the market and are waiting to be filled.`}
            {groupSell.status === 'orders_failed' && `Something went wrong. Please contact support@tryshare.app.`}
          </div>
        </div>}
        {groupSell.orders.map((order, idx) => 
          <Link key={`order-${order.id}`} to={`/history/orders/${order.id}`} className="black no-underline">
            <div className={`ph3 ba br5 b--light-gray flex flex-row items-center justify-between`} style={{paddingTop: 24, paddingBottom: 24, marginTop: idx > 0 ? -1 : 0}}>
                <div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="f5 bold">
                      {order.ticker}
                    </div>
                    <RatingBadge type={order.side} />
                  </div>
                  <div className="f6 mt2 silver flex flex-row items-center justify-start">
                    {!order.status && <>
                      <div>Pending</div>
                    </>}
                    {Boolean(order.status) && <>
                      <div>{moment.unix(order.submittedAt || order.createdAt).format('MMM D, YYYY')}</div>
                      <div className="ph1">{String.fromCharCode(183)}</div>
                      <div>{_.startCase(order.status)}</div>
                    </>}
                  </div>
                </div>
                <div className="flex flex-row items-center f4 mono">
                  {Boolean(order.notional) && MONEY.formattedDollars(order.notional/100)}
                  {!Boolean(order.notional) && Boolean(order.filledQuantity) && MONEY.formattedDollars(parseFloat(order.filledAveragePrice) * parseFloat(order.filledQuantity))}
                  <img className="ml2" src={chevronRight} style={{width: 15, height: 15}} />
                </div>
            </div>
            </Link>)}
      </div>
    </div>
    <div className="fixed dn-ns w-100 center bg-white" style={{bottom: 0, padding: 24}}>
      <Link to={returnTo}>
        <Button width="w-100">Done</Button>
      </Link>
    </div>
  </div>;
}
