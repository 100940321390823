import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from './partials/loading';
import { Button, H2 } from './partials/ui';
import rocket from './assets/img/rocket.svg';

export default function Onboarding({ client }) {
  const { groupId } = useParams();
  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  
  useEffect(() => {
    (async () => {
      setUser(await client.users.getMe());
      try {
        const group = await client.groups.getOne({ id: groupId });
        setGroup(group);
      } catch (e) {
        window.location.href = '/groups';
      }
    })();
  }, []);

  if (!user || !group) return <Loading />;

  return <div className="w-100 min-vh-100">
    <div className="relative center tc mt5 w-100-ns w-90" style={{maxWidth: 700, paddingBottom: 80}}>
      <div className="w-100 tc f6">
        <div>Congrats on joining</div>
        <div className="bold mt1">{group.name}</div>
      </div>
      <div className="tc mv4">
        <img className="center" src={rocket} />
      </div>
      <H2>What's next?</H2>
      <div className="mv4">
        <div className="mt4 pa3 br3 bg-near-white tl">
          <div className="f6 lh-title">Suggest</div>
          <div className="mt2 f6 lh-copy w-two-thirds-ns w-100">
            Got some ideas? <Link className="blue" to="/search">Explore companies</Link> and click the Suggest button to add them to the next batch of stocks for voting.
          </div>
        </div>
        <div className="mt4 pa3 br3 bg-near-white tl">
          <div className="f6 lh-title">Discuss</div>
          <div className="mt2 f6 lh-copy w-two-thirds-ns w-100">
            Talk about the different companies proposed and share ideas to figure out which companies have the best outlook.
          </div>
        </div>
        <div className="mt4">
          <Link to="/">
            <Button width="w-100">Continue</Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
}