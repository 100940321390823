export const ONE_HOUR = 60* 60;
export const ONE_DAY = 60 * 60 * 24;
export const ONE_YEAR = 60 * 60 * 24 * 365;

export function set(key, value, maxAge) {
  let cookie = `${key}=${value} ;  path=/`;
  if (maxAge) {
    cookie = `${cookie} ; max-age=${maxAge}`;
  }

  document.cookie = cookie;
}

export function get(key) {
  let value = undefined;
  document.cookie.split(';').forEach((item) => {
    if (item.includes(`${key}=`)) {
      const [k, v] = item.split('=');
      value = v.trim();
    }
  });

  return value;
}

export function remove(key) {
  document.cookie = `${key}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}

const appVersion = get('appVersion');
if (appVersion) {
  set('app_version', appVersion);
}