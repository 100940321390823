import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, H2, Input } from '../partials/ui';
import Nav from '../partials/nav';
import { PHONE } from '../../utils';
import arrowLeft from '../assets/img/arrow-left.svg';

export default function Phone({ client, onPhoneNumberCreate }) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [code, setCode] = useState('');
  const [codeValid, setCodeValid] = useState(false);
  const [phoneNumberToken, setPhoneNumberToken] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      const user = await client.users.getMe();
      if (user.phoneNumber) {
        onPhoneNumberCreate(user);
      } else {
        setUser(user);
      }
    })();
  }, []);

  useEffect(() => {
    if (!codeValid) return;
    (async () => {
      try {
        const { token } = await client.phones.verifyResponse({ phoneNumber, code });
        const nextUser = await client.users.update(user.id, { phoneNumberToken: token });
        setError(null);
        onPhoneNumberCreate(nextUser);
      } catch (e) {
        setError(e.message);
      }
    })();
  }, [codeValid]);

  const validatePhoneNumber = (e) => {
    const { target: { value } } = e;
    const { isValid } = PHONE.validatePhoneNumber(value);
    setPhoneNumberValid(isValid);
  };

  const validateCode = (e) => {
    const { target: { value } } = e;
    const isValid = value.length === 6;
    if (isValid !== codeValid) {
      if (isValid) setCode(value);
      setCodeValid(isValid);
    }
  };

  const onSubmitPhoneNumber = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const phoneNumber = PHONE.getDigits(form.get('phoneNumber'));
    await client.phones.verifyRequest({ phoneNumber });
    setPhoneNumber(phoneNumber);
  };

  const clearPhoneNumber = () => {
    setPhoneNumber(null);
    setPhoneNumberValid(false);
  };

  const resendCode = () => {
    client.phones.verifyRequest({ phoneNumber }); 
  };

  const title = (() => {
    if (!phoneNumber) return 'What is your mobile number?';
    return `Enter the code sent to ${PHONE.formatPhoneNumber(phoneNumber)}.`;
  })();

  const subtitle = (() => {
    if (!phoneNumberToken) return 'Phone number verification protects your account.';
    return '';
  })();

  const BackButton = () => {
    const onClick = () => {
      if (phoneNumberToken) {
        setPhoneNumberToken(null);
        clearPhoneNumber();
      } else if (phoneNumber) {
        clearPhoneNumber();
      } else {
        history.goBack();
      }
    };
  
    return <img src={arrowLeft} className="pointer" onClick={onClick} />;
  };

  return <div className="min-vh-100 w-100">
    <Nav client={client} leftButton={BackButton} footer={false} />
    <div className="w-100 center h-100 pt6-ns" style={{maxWidth: 500}}>
      <div style={{padding: 24}}>
        <H2>{title}</H2>
        {subtitle && <div className="near-black">{subtitle}</div>}
      </div>
      <div className="bg-white" style={{padding: 24, paddingTop: 0}}>
        <form onSubmit={onSubmitPhoneNumber}>
          {!phoneNumberToken && <>
            {!phoneNumber && <>
              <Input
                type="tel"
                name="phoneNumber"
                label=""
                placeholder="(201) 555-0123"
                required={true}
                onChange={validatePhoneNumber}
                autoFocus={true}
                />
              <div className={`bg-white w-100 fixed relative-ns pa0-ns pa center`} style={{bottom: 0, left: 0, paddingTop: 0}}>
                {error && <div className="w-100 f7 mb1 tr dark-red">{error}</div>}
                {!error && <div className="mb2 f7 gray w-100 tc">US numbers only. Carrier rates may apply.</div>}
                <Button isDisabled={!phoneNumberValid} width="w-100">Continue</Button>
              </div>
            </>}
            {phoneNumber && <>
              {error && <div className="w-100 f7 mb1 tr dark-red">{error}</div>}
              <Input
                type="tel"
                name="code"
                label=""
                placeholder="Enter your code"
                required={true}
                autoFocus={true}
                onChange={validateCode}
                />
              <div className="mt4">
                <BlueLink onClick={resendCode}>Resend code</BlueLink>
              </div>
            </>}
          </>}
          </form>
      </div>
    </div>
  </div>;
}

function BlueLink({ children, onClick }) {
  return <div className="mt3 f5 bold blue no-underline pointer w-100 tc" onClick={onClick}>{children}</div>
}