import React from 'react';

//vote button icons
import iconHellYes from '../assets/img/batch/hell-yes.svg';
import iconYes from '../assets/img/batch/yes.svg';
import iconNotSure from '../assets/img/batch/not-sure.svg';
import iconNo from '../assets/img/batch/no.svg';
import iconHellNo from '../assets/img/batch/hell-no.svg';
import iconHellYesSelected from '../assets/img/batch/hell-yes-selected.svg';
import iconYesSelected from '../assets/img/batch/yes-selected.svg';
import iconNotSureSelected from '../assets/img/batch/not-sure-selected.svg';
import iconNoSelected from '../assets/img/batch/no-selected.svg';
import iconHellNoSelected from '../assets/img/batch/hell-no-selected.svg';

export default function VoteButton({ isDisabled, isSell, onVote, onRevote, value, vote, ...rest }) {
  const voteValue = vote ? vote.vote : null;
  const onClick = async (e) => {
    e.target.blur();
    if (voteValue === value) {
      onRevote({
        value,
        ...rest
      });
    } else {
      await onVote({
        value,
        ...rest,
      });
    }
  };

  return <div className="flex flex-column items-center justify-start">
    {isSell && <SellButton onClick={onClick} value={value} vote={vote} isDisabled={isDisabled} isHighlighted={voteValue === value} />}
    {!isSell && <BuyButton onClick={onClick} value={value} vote={vote} isDisabled={isDisabled} isHighlighted={voteValue === value} />}
  </div>;
}

function SellButton({ isDisabled, isHighlighted, onClick, value }) {
  return <button
  className={`f7 black pa3 ba br-100 flex flex-column items-center ttl justify-center pointer ${isHighlighted ? 'bg-lightest-blue b--light-blue blue' : 'shadow-4 bg-white b--white white'}`}
  style={{width: 64, height: 64}}
  disabled={isDisabled === true}
  onClick={onClick}
  >{isHighlighted ? activeIcons[value] : icons[value]}
  {isHighlighted && <>{text[value]}</>}
  </button>;
}

function BuyButton({ isDisabled, isHighlighted, onClick, value, vote }) {
  return <button
  className={`f7 black pa3 ba br-100 flex flex-column items-center ttl justify-center pointer ${isHighlighted ? 'bg-lightest-blue b--light-blue blue' : 'shadow-4 bg-white b--white white'}`}
  style={{width: 64, height: 64}}
  disabled={isDisabled === true}
  onClick={onClick}
  >{isHighlighted ? activeIcons[value] : icons[value]}
  {isHighlighted && <>{text[value]}</>}
  </button>;
}

const text = {
  '-2': 'Hell No',
  '-1': 'No',
  '0': 'Not Sure',
  '1': 'Yes',
  '2': 'Hell Yes',
};

const icons = {
  '-2': <img src={iconHellNo} />,
  '-1': <img src={iconNo} />,
  '0': <img src={iconNotSure} />,
  '1':  <img src={iconYes} />,
  '2':  <img src={iconHellYes} />,
};

const activeIcons = {
  '-2': <img src={iconHellNoSelected} />,
  '-1': <img src={iconNoSelected} />,
  '0': <img src={iconNotSureSelected} />,
  '1':  <img src={iconYesSelected} />,
  '2':  <img src={iconHellYesSelected} />,
} 