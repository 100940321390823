import React from 'react';
import _ from 'lodash';

export default function BigNumber({ color = 'black', children, superscriptDollarSign }) {
  let toRender = children;
  if (superscriptDollarSign && _.isString(children)) {
    toRender = <><sup style={{fontSize: '60%', top: '-0.75em' }}>$</sup>{children.slice(1)}</>;
  }

  return <div className={`bold ${color}`} style={{fontSize: 48}}>{toRender}</div>
}