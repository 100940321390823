const filingTypeMap = {
  '10-K': 'Annual Report',
  '10-Q': 'Quarterly Report',
  'S-1': 'IPO Prospectus',
  '10-K/A': 'Annual Report Amendment',
  '10-Q/A': 'Quarterly Report Amendment',
  'S-1/A': 'IPO Prospectus Amendment',
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const longFractionFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 8,
});

const wholeDollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export function prettyFilingType(filingType) {
  return filingTypeMap[filingType.toUpperCase()] || filingType;
}

export function formattedMarketCap(marketCap) {
  if (marketCap > Math.pow(10, 12)) {
    return `$${(marketCap / Math.pow(10, 12)).toFixed(2)}T`;
  }
  if (marketCap > Math.pow(10, 9)) {
    return `$${(marketCap / Math.pow(10, 9)).toFixed(2)}B`;
  }
  if (marketCap > Math.pow(10, 6)) {
    return `$${(marketCap / Math.pow(10, 6)).toFixed(2)}M`;
  }
  if (marketCap > Math.pow(10, 3)) {
    return `$${(marketCap / Math.pow(10, 6)).toFixed(2)}K`;
  }
  //this should never happen
  return `${marketCap}`;
}

export function formattedNumShares(numShares) {
  if (numShares % 1 === 0) {
    return `${numShares}`;
  }
  return numShares.toFixed(5).toString();
}

export function formattedDollars(amt, options = {}) {
  const {
    wholeDollar = false,
  } = options;

  if (Number.isNaN(amt)) amt = 0;
  let text;
  if (wholeDollar) {
    text = wholeDollarFormatter.format(amt);
  } else if (Math.abs(amt) < 0.001) {
    // some coins trade in the fractions of cents, which requires displaying many decimal places.
    text = longFractionFormatter.format(amt);
  } else {
    text = formatter.format(amt);
  }
  
  return text;
}

export function formattedRatio(number, places = 1) {
  return number.toFixed(places).toString();
}