import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import BackButton from '../partials/button-back';
import Nav from '../partials/nav';
import Loading from '../partials/loading';
import { Button, Checkbox, H2 } from '../partials/ui';

export default function AccountTerms({ client, defaultChecked, onAcceptTerms }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    onAcceptTerms();
  };

  if (!user) return <Loading />;
  
  return <div className="w-100 min-vh-900">
    <Nav client={client} leftButton={BackButton} title="Customer Agreement" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>
      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb5">
          <H2>Terms &amp; conditions</H2>
        </div>

        <Checkbox
        name="customerAgreement"
        required={true}
        defaultChecked={defaultChecked === true}
        label={<span className="f5 lh-copy">I have read, understood, and agree to be bound by the terms of the <a href="https://docs.google.com/document/d/1XBAWCPzOqHf3u5D-bY5sonKdarshVhym6-Peh5OKI7w/edit?usp=sharing" target="_blank">Share Advisory Agreement</a></span>}
        />

        <Checkbox
        name="agreement"
        required={true}
        defaultChecked={defaultChecked === true}
        label={<span className="f5 lh-copy">I have read, understood, and agree to be bound by <a href="https://alpaca.markets/disclosures" target="_blank">Alpaca Securities LLC</a> terms, disclosures and disclaimers applicable to me, as referenced in the <a href="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf" target="_blank">Application Agreement and Customer Agreement</a> and, if I trade crypto assets, the <a href="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf" target="_blank">Crypto Agreement</a>.</span>}
        />

        <Checkbox
        name="digitalSignature"
        required={true}
        defaultChecked={defaultChecked === true}
        label={<span className="f5 lh-copy">I understand I am signing this agreement electronically and that my electronic signature is the legal equivalent of my manual signature on these Agreements.</span>}
        />

        <div className="mt4">
          <Button  width="w-100" >Finish</Button>
        </div>
      </form>
  </div>
</div>;
}