import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export default function SegmentController({ contents, defaultSegment = 0, isDisabled, onChange, segment, segments, style = 'default' }) {
  const [selectedSegment, setSelectedSegment] = useState(segment ? segment : segments[defaultSegment]);
  const width = (() => {
    if (segments.length === 3) return `w-third`;
    return `w-${100/segments.length}`;
  })();

  useEffect(() => {
    //override from outside
    if (segment) {
      const idx = segments.findIndex(s => s === segment);
      if (idx >= 0) {
        setSelectedSegment(segment);
        //don't fire onchange if it came from outside, to avoid state loop
      }
    }
  }, [segment]);

  const _onChange = (segment) => {
    if (isDisabled) return;
    setSelectedSegment(segment);
    if (onChange) {
      onChange(segment.value || segment);
    }
  };

  const isMinimal = style === 'minimal';
  const segmentStyle = (segment) => {
    if (_.isEqual(selectedSegment, segment)) {
      return isMinimal ? 'ph3 pv2 bb bw2 b--black bold' : 'bg-white ph3 pv2 bold br3 shadow-4';
    }
    return isMinimal ? 'ph3 pv2 bb bw2 b--white' : 'gray';
  }

  return <>
    <div className={`${isMinimal ? '' : 'bg-light-gray'} ${isDisabled ? 'o-40' : ''} pa1 flex flex-row items-center justify-around`} style={{borderRadius: isMinimal ? 0 : 10}}>
      {segments.map((segment) => <React.Fragment key={segment.key || segment.value || segment}>
        {_.isPlainObject(segment) &&
          <div key={segment.value} id={segment.value} className={`${width} pointer tc ${segmentStyle(segment)}`} onClick={() => _onChange(segment)}>{segment.label}</div>}
        {_.isString(segment) &&
          <div key={segment} id={segment} className={`${width} pointer tc ${segmentStyle(segment)}`} onClick={() => _onChange(segment)}>{_.startCase(segment)}</div> }
        </React.Fragment>)}
    </div>
    {Boolean(contents) && contents[selectedSegment]}
  </>;
}
