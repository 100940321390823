import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nav from './partials/nav';
import { Button, Loader } from './partials/ui';

export default function UploadDocument({ client }) {
  const { type } = useParams();
  const [user, setUser] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    (async () => {
      setUser(await client.users.getMe());
    })();
  }, []);

  const handleFileInputChange = async (e) => {
    const types = {
      address: 'address_verification',
      dob: 'date_of_birth_verification',
      identity: 'identity_verification',
      tax: 'tax_id_verification',
    };

    try {
      const { target: { files } } = e;
      if (files.length === 0) {
        return;
      }
      setIsUploading(true);
      const file = files[0];
      await client.users.uploadDocument({
        type: types[type],
        subType: documentType,
        file,
        userId: user.id,
      });
      setIsDone(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  }

  return <div>
    <Nav client={client} title="Confirm Identity" />
    <div className="w-100 center pt6-ns pt3" style={{maxWidth: 700}}>
    <div className="w-100-ns w-90 tc center">
      {!isDone && <>
        <div className="f5 lh-copy mv4">In order to confirm your identity, please upload a photo of one of the documents below.</div>
        {!documentType && <div className="flex flex-column">
          {type === 'address' && <>
          <div className="mb3">
            <Button width={`w-100 w-auto-ns`} onClick={() => setDocumentType('drivers_license')}>Driver's License</Button>
          </div>
          <div>
            <Button width={`w-100 w-auto-ns`} onClick={() => setDocumentType('utility_bill')}>Utility Bill</Button>
          </div>
          </>}
          {['dob', 'identity'].includes(type) && <>
          <div className="mb3">
            <Button width={`w-100 w-auto-ns`} onClick={() => setDocumentType('passport')}>Passport</Button>
          </div>
          <div>
            <Button width={`w-100 w-auto-ns`} onClick={() => setDocumentType('drivers_license')}>Driver's License</Button>
          </div>
          </>}
          {type === 'tax' && <>
          <div className="mb3">
            <Button width={`w-100 w-auto-ns`} onClick={() => setDocumentType('social_security_number')}>Social Security Card</Button>
          </div>
          </>}
          {}
        </div>}

        {Boolean(documentType) && <div className="flex flex-column">
          {!isUploading && <>
            <input id="document" disabled={isUploading} className="mt2 mb3 center w-50" type="file" accept="image/*" name="document" onChange={handleFileInputChange} />
          </>}
          {isUploading && <div className="center tc">
            <div>Uploading, please wait</div>
            <Loader style={{width: 200, height: 200}} />
          </div>}
        </div>}
      </>}

      {isDone && <>
        <div className="f5 lh-copy mv4">Thank you, we're processing your document and will email you when it's complete.</div>
      </>}
    </div>
  </div>
</div>;
}