import moment from 'moment';

export function isPreLive(batch) {
  return moment().isBefore(moment.unix(batch.liveDate));
}

export function isLive(batch) {
  return moment.unix(batch.liveDate).isBefore() && moment.unix(batch.closeDate).isAfter();
}

export function isClosed(batch) {
  return moment.unix(batch.closeDate).isBefore();
}

export function isBought(batch) {
  return Boolean(batch.buyId);
}