import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../partials/loading';
import { Button, H2, Radio } from '../partials/ui';
import { QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back'

export default function AccountDisclosure({ client, onDisclosureCreate }) {
  const { returnTo } = QUERY.toObject(useLocation().search);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [disclosure, setDisclosure] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
      try {
        setDisclosure(await client.disclosures.get({ userId: user.id }))
      } catch {} finally {
        setIsLoading(false);
      }
    })();
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const form = new FormData(e.target);
    const isControlPerson = form.get('isControlPerson') === 'true';
    const isAffiliatedExchangeOrFinra = form.get('isAffiliatedExchangeOrFinra') === 'true';
    const isPoliticallyExposed = form.get('isPoliticallyExposed') === 'true';
    const isImmediateFamilyExposed = isPoliticallyExposed;
     
    const data = {
      userId: user.id,
      isControlPerson,
      isAffiliatedExchangeOrFinra,
      isPoliticallyExposed,
      isImmediateFamilyExposed,
    };

    try {
      const disclosure = await client.disclosures.update(data);
      if (onDisclosureCreate) {
        onDisclosureCreate({ disclosure });
      } else {
        history.push(`/account/onboarding${returnTo ? '?section=' + returnTo : ''}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  if (isLoading) return <Loading />;

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Disclosures" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb3">
          <H2>Are you or a family member any of the following?</H2>
        </div>
        
        <div className="f5 lh-copy pv3">
            <div className="mb3">An officer or 10% or greater shareholder of a publicly traded company, subject to the US Securities Exchange Act 1934?</div>
            <div className="f6 pv1">
              <div className="ba br3 b--moon-gray pa2">
                <Radio name="isControlPerson" value="true" label="Yes" required={true} defaultChecked={disclosure?.isControlPerson} />
              </div>
            </div>
            <div className="f6 pv1">
            <div className="ba br3 b--moon-gray pa2">
              <Radio name="isControlPerson" value="false" label="No" required={true} defaultChecked={!disclosure?.isControlPerson} />
            </div>
            </div>
        </div>

        <div className="f5 lh-copy pv3">
            <div className="mb3">Affiliated with or employed by a stock exchange, regulatory body, member firm of an exchange, FINRA or a municipal securities broker-dealer?</div>
            <div className="f6 pv1">
            <div className="ba br3 b--moon-gray pa2">
              <Radio name="isAffiliatedExchangeOrFinra" value="true" label="Yes" required={true} defaultChecked={disclosure?.isAffiliatedExchangeOrFinra} />
            </div>
            </div>
            <div className="f6 pv1">
              <div className="ba br3 b--moon-gray pa2">
              <Radio name="isAffiliatedExchangeOrFinra" value="false" label="No" required={true} defaultChecked={!disclosure?.isAffiliatedExchangeOrFinra}/>
              </div>
            </div>
        </div>

        <div className="f5 lh-copy pv3">
            <div className="mb3">Currently or formerly a Politically Exposed Person or Public Official?</div>
            <div className="f6 pv1">
            <div className="ba br3 b--moon-gray pa2">
              <Radio name="isPoliticallyExposed" value="true" label="Yes" required={true} defaultChecked={disclosure?.isPoliticallyExposed} />
            </div>
            </div>

            <div className="f6 pv1">
            <div className="ba br3 b--moon-gray pa2">
              <Radio name="isPoliticallyExposed" value="false" label="No" required={true} defaultChecked={!disclosure?.isPoliticallyExposed} />
            </div>
            </div>
        </div>

        <div className="mt4">
          <Button isDisabled={isDisabled} width="w-100">Continue</Button>
          {error && <div className="f7 mt1 dark-red">{error}</div>}
        </div>
      </form>
  </div>
</div>;
}
