import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { COOKIES, PHONE, QUERY } from '../utils';
import { Button, H2, Input } from './partials/ui';
import Nav from './partials/nav';
import arrowLeft from './assets/img/arrow-left.svg';

export default function SignIn({ client }) {
  const history = useHistory();
  const { search } = useLocation();
  const { idToken, next = '/portfolio' } = QUERY.toObject(search);
  const [mode, setMode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberValid , setPhoneNumberValid] = useState(false);
  const [hasSentCode, setHasSentCode] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (idToken) {
      (async () => {
        try {
          const tokens = await client.users.getToken({ idToken });
          COOKIES.set('user_id', tokens.userId, COOKIES.ONE_YEAR);
          COOKIES.set('id_token', idToken, COOKIES.ONE_YEAR);
          history.push(next);
        } catch (e) {
          COOKIES.remove('user_id');
          COOKIES.remove('id_token');
          window.localStorage.removeItem('id_token');
        }
      })();
    }
  }, []);

  useEffect(() => {
    setError(null);
  }, [mode]);
  
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isSigningIn) return;
    const form = new FormData(e.target);
    const data = {};
    for (let [key, value] of form.entries()) {
      data[key] = value;
    }
    try {
      setIsSigningIn(true);
      const user = await client.users.signIn({
        email: data.email,
        password: data.password,
      });

      COOKIES.set('user_id', user.id, COOKIES.ONE_YEAR);
      COOKIES.set('id_token', user.idToken, COOKIES.ONE_YEAR);
      
      history.push(next);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSigningIn(false);
    }
  };

  const validatePhoneNumber = (e) => {
    const { target: { value } } = e;
    const { isValid } = PHONE.validatePhoneNumber(value);
    setPhoneNumberValid(isValid);
  }

  const sendCode = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const phoneNumber = PHONE.getDigits(form.get('phoneNumber'));
    setPhoneNumber(phoneNumber);
    try {
      setIsSigningIn(true);
      await client.phones.signInRequest({ phoneNumber });
      setHasSentCode(true);
    } catch (e) {
      setError(e.message);
      setHasSentCode(false);
    } finally {
      setIsSigningIn(false);
    }
  }

  const resendCode = () => {
    client.phones.verifyRequest({ phoneNumber });
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const code = form.get('code');
    try {
      setIsSigningIn(true);
      const { token } = await client.phones.signInResponse({ code, phoneNumber });
      COOKIES.set('id_token', token, COOKIES.ONE_YEAR);
      history.push(next);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSigningIn(false);
    }
  }

  const formHandler = (() => {
    if (mode === 'email') return onSubmit;
    if (hasSentCode) return verifyCode;
    return sendCode;
  })();

  const title = (() => {
    switch (mode) {
      case 'sms':
      if (hasSentCode) return `Enter the code sent to ${PHONE.formatPhoneNumber(phoneNumber)}.`;
      return 'What is your mobile number?';
      case 'email':
      return `What is your email and password?`
      default:
        return 'How do you want to sign in?';
    }
  })();

  const GoBack = () => {
    const onClick = () => {
      switch (mode) {
        case 'sms':
          if (hasSentCode) {
            setHasSentCode(false);
            setPhoneNumber(null);
          } else {
            setMode(null);
          }
          break;
        case 'email':
          setMode(null);
          break;
        default:
          history.goBack();
          break;
      }
    };
  
    return <img src={arrowLeft} className="pointer" onClick={onClick} />;
  };

  return <div className="absolute absolute--fill">
    <Nav client={client} leftButton={GoBack} footer={false} />
    <div className="w-100 pt6-ns" style={{height: 'calc(100% - 80px)'}}>
      <div className="center pa" style={{maxWidth: 500}}>
        <div className="ttu f5 bold">Welcome Back</div>
        <H2>{title}</H2>
      </div>
      <div className="w-100 center pa" style={{maxWidth: 500}}>
        {!mode && <div className="tc">
          <Button width="w-50" className="nr1" onClick={() => setMode('email')}>Email</Button>
          <Button width="w-50" onClick={() => setMode('sms')}>SMS</Button>
        </div>}
        {Boolean(mode) && <form className="" onSubmit={formHandler}>
          {mode === 'sms' && <>
            {!hasSentCode && <>
              <Input type="tel" name="phoneNumber" label="" placeholder="(201) 555-0123" required={true} autoFocus={true} onChange={validatePhoneNumber} />
              <div className="mt4">
                {error && <div className="mb2 f6 dark-red">{error}</div>}
                <Button isDisabled={!phoneNumberValid || isSigningIn} width="w-100">Sign In</Button>
            </div> 
            </>}
            {hasSentCode && <>
              <Input type="text" name="code" label="" required={true} autoFocus={true} />
              <div className="mt4">
                {error && <div className="mb2 f6 dark-red">{error}</div>}
                <Button isDisabled={isSigningIn} width="w-100">Verify</Button>
                <BlueLink onClick={resendCode}>Resend code</BlueLink>
            </div> 
            </>}
          </>}
          {mode === 'email' && <>
            <Input type="email" name="email" required={true} autoFocus={true} />
            <Input type="password" name="password" required={true} hint={<div className="f6 silver tr">Forgot your password? <Link className="blue bold no-underline" to="/forgot-password">Reset it.</Link></div>} />
            <div className="mt4">
              {error && <div className="mb2 f6 dark-red">{error}</div>}
              <Button isDisabled={isSigningIn} width="w-100">Sign In</Button>
            </div>
          </>}
        </form>}
      </div>
    </div>
  </div>;
}

export function BlueLink({ children, onClick }) {
  return <div className="mt3 f5 bold blue no-underline pointer w-100 tc" onClick={onClick}>{children}</div>
}