import React from 'react';

export default function Disclosures({ fontSize = 'f7', prefix, postfix }) {
  return <div className={`w-100 tl center ${fontSize} lh-copy`} style={{maxWidth: 1100}}>
    {Boolean(prefix) && <p>{prefix}</p>}
    <p>All investments involve risk and the past performance of a security or financial product does not guarantee future results or returns. Keep in mind that while diversification may help spread risk it does not assure a profit, or protect against loss. There is always the potential of losing money when you invest in securities or other financial products. The price of a security or other financial product may increase or decrease based on market conditions and customers may lose money, including their original investment. Investors should consider their investment objectives and risks carefully before investing.</p>
    <p>The Share Invest mobile application, together with any connected features and functionality, is offered by SHARE INVEST INC. ("Share").</p>
    <p>Brokerage services are provided by Alpaca Securities LLC ("Alpaca"), member <a href="https://www.finra.org/" target="_blank">FINRA,</a>  a wholly-owned subsidiary of AlpacaDB, Inc. Clearing services are provided by Velox Clearing LLC (Velox) and Vision Financial Markets LLC (Vision). All three are members of the Securities Investor Protection Corporation (SIPC), which protects securities customers of its members up to $500,000 (including $250,000 for claims for cash) per customer. Explanatory brochure available upon request or at <a href="https://www.sipc.org" target="_blank">www.sipc.org.</a></p>
    <p>Share and Alpaca are not affiliated and neither are responsible for the liabilities of the other.</p>
    <p>This is not an offer, solicitation of an offer, or advice to buy or sell securities, or open a brokerage account in any jurisdiction where Alpaca is not registered (Alpaca is registered only in the United States).</p>
    <p>A security being available for fractional share trading is not a recommendation; whether to use fractional shares at all or to invest in any specific security. A security's eligibility on the list of fractional shares available for trading is not an endorsement of any of the securities, nor is it intended to convey that such securities have low risk. Fractional share transactions are executed either on a principal or riskless principal basis, and can only be bought or sold with market orders during normal market hours.</p>
    <p>For additional questions, please contact our customer service department at <a href="mailto:hi@tryshare.app">hi@tryshare.app.</a></p>
    <p><a href="https://alpaca.markets/disclosures" target="_blank">View Alpaca's Disclosures</a></p>
    {Boolean(postfix) && <p>{postfix}</p>}
  </div>;
}