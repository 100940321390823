import React, { useRef, useState } from 'react';
import _ from 'lodash';
import Button from './button';

export default function Input({
  action,
  autoCompleteConfig = [],
  name, errors, label, focus, hint, hintPlacement, options = [], placeholder, required, type, validator, ...rest }) {
  const [isValid, setIsValid] = useState(null);
  const [validMessage, setValidMessage] = useState('');
  const [autoCompleteMatch, setAutoCompleteMatch] = useState(null);
  const [autoCompleteResults, setAutoCompleteResults] = useState(null);
  const error = errors ? errors[name] : false;
  const isTextInput = type !== 'textarea' && type !== 'select';

  const ref = useRef(null);
  const focusRef = (newSelectionStart) => {
    if (!ref.current) return;

    ref.current.focus()
    if (newSelectionStart) {
      ref.current.setSelectionRange(newSelectionStart, newSelectionStart);
    }
  }

  const handleAutoCompletes = (e) => {
    if (autoCompleteMatch) {
      const matched = handleAutoComplete(autoCompleteMatch, e);
      if (matched) return;
    }

    for (const config of autoCompleteConfig) {
      const matched = handleAutoComplete(config, e);
      if (matched) {
        break;
      }
    }
  }

  const handleAutoComplete = (config, e) => {
    const position = e.target.selectionStart;
    const { target: { value } } = e;
    const currChar = value[position];

    // If cursor is at the beginning of the string, or not at the end of a mention, do not display the autocomplete.
    if (position === 0 || (currChar !== ' ' && currChar !== undefined)) {
      setAutoCompleteMatch(null);
      setAutoCompleteResults(null);
      return false;
    }

    const precedingWords = value.substring(0, position).split(' ');
    const lastWord = precedingWords[precedingWords.length-1];
    const match = lastWord.match(config.regex);
    if (match) {
      setAutoCompleteMatch(config);
      config.handler(match[0], setAutoCompleteResults); 
    } else {
      setAutoCompleteMatch(null);
      setAutoCompleteResults(null);
    }
    return Boolean(match);
  }
  
  const onChange = (e) => {
    if (validator) {
      const { isValid, message } = validator(e);
      setIsValid(isValid);
      setValidMessage(message);
    }

    if (rest.onChange) {
      onChange(e);
    }
  }

  const onFocus = (e) => {
    if (_.isFunction(focus)) focus(true);
  }

  const onBlur = (e) => {
    if (_.isFunction(focus)) focus(false);
  }

  const onSelect = (e) => {
    if (autoCompleteConfig.length) {
      handleAutoCompletes(e);
    }
  }

  const onSelectResult = (e) => {
    if (!ref.current) return;
    const input = ref.current;
    const position = input.selectionStart;

    const precedingWords = input.value.substring(0, position).split(' ');
    const lastWord = precedingWords[precedingWords.length-1];
    const match = lastWord.match(autoCompleteMatch.regex);
    if (!match) return;

    const beforeReplace = input.value.slice(0, position - lastWord.length);
    const afterReplace = input.value.slice(position);
    let replaceValue = e.currentTarget.dataset.replace;
    if (afterReplace[0] !== ' ') replaceValue += ' ';

    input.value = beforeReplace + replaceValue + afterReplace;
    setAutoCompleteMatch(null);
    setAutoCompleteResults(null);
    focusRef(position - lastWord.length + replaceValue.length);
  }

  if (type === 'hidden') return <input type="hidden" name={name} {...rest} />;

  return <React.Fragment>
    {label !== "" && type !== 'hidden' && <label htmlFor={name} className={`db mb2 mt3 ${error ? 'dark-red' : 'black'}`}>{label || _.startCase(name)}</label>}
    <div className={`relative flex flex-row items-center justify-start ${hint ? 'mb1' : 'mb3'}`}>
      {isTextInput && <input
        ref={ref}
        type={type || 'text'}
        className={`w-100 pa3 ba br3 f5 border-box ${error ? 'b--dark-red' : 'b--moon-gray'}`}
        id={name}
        name={name}
        placeholder={placeholder || _.startCase(name)}
        required={required === true}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onSelect={onSelect}
        {...rest}
      />}
      {type === 'textarea' && <textarea
        ref={ref}
        className={`w-100 pa3-ns pa2 ba br3 f5 ${error ? 'b--dark-red' : 'b--moon-gray'}`}
        id={name}
        name={name}
        placeholder={placeholder || _.startCase(name)}
        required={required === true}
        style={{minHeight: 80}}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onSelect={onSelect}
        {...rest}
      />}
      {type === 'select' && <select
        className={`w-100 pa3 ba f5 ${error ? 'b--dark-red' : 'b--moon-gray'}`}
        id={name}
        name={name}
        defaultValue={placeholder || options[0]}
        required={required === true}
        {...rest}
      >
        {placeholder && <option disabled={true} value={placeholder}>{placeholder}</option>}
        {options.map(({ name, value }) => <option key={value} value={value}>{name}</option>)}
      </select>}
      {_.isString(action) && <div className="ml2"><Button>{action}</Button></div>}
      {autoCompleteMatch !== null && <div className={`w-100 absolute pa2 bg-white shadow-5 ba b--moon-gray`} style={{
        top: autoCompleteResults ? (autoCompleteResults.length * - autoCompleteMatch.resultHeight) - 16 + 3 : 0,
      }}>
        {autoCompleteResults?.map((result) => autoCompleteMatch.render(result, onSelectResult))}
      </div>}
    </div>
    {isValid && validMessage && <div className={`w-100 mb3 f7 green lh-copy tl`}>{validMessage}</div>}
    {isValid === false && validMessage && <div className={`w-100 mb3 f7 red lh-copy tl`}>{validMessage}</div>}
    {!validMessage && hint && <div className={`w-100 mb3 f7 silver lh-copy ${hintPlacement || 'tl'}`}>{hint}</div>}
  </React.Fragment>;
}
