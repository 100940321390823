import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Loading from './partials/loading';
import { MONEY, QUERY } from '../utils';
import { Button } from './partials/ui';
import Nav from './partials/nav';
import BackButton from './partials/button-back';

export default function ScheduledBuy({ client }) {
  const { id } = useParams();
  const { returnTo } = QUERY.toObject(useLocation().search);
  const [scheduledBuy, setScheduledBuy] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    (async () => {
      setScheduledBuy(await client.scheduledBuys.getOne({ id }));
    })();
  }, []);

  useEffect(() => {
    if (!scheduledBuy) return;
    (async () => {
      setPaymentMethod(await client.paymentMethods.getOne({ id: scheduledBuy.paymentMethodId }));
    })();
  }, [scheduledBuy]);

  if (!scheduledBuy) return <Loading />;

  return <div className="w-100 vh-100">
    <Nav leftButton={BackButton} client={client} title={`${scheduledBuy.ticker} Buy`} footer={false} />
    <div className="pt3 relative" style={{height: 'calc(100% - 40px)'}}>
      <div className="flex flex-column h-100 w-100 justify-between">
        <div className="mt3">
          <div className="tc w-90 center">
            <div className={`bold pt3 black`} style={{fontSize: 72}}>{MONEY.formattedDollars(scheduledBuy.notional / 100)}</div>
          </div>

          <div className="w-90 center mt4">
            <LabelValue label="Asset" value={scheduledBuy.ticker} />
            <LabelValue label="Date" value={moment(scheduledBuy.date).format('MMM D, YYYY')} />
            <LabelValue label="Status" value={_.startCase(scheduledBuy.status)} />
            {paymentMethod && <LabelValue label="Payment method" value={paymentMethod.name} />}
          </div>
        </div>

        <div>
          <div className="w-90 center mv4">
            {returnTo && <Link to={returnTo}>
              <Button width={`w-100`}>Done</Button>
            </Link>}
          </div>
        </div>
      </div>
    </div>
  </div>
}

function LabelValue({ label, value }) {
  return <div className="flex flex-row w-100 items-start justify-between bb b--moon-gray" style={{paddingTop: 24, paddingBottom: 24}}>
    <div className="bold">{label}</div>
    <div className="w-50 tr">{value}</div> 
  </div>;
}