import React from 'react';
import { isPlainObject, isString, } from 'lodash';

const colors = {
  black: { bg: 'black', text: 'white' },
  blue: { bg: 'blue', text: 'white' },
  red: { bg: 'dark-red', text: 'white' },
}

function borderString(outline) {
 return outline === 'none' ? 'bn' : 'ba bw2';
}

function colorString(color, outline) {
  const { bg, text } = (() => {
    if (isString(color)) {
      if (colors.hasOwnProperty(color)) {
        return colors[color];
      }

      console.warn(`unsupported color property ${color}`);
    } else if (isPlainObject(color)) {
      const { bg, text } = color;
      if (bg && text) {
        return { bg, text };
      }

      console.warn(`missing required properties bg and text`);
    }

    return colors.black;
  })();
  if (outline === 'none') return `${bg} bg-transparent`;
  return outline ? `bg-${text} ${bg} b--${bg}` : `bg-${bg} ${text} b--${bg}`; 
}

export function TinyButton({
  bubbles,
  children,
  className = '',
  color = 'black',
  href,
  isDisabled,
  onClick,
  outline = true,
  width,
  style: styleOverride,
  ttl = true,
  ...rest
}) {
  return <button
  className={`${className} ${colorString(color, outline)} ${isDisabled ? 'o-40' : 'pointer'} lh-copy ${borderString(outline)} br3 ${ttl ? 'ttl' : ''} ba bw2 bold ${width || ''}`}
  disabled={isDisabled === true}
  onClick={(e) => {
    if (bubbles === false || href) {
      e.stopPropagation();
    }

    if (href) {
      e.preventDefault();
      window.open(href, '_blank');
      return;
    }

    e.target.blur();
    if (onClick) {
      onClick(e);
    }
  }}
  style={{ fontSize: 18, padding: `5px 10px`, borderRadius: 10, ...styleOverride }}
  {...rest}
  >
    {children}
  </button>
}

export default function Button({
  bubbles,
  children,
  className = '',
  color = 'black',
  href,
  isDisabled,
  onClick,
  outline = true,
  width,
  style: styleOverride,
  ...rest
}) {
  return <button
  className={`${className} ${colorString(color, outline)} ${isDisabled ? 'o-40' : 'pointer'} f4 bold ${borderString(outline)} br4 ttl bold ${width || ''}`}
  disabled={isDisabled === true}
  onClick={(e) => {
    if (bubbles === false || href) {
      e.stopPropagation();
    }

    if (isDisabled) {
      e.preventDefault();
      return;
    };

    if (href) {
      e.preventDefault();
      window.open(href, '_blank');
      return;
    }

    e.target.blur();
    if (onClick) {
      onClick(e);
    }
  }}
  style={{ padding: `12px 28px`, ...styleOverride }}
  {...rest}
  >
    {children}
  </button>
}
