import React from 'react';
import buy from '../../assets/img/batch/buy.svg';
import firstBuy from '../../assets/img/batch/first-buy.svg';
import hold from '../../assets/img/batch/hold.svg';
import sell from '../../assets/img/batch/sell.svg';

export default function Badge({ type }) {
  const text = {
    buy: 'Buy',
    hold: 'Hold',
    new: 'New',
    sell: 'Sell',
  };

  const icon = {
    buy,
    hold,
    new: firstBuy,
    sell,
  };

  const bgColor = {
    buy: 'cyan',
    hold: 'gold',
    sell: 'orange',
  };

  return <div className={`flex flex-row items-center bg-${bgColor[type]} ml2 ph2 pv1 f7 br3`} style={{flexShrink: 0}}>
    <img src={icon[type]} style={{width: 13}} />
    <span className="ml2">{text[type]}</span>
  </div>; 
}