import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import TemplateCard from './partials/template-card';
import { BigNumber, Button, H2, NumberPad, TinyButton } from './partials/ui';
import { MONEY, QUERY, S3 } from '../utils';

function RiskBadge({ beta }) {
  const color = (() => {
    if (beta <= 0) return 'bright-mint';
    if (beta < 0.8) return 'cyan';
    if (beta < 1.1) return 'gold';
    return 'hot-pink white';
  })();

  let text;
  if (beta <= 0) {
    text = 'Rock solid';
  } else if (beta < 0.8) {
    text = 'Stable';
  } else if (beta < 1.1) {
    text = 'Moderate';
  } else {
    text = 'Aggressive';
  }

  return <div className={`pa1 br2 black f7 ttl bg-${color}`}>{text}</div>
}


export default function PortfolioBuilder({ client }) {
  const history = useHistory();
  const [step, setStep] = useState('start');
  const [simulations, setSimulations] = useState({});
  const [templates, setTemplates] = useState(null);
  const [weeklyContribution, setWeeklyContribution] = useState('');
  const [base1, setBase1] = useState(null);
  const [base2, setBase2] = useState(null);

  useEffect(() => {
    (async () => {
      setTemplates(await client.templates.get());
    })();
  }, []);

  useEffect(() => {
    if (!templates) return;
    templates.forEach(async (template) => {
      const { bucket, path } = await client.simulations.get({ templateId: template.id });
      const meta = await S3.getMeta({ bucket, path });
      setSimulations((prevSimulations) => ({
        ...prevSimulations,
        [template.id]: meta,
      }));
    });
  }, [templates]);

  const findTemplate = (name) => {
    return templates?.find(t => {
      return t.name.startsWith(name);
    })
  }

  const findHiddenTemplate = (name) => {
    return templates?.find(t => {
      return t.name.startsWith(name) && t.isHidden;
    })
  }

  const onSelectBase1 = (base1) => {
    setBase1(base1);
    setStep('base2');
  }

  const onSelectBase2 = (base2) => {
    setBase2(base2);
    setStep('extras');
  }

  const intContribution = parseInt(weeklyContribution);

  const cash = findTemplate('Cash');
  const vdc = findHiddenTemplate('Consumer Staples');
  const voo = findTemplate('S&P 500');
  const vym = findTemplate('Dividend Payers');
  const qqq = findTemplate('Nasdaq 100');

  const ai = findTemplate('Artificial Intelligence');
  const banking = findTemplate('Banking');
  const bigTech = findTemplate('Big Tech');
  const oil = findTemplate('Oil');
  const renewables = findTemplate('Renewables');
  const semiconductors = findTemplate('Semiconductors');
  const travel = findTemplate('Travel');

  const extras = [ai, banking, bigTech, oil, renewables, semiconductors, travel];

  const [b1Percent, b2Percent, extrasPercent] = (() => {
    if (intContribution < 10) return [100, 0, 0];
    if (intContribution < 20) return [70, 30, 0];
    return [50, 30, 20];
  })();

  if (step === 'start') {
    return <div>
       <Nav client={client} leftButton={BackButton} title="Portfolio Builder" footer={false} />
       <div className="w-100 ph pt6-ns pt3 center" style={{maxWidth: 500}}>
          <div className="f6 lh-copy">
            A step by step process to build a portfolio that matches your risk level, investment goals, and time horizon. 
          </div>

        <div className="mt3">
          
          <div className="ttu bold f5 mb1 purple">Step 1</div>
          <div className={`ph3 pv ba br4`}>
            <div className="tl">
              <div className="f4 bold">Choose Base 1</div>
            </div>
            <div className="tl lh-copy f6 mt2 black">
              This forms the base of your portfolio. Diversified, low volatility, and long-term. At least 50% of your weekly investment will go here.
            </div>
          </div>

          <div className="ttu bold f5 mt3 mb1 orange">Step 2 - If investing $10/wk or more</div>

          <div className={`ph3 pv ba br4`}>
            <div className="tl">
              <div className="f4 bold">Choose Base 2</div>
            </div>
            <div className="tl lh-copy f6 mt2 black">
              A second base to add more diversification, with a slightly higher risk profile. About 30% of your weekly investment will go here.
            </div>
          </div>


          <div className="ttu bold f5 mt3 mb1 hot-pink">Step 3 - If investing $20/wk or more</div>
          <div className={`ph3 pv ba br4`}>
            <div className="tl">
              <div className="f4 bold">Choose Extras</div>
            </div>
            <div className="tl lh-copy f6 mt2 black">
              Optionally, add additional strategies to your portfolio. These are themes or ideas you are interested in. At most 20% of your weekly investment will go here.
            </div>
          </div>

          <div className="mt4">
            <Button width="w-100" onClick={() => setStep('amount')}>Get started</Button>
          </div>

        </div>
       </div>
    </div>
  }

  if (step === 'amount') {
    return <div>
      <Nav client={client} leftButton={BackButton} footer={false} />
      <div className="w-100 ph pt6-ns pt3 center" style={{maxWidth: 500}}>
      <div className="tl w-100">
        <div className="ttu f5 bold mt3">Starting Amount</div>
        <H2>How much do you want to invest per week?</H2>
        <div className="dark-gray">Must be at least {MONEY.formattedDollars(5, { wholeDollar: true })}. You can change it later.</div>
        <div className="mt5 mb3 tc">
          <BigNumber superscriptDollarSign={true}>
            {MONEY.formattedDollars(weeklyContribution, { wholeDollar: true })}
          </BigNumber>
          <div>per week</div>
        </div>
      </div>
      <div className="mt3 w-100">
        <NumberPad allowDecimal={false} amount={weeklyContribution} onChange={setWeeklyContribution} max={10000} />
        <div className="mt3 w-100">
          <Button width="w-100" isDisabled={weeklyContribution < 5} onClick={() => setStep('base1')}>Next</Button>
        </div>
      </div>
      </div>
    </div>;
  }

  if (step === 'base1') {
    return <div>
        <Nav client={client} leftButton={BackButton} title="Choose Base 1" footer={false} />
        <div className="w-100 ph pt6-ns pt3 center" style={{maxWidth: 500}}>
          <div className="f6 lh-copy">
            Choose one of the two options below. {b1Percent}% of your investment will go here.
          </div>

          <div className="mt3">
            <div className="ttu bold f5 mb1">Option 1</div>
            <div className={`ph3 pv ba br4`}>
              <div className="flex flex-row items-center justify-start">
                <div className="f4 bold mr2">{cash.name}</div>
                <RiskBadge beta={cash.beta} />
              </div>
              <div className="tl lh-copy f6 mt2 black">
                If you're risk-averse (don't want ANY losses) or have a time horizon of less than 3 years, this is a good option. This strategy provides safety, an annual return of 5.46% (as of September 30th), and full liquidity.
              </div>
              <div className="mt3">
                <TinyButton width="w-100" onClick={() => onSelectBase1(cash)}>Choose {cash.name}</TinyButton>
              </div>
            </div>
          </div>

          <div className="mt3">
            <div className="ttu bold f5 mb1">Option 2</div>
            <div className={`ph3 pv ba br4`}>
              <div className="flex flex-row items-center justify-start">
                <div className="f4 bold mr2">{voo.name}</div>
                <RiskBadge beta={voo.beta} />
              </div>
              <div className="tl lh-copy f6 mt2 black">
                {voo.description}
              </div>
              <div className="mt3">
                <TinyButton width="w-100" onClick={() => onSelectBase1(voo)}>Choose {voo.name}</TinyButton>
              </div>
            </div>
          </div>


        </div>
    </div>
  }

  if (step === 'base2') {
    return <div>
        <Nav client={client} leftButton={BackButton} title="Choose Base 2" footer={false} />
        <div className="w-100 ph pt6-ns pt3 center" style={{maxWidth: 500}}>
          <div className="f6 lh-copy">
            Choose one of the options below. {b2Percent}% of your investment will go here.
          </div>

          <div className="mt3">
            <div className="ttu bold f5 mb1">Option 1</div>
            <div className={`ph3 pv ba br4`}>
              <div className="flex flex-row items-center justify-start">
                <div className="f4 bold mr2">{qqq.name}</div>
                <RiskBadge beta={qqq.beta} />
              </div>
              <div className="tl lh-copy f6 mt2 black">
                {qqq.description}
              </div>
              <div className="mt3">
                <TinyButton width="w-100" onClick={() => onSelectBase2(qqq)}>Choose {qqq.name}</TinyButton>
              </div>
            </div>
          </div>

          <div className="mt3">
            <div className="ttu bold f5 mb1">Option 2</div>
            <div className={`ph3 pv ba br4`}>
              <div className="flex flex-row items-center justify-start">
                <div className="f4 bold mr2">{vym.name}</div>
                <RiskBadge beta={vym.beta} />
              </div>
              <div className="tl lh-copy f6 mt2 black">
                {vym.description}
              </div>
              <div className="mt3">
                <TinyButton width="w-100" onClick={() => onSelectBase2(vym)}>Choose {vym.name}</TinyButton>
              </div>
            </div>
          </div>

          <div className="mt3">
            <div className="ttu bold f5 mb1">Option 3</div>
            <div className={`ph3 pv ba br4`}>
              <div className="flex flex-row items-center justify-start">
                <div className="f4 bold mr2">{vdc.name}</div>
                <RiskBadge beta={vdc.beta} />
              </div>
              <div className="tl lh-copy f6 mt2 black">
                {vdc.description}
              </div>
              <div className="mt3">
                <TinyButton width="w-100" onClick={() => onSelectBase2(vdc)}>Choose {vdc.name}</TinyButton>
              </div>
            </div>
          </div>

        </div>
    </div>
  }

  if (step === 'extras') {
    return <div>
      <Nav client={client} leftButton={BackButton} title="Extras" footer={false} />
      <div className="w-100 ph pt6-ns pt3 center" style={{maxWidth: 500}}>
        <div className="f6 lh-copy">
          Choose up to two of the options below. {extrasPercent}% of your investment will go here.
        </div> 

        <div className="mt4">
          {extras.map((t, idx) =>
            <TemplateCard
            key={t.uuid}
            border="black"
            cta="select"
            template={t}
            simulation={simulations[t.id]}
            />)}
          </div>
      </div>
    </div>;
  }

    

}

function Start({ client }) {
  return <div className="w-100 tc center ph ph0-ns pt6-ns mt4" style={{maxWidth: 500, paddingBottom: 80}}>
    <h1 className="f2 fw6">Portfolio Builder</h1>
    <p className="f4 fw4">Create a portfolio of companies to track and analyze.</p>
    <div className="mt4">
      <button
        className="f4 fw6 bn bg-black white br3 pv3 ph4 pointer"
        onClick={() => setStep('companies')}
      >
        Start
      </button>
    </div>
  </div>
}