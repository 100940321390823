import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Nav from '../partials/nav';
import Loading from '../partials/loading';
import { Button, H2, Input } from '../partials/ui';
import BackButton from '../partials/button-back';

function formatSocialSecurity(val) {
	val = val.replace(/\D/g, '');
	val = val.replace(/^(\d{3})/, '$1-');
	val = val.replace(/-(\d{2})/, '-$1-');
	val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
	return val;
}

export default function AccountSSN({ client, onSsnCreate }) {
  const [user, setUser] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        //this is fatal, render an error later 
      }
    })();
  }, []);

  const validateSocial = (e) => {
    e.target.value = formatSocialSecurity(e.target.value);
    const isValid = e.target.value.length === 11;
    setIsDisabled(!isValid);
    return { isValid };
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const ssn = form.get('ssn');
    onSsnCreate({ ssn });
  };

  if (!user) return <Loading />;

  return <div className="w-100 min-vh-900">
    <Nav client={client} leftButton={BackButton} title="SSN" footer={false} />
    <div className="w-100 center pt6-ns pv3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onSubmit}>
        <div className="mb5">
          <H2>Identity Verification</H2>
        </div>

        <Input
        type="text"
        name="ssn"
        label="Social Security Number"
        placeholder="111-11-1111"
        required={true}
        hint="We're required by law to obtain your SSN to verify your identity - nothing else. It will not be stored by Share."
        validator={validateSocial}
        autoComplete="off"
        />

        <div className="mt4">
          <Button isDisabled={isDisabled} width="w-100" >Continue</Button>
        </div>
      </form>
  </div>
</div>;
}