import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { H2, Button } from './partials/ui';
import logo from './assets/img/logo.svg';
import Disclosures from './partials/disclosures';
import { QUERY } from '../utils';

export default function GetStarted() {
  const { search } = useLocation();
  const { next } = QUERY.toObject(search);
  const [showDisclosures, setShowDisclosures] = useState(false);

  if (showDisclosures) {
    return <div className="w-100 min-vh-100 pa3">
      <H2>Disclosures</H2>
      <Disclosures />
      <Button className="mt2" width="w-100" onClick={() => setShowDisclosures(false)}>Done</Button>
    </div>
  }

  return <div className="relative w-100 vh-100 flex flex-column items-center justify-center" style={{background: 'linear-gradient(#FF76AB, #FFD057)'}}>
  <div className="w-100 flex flex-column items-center align-start" style={{maxWidth: 500}}>
    <div className="mv2 tc">
      <Link to="/"><img className="pb3 pr3" src={logo} style={{width: 150}} /></Link>
      <div>Invest better.</div>
    </div>
    <div className="absolute bottom-2 w-100-ns w-90" style={{maxWidth: 500}}>
      <Link to={`/sign-in${Boolean(next) ? `?next=${next}` : ''}`}>
        <div className="mt2">
        <Button width="w-100" outline={true}>Log In</Button>
        </div>
      </Link>
      <div className="mid-gray f6 underline tc mt2 pointer" onClick={() => setShowDisclosures(true)}>View our Disclosures</div>
    </div>
  </div>
</div>; 
}