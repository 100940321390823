import React, { useState } from 'react';
import logo from './assets/img/logo.svg';
import { Button, Input } from './partials/ui';
import { Link, useHistory, useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword({ client }) {
  const history = useHistory();

  const query = useQuery();
  const resetToken = query.get('reset_token');
  if (!resetToken) return history.push('/groups');

  const [isResetting, setIsResetting] = useState(false);
  const [didReset, setDidReset] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = new FormData(e.target);

    if (!form.get('password') || form.get('password') !== form.get('confirmPassword')) {
      setError('passwords do not match');
      return;
    }

    try {
      setIsResetting(true);
      await client.users.resetPassword(resetToken, form.get('password'));
      setError(null);
      setDidReset(true);
      window.setTimeout(() => {
        history.push('/sign-in');
      }, 3000);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsResetting(false);
    }
  }

  return <div className="absolute absolute--fill flex flex-column items-center justify-center">
    <div className="w-100-ns w-90 flex flex-column items-center align-start" style={{maxWidth: 500}}>
      <div className="mv2">
        <Link to="/"><img src={logo} style={{width: 200}} /></Link>
      </div>
      <form className="w-100" onSubmit={onSubmit}>
        <div className="f2 tc mb5">Password Reset</div>
        <Input type="password" name="password" />
        <Input type="password" name="confirmPassword" />     
        <div className="mt4">
          {didReset && <div className="mb2 f6">
            Your password has been successfully changed. You will be redirected in 3 seconds.
          </div>}
          {error && <div className="mb2 f6">{error}</div>}
          <Button isDisabled={isResetting} width="w-100">Reset Password</Button>
        </div>
      </form>
      <div className="f6 silver mt3 tc"><Link className="light-silver" to="/sign-in">Back to login.</Link></div>
    </div>
  </div>;
}

