import React from 'react';
import _ from 'lodash';
import chevronRight from '../../assets/img/chevron-right.svg';

function fontSize(text, size) {
  const { length } = text.replace(/[,.]/g, '');
  if (size === 'big') return length > 6 ? 36 : 48;
  return length > 6 ? 28 : 32;
}

export default function Bubble({ color, contrast = 'black', onClick, number, render, subtitle, size, width }) {
  const _onClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  }

  return <div className={`bg-${color} ${contrast} pa3 tc flex flex-column items-center justify-center mr1 ${width}`} onClick={_onClick} style={{borderRadius: 20}}>
    <div className="bold mt3" style={{fontSize: fontSize(number, size)}}>
      {_.isFunction(render) ? render() : number}
    </div>
    <div className="f5 mt1 mb3 ttl">{subtitle}</div>
    {_.isFunction(onClick) && <div className="f6 bold flex flex-row items-center justify-center">
      <div>See details</div>
      <img className="ml1" src={chevronRight} style={{marginTop: 2, width: 6}} />
    </div>}
  </div>;
}