import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MONEY } from '../utils';
import chevronRight from './assets/img/chevron-right.svg';
import Nav from './partials/nav';
import BackButton from './partials/button-back';

export default function Orders({ client }) {
  const [groupBuys, setGroupBuys] = useState(null);

  useEffect(() => {
    (async () => {
      setGroupBuys(await client.groupBuys.get());
    })();
  }, []);

  return <div>
    <Nav client={client} leftButton={BackButton} title="Fund Buys" />
    <div className="center w-100-ns w-90 pt6-ns pt3" style={{maxWidth: 500, paddingBottom: 80}}>
    <div className="pb3">
    {Array.isArray(groupBuys) && groupBuys.map((groupBuy, idx) =>
    <Link key={`group-buy-${groupBuy.id}`} to={`/history/group-buys/${groupBuy.id}`} className="black no-underline">
    <div className={`pv4 ${idx < groupBuys.length - 1 ? 'bb' : 'bn'} b--light-gray flex flex-row items-center justify-between`}>
        <div>
          <div className="f5">{groupBuy.group?.name}</div>
          <div className="f6 mt2 silver flex flex-row items-center justify-start">
            <div>{_.startCase(groupBuy.status)}</div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          {MONEY.formattedDollars(groupBuy.amount/100)}
          <img className="ml2" src={chevronRight} style={{width: 15, height: 15}} />
        </div>
    </div>
    </Link>)}
    </div>
    </div>
  </div>
}