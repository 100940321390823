import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Button, Input } from './partials/ui';
import { PHONE } from '../utils';
import Disclosures from './partials/disclosures';
import logo from './assets/img/logo.svg';

export default function Refer({ client }) {
  const { referralCode } = useParams();
  const [referrer, setReferrer] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const [user] = await client.users.get({ referralCode });
        setReferrer(user);
      } catch (err) {
        setError(`invalid referral code`);
      }
    })();
  }, [referralCode]);

  if (!referrer) return null;

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const referralCode = form.get('referralCode').trim();
    if (!referralCode) {
      setError('referralCode is required');
      return;
    }

    const phoneNumber = PHONE.getDigits(form.get('phoneNumber'));
    if (phoneNumber.length !== 10) {
      setError('Phone number must be 10 digits.');
      return;
    }

    try {
      setIsSending(true);
      const invitation = await client.invitations.sendWithCode({
        phoneNumber,
        referralCode,
      });
      setInvitation(invitation);
    } catch(e) {
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  return <div className="absolute absolute--fill">
    <div className="pv5 mh tc center-ns" style={{maxWidth: 500}}>
      <div className="mb4">
        <img src={logo} style={{width: 120}} />
      </div>
      <div>
        <Avatar user={referrer} size={80} />
        <div className="mt2 f5 lh-copy">
          {referrer.firstName} invited you to join Share, a modern investing app for building simple, diversified portfolios.
        </div>

        <div className="mt4 center" style={{paddingBottom: 20}}>
          {!Boolean(invitation) && <form onSubmit={onSubmit}>
            <Input type="tel" name="phoneNumber" label="" disabled={isSending} autoComplete="off" required={true} />
            <input type="hidden" name="referralCode" value={referralCode} />
            <div className="mt3 tr">
              <Button isDisabled={isSending} width="w-100">Text Me the App</Button>
              {!error && <div className="f6 lh-copy mt2 tc">Message and data rates may apply. App is available on iOS only.</div>}
              {error && <div className="dark-red f7 mt1">{error}</div>}
            </div>
          </form>}
          {Boolean(invitation) && <div>
            <div className="f5 lh-copy">We sent an invitation to {PHONE.formatPhoneNumber(invitation.phoneNumber)}.</div>
            <div className="mt3 f5 underline pointer" onClick={() => setInvitation(null)}>Send again.</div>
          </div>}
        </div>

        <Disclosures />
      </div>
    </div>
  </div>
}

