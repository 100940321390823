import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export default function NumberPad({ allowDecimal = false, amount, isDisabled, max, onChange }) {
  const [digits, setDigits] = useState([]);

  useEffect(() => {
    setDigits(_.split(amount, ''));
  }, [amount]);

  const onClickDigit = (e) => {
    if (isDisabled) return;
    const { target: { dataset: { digit } } } = e;

    //check if full number
    const decimalIdx = digits.indexOf('.');
    if (decimalIdx >= 0 && digits.length - decimalIdx == 3) return;

    const nextDigits = [...digits, digit];
    const nextNumber = nextDigits.join('');
    
    //check if exceeds max
    if (max && Number.parseFloat(nextNumber) > max) return;

    //can proceed
    setDigits(nextDigits);
    onChange(nextDigits.join(''));
  };

  const onClickDecimal = () => {
    if (digits.includes('.')) return;
    const nextDigits = digits.length === 0 ? ['0', '.'] : [...digits, '.'];
    setDigits(nextDigits);
    onChange(nextDigits.join(''));
  };

  const onClickDelete = () => {
    digits.pop();
    setDigits([...digits]);
    onChange(digits.join(''));
  };

  return <div className={`flex flex-row flex-wrap ${isDisabled ? 'o-50' : ''}`}>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="1" onClick={onClickDigit}>1</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="2" onClick={onClickDigit}>2</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="3" onClick={onClickDigit}>3</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="4" onClick={onClickDigit}>4</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="5" onClick={onClickDigit}>5</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="6" onClick={onClickDigit}>6</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="7" onClick={onClickDigit}>7</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="8" onClick={onClickDigit}>8</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="9" onClick={onClickDigit}>9</div>
    {!allowDecimal && <div className="w-third f3 tc mv3"> </div>}
    {allowDecimal && <div className="pointer w-third f3 tc mv3" onClick={onClickDecimal}>.</div>}
    <div className="pointer w-third f3 tc mv3 dark-gray" data-digit="0" onClick={digits.length > 0 ? onClickDigit : () => {}}>0</div>
    <div className="pointer w-third f3 tc mv3 dark-gray" onClick={onClickDelete}>{`<`}</div>
  </div>;
}