import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Button, H2, TinyButton } from '../partials/ui';
import { MONEY, PHONE, QUERY } from '../../utils';
import Nav from '../partials/nav';
import BackButton from '../partials/button-back';

export default function ReviewAccount({ client, contact, disclosure, employment, error, identity, income, onCreateBrokerageAccount, ssn }) {
  const { section } = QUERY.toObject(useLocation().search);

  useEffect(() => {
    if (!section) return;
    const el = document.getElementById(section);
    el?.scrollIntoView();
  }, [section]);

  if (!ssn) return <Redirect to="/account/onboarding/ssn" />;

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title="Last Step" footer={false} />
    <div className="w-100 center pt6-ns pt3 ph0-ns ph" style={{maxWidth: 500}}>
      <div className="db-ns dn">
        <BackButton />
      </div>

      <form name="signUp" onSubmit={onCreateBrokerageAccount}>
        <div className="mb5">
          <H2>Review your info</H2>
          <div className="f6 dark-gray lh-copy">Please check it over. Errors will delay your account opening.</div>
        </div>

        <div className="">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="contact">Contact</div>
            <Link to="/account/onboarding/contact?returnTo=contact" className="no-underline black">
              <TinyButton>Edit</TinyButton>
            </Link>
          </div>
          <div className="f5 mb2 bold">Address</div>
          <div className="mb2">{contact.addressOne}{Boolean(contact.addressTwo) && <>, {contact.addressTwo}</>}</div>
          <div className="mb4">{contact.city}, {contact.state} {contact.postalCode}</div>
          <div className="f5 mb2 bold">Email</div>
          <div className="mb4">{contact.email}</div>
          <div className="f5 mb2 bold">Phone</div>
          <div className="mb4">{PHONE.formatPhoneNumber(contact.phone)}</div>
        </div>

        <div className="mt4 pt4 bt b--moon-gray">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="disclosures">Disclosures</div>
            <Link to="/account/onboarding/disclosure?returnTo=disclosures" className="no-underline black">
              <TinyButton>Edit</TinyButton>
            </Link>
          </div>
          <div className="f5 mb2 bold">I'm a senior executive or 10% shareholder.</div>
          <div className="mb4">{disclosure.isControlPerson ? 'Yes' : 'No'}</div>
          <div className="f5 mb2 bold">I work for a brokerage.</div>
          <div className="mb4">{disclosure.isAffiliatedExchangeOrFinra ? 'Yes' : 'No'}</div>
          <div className="f5 mb2 bold">I'm politically exposed.</div>
          <div className="mb4">{disclosure.isPoliticallyExposed ? 'Yes' : 'No'}</div>
        </div>

        <div className="mt4 pt4 bt b--moon-gray">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="employment">Employment</div>
            <Link to="/account/onboarding/employment?returnTo=employment" className="no-underline black">
              <TinyButton>Edit</TinyButton>
            </Link>
          </div>
          <div className="f5 mb2 bold">Employment Status</div>
          <div className="mb4">{_.startCase(employment.employmentStatus)}</div>
          {employment.employmentStatus === 'employed' && <>
          <div className="f5 mb2 bold">Employer</div>
          <div className="mb4">{employment.employerName}</div>
          <div className="f5 mb2 bold">Employer Address</div>
          <div className="mb4 lh-copy">{employment.employerAddress}</div>
          </>}
        </div>

        <div className="mt4 pt4 bt b--moon-gray">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="identity">Identity</div>
            <Link to="/account/onboarding/identity?returnTo=identity" className="no-underline black"><TinyButton>Edit</TinyButton></Link>
          </div>
          <div className="f5 mb2 bold">Legal Name</div>
          <div className="mb4">{identity.legalFirstName} {identity.legalLastName}</div>
          <div className="f5 mb2 bold">Date of Birth</div>
          <div className="mb4">{identity.dateOfBirth}</div>
          <div className="f5 mb2 bold">Citizenship</div>
          <div className="mb4">{countryListAlpha3[identity.countryOfCitizenship]}</div>
          <div className="f5 mb2 bold">Tax Residency</div>
          <div className="mb4">{countryListAlpha3[identity.countryOfTaxResidency]}</div>
          <div className="f5 mb2 bold">Funding Source</div>
          <div className="mb4">{_.startCase(identity.fundingSource)}</div>
        </div>

        <div className="mt4 pt4 bt b--moon-gray">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="incomeAndAssets">Income &amp; assets</div>
            <Link to="/account/onboarding/income?returnTo=incomeAndAssets" className="no-underline black">
              <TinyButton>Edit</TinyButton>
            </Link>
          </div>
          <div className="f5 mb2 bold">Annual Income</div>
          <div className="mb4">
            {MONEY.formattedDollars(income.annualIncomeMin, { wholeDollar: true })}
            {income.annualIncomeMax === -1 && '+'}
            {income.annualIncomeMax !== -1 && <> - {MONEY.formattedDollars(income.annualIncomeMax, { wholeDollar: true })}</>}
            </div>
          <div className="f5 mb2 bold">Liquid Net Worth</div>
          <div className="mb4">
            {MONEY.formattedDollars(income.liquidNetWorthMin, { wholeDollar: true })}
            {income.liquidNetWorthMax === -1 && '+'} 
            {income.liquidNetWorthMax !== -1 && <> - {MONEY.formattedDollars(income.liquidNetWorthMax, { wholeDollar: true })}</>}
          </div>
          <div className="f5 mb2 bold">Total Net Worth</div>
          <div className="mb4">
            {MONEY.formattedDollars(income.totalNetWorthMin, { wholeDollar: true })}
            {income.totalNetWorthMax === -1 && '+'}  
            {income.totalNetWorthMax !== -1 && <> - {MONEY.formattedDollars(income.totalNetWorthMax, { wholeDollar: true })}</>}
          </div>
        </div>

        <div className="mt4 pt4 bt b--moon-gray">
          <div className="flex flex-row items-center justify-between mb4">
            <div className="f3 bold" id="taxIdentity">Tax identity</div>
            <Link to="/account/onboarding/ssn?returnTo=ssn" className="no-underline black">
              <TinyButton>Edit</TinyButton>
            </Link>
          </div>
          <div className="f5 mb2 bold">SSN</div>
          <div className="mb4">{ssn}</div>
        </div> 

        <div className="mt5 mb3">
          <Button width="w-100">Looks Good, Submit</Button>
          {error && <div className="f7 mt1 dark-red">{error}</div>}
        </div>
      </form>
    </div>
  </div>;
}


const countryListAlpha3 = {
  "AFG": "Afghanistan",
  "ALB": "Albania",
  "DZA": "Algeria",
  "ASM": "American Samoa",
  "AND": "Andorra",
  "AGO": "Angola",
  "AIA": "Anguilla",
  "ATA": "Antarctica",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "ABW": "Aruba",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BMU": "Bermuda",
  "BTN": "Bhutan",
  "BOL": "Bolivia (Plurinational State of)",
  "BES": "Bonaire, Sint Eustatius and Saba",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BVT": "Bouvet Island",
  "BRA": "Brazil",
  "IOT": "British Indian Ocean Territory",
  "BRN": "Brunei Darussalam",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CYM": "Cayman Islands",
  "CAF": "Central African Republic",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "CXR": "Christmas Island",
  "CCK": "Cocos (Keeling) Islands",
  "COL": "Colombia",
  "COM": "Comoros",
  "COD": "Congo (the Democratic Republic of the)",
  "COG": "Congo",
  "COK": "Cook Islands",
  "CRI": "Costa Rica",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CUW": "Curaçao",
  "CYP": "Cyprus",
  "CZE": "Czechia",
  "CIV": "Côte d'Ivoire",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FLK": "Falkland Islands [Malvinas]",
  "FRO": "Faroe Islands",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GUF": "French Guiana",
  "PYF": "French Polynesia",
  "ATF": "French Southern Territories",
  "GAB": "Gabon",
  "GMB": "Gambia",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GIB": "Gibraltar",
  "GRC": "Greece",
  "GRL": "Greenland",
  "GRD": "Grenada",
  "GLP": "Guadeloupe",
  "GUM": "Guam",
  "GTM": "Guatemala",
  "GGY": "Guernsey",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HMD": "Heard Island and McDonald Islands",
  "VAT": "Holy See",
  "HND": "Honduras",
  "HKG": "Hong Kong",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran (Islamic Republic of)",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "IMN": "Isle of Man",
  "ISR": "Israel",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JEY": "Jersey",
  "JOR": "Jordan",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "PRK": "Korea (the Democratic People's Republic of)",
  "KOR": "Korea (the Republic of)",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Lao People's Democratic Republic",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MAC": "Macao",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands",
  "MTQ": "Martinique",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MYT": "Mayotte",
  "MEX": "Mexico",
  "FSM": "Micronesia (Federated States of)",
  "MDA": "Moldova (the Republic of)",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MSR": "Montserrat",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands",
  "NCL": "New Caledonia",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger",
  "NGA": "Nigeria",
  "NIU": "Niue",
  "NFK": "Norfolk Island",
  "MNP": "Northern Mariana Islands",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PSE": "Palestine, State of",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines",
  "PCN": "Pitcairn",
  "POL": "Poland",
  "PRT": "Portugal",
  "PRI": "Puerto Rico",
  "QAT": "Qatar",
  "MKD": "Republic of North Macedonia",
  "ROU": "Romania",
  "RUS": "Russian Federation",
  "RWA": "Rwanda",
  "REU": "Réunion",
  "BLM": "Saint Barthélemy",
  "SHN": "Saint Helena, Ascension and Tristan da Cunha",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "MAF": "Saint Martin (French part)",
  "SPM": "Saint Pierre and Miquelon",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SXM": "Sint Maarten (Dutch part)",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "SGS": "South Georgia and the South Sandwich Islands",
  "SSD": "South Sudan",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan",
  "SUR": "Suriname",
  "SJM": "Svalbard and Jan Mayen",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syrian Arab Republic",
  "TWN": "Taiwan",
  "TJK": "Tajikistan",
  "TZA": "Tanzania, United Republic of",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TKL": "Tokelau",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TKM": "Turkmenistan",
  "TCA": "Turks and Caicos Islands",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates",
  "GBR": "United Kingdom of Great Britain and Northern Ireland",
  "UMI": "United States Minor Outlying Islands",
  "URY": "Uruguay",
  "USA": "United States of America",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela (Bolivarian Republic of)",
  "VNM": "Viet Nam",
  "VGB": "Virgin Islands (British)",
  "VIR": "Virgin Islands (U.S.)",
  "WLF": "Wallis and Futuna",
  "ESH": "Western Sahara",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe",
};