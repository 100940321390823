import React from 'react';
import _ from 'lodash';

export default function Checkbox({ name, errors, label, hint, hintPlacement, required, ...rest }) {
  const error = errors ? errors[name] : false;
  return <div className={`flex flex-row items-start justify-start ${hint ? '' : 'mb3'}`}>
    <input
    type="checkbox"
    className={`ba border-box ${error ? 'b--dark-red' : 'b--moon-gray'} mt2 mr2`}
    id={name}
    name={name}
    required={required === true}
    style={{flexShrink: 0}}
    {...rest} />
    {label !== "" && <label htmlFor={name} className={`${error ? 'dark-red' : 'black'} f5 lh-copy`}>{label || _.startCase(name)}</label>}
    {hint && <div className={`w-100 mb3 f7 silver lh-copy ${hintPlacement || 'tl'}`}>{hint}</div>}
  </div>;
}
