//formatPhoneNumber formats a string of digits like +1 (123) 456-7890
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

//getDigits strips all non-digit characters from a phone number.
export function getDigits(phoneNumber) {
  let out;
  //strip country code
  if (phoneNumber.startsWith('+1')) {
    out = phoneNumber.slice(2);
  } else if (phoneNumber.startsWith('1')) {
    out = phoneNumber.slice(1);
  } else {
    out = phoneNumber;
  }

  //strip non-digits
  out = out.replace(/[^0-9]+/g, '');

  return out;
}

//validatePhoneNumber ensures a phone number has 10 digits in it
export function validatePhoneNumber(phoneNumber) {
  const noCC = phoneNumber.replace(/(^\+[0-1])/, '');
  const digits = noCC.replace(/[^0-9]+/g, '');
  if (digits.length !== 10) {
    return { 
      digits,
      isValid: false,
      error: new Error('Phone number must be 10 digits.'),
    };
  }

  return {
    digits,
    isValid: true,
    error: null,
  };
}