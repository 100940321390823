import React from 'react';
import _ from 'lodash';

export default function Pills({ display = _.identity, justify = 'start', marginRight = 'mr2', onSelect, selectedValue, values = [], wrap = false }) {

  const key = v => _.isObject(v) ? v.id : v;

  if (!values?.length) return null;

  return <div className={`flex flex-row items-center justify-${justify} overflow-scroll pb2 ${wrap ? 'flex-wrap' : ''}`}>
    {values.map((v, idx) =>
      <div key={key(v)} id={`pill-${key(v)}`} onClick={() => onSelect(v)} className={`pa2 ph3 f6 br-pill ba b--light-gray pointer ${wrap ? 'mb1' : ''} ${v === selectedValue ? 'bg-black white' : 'bg-white black'} ${idx < values.length - 1 ? marginRight : ''}`} style={{flexShrink: 0}}>
      {display(v)}
    </div>)}
  </div>
}