import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, SegmentController } from './ui';
import arrowLeftIcon from '../assets/img/arrow-left.svg';
import xIcon from '../assets/img/x.svg';

const parseReview = (review) => ({
  ...review,
  percentReturn: parseFloat(review.percentReturn),
  percentReturnAllTime: parseFloat(review.percentReturnAllTime),
});

export default function PortfolioReview({ client, id, onComplete, onDismiss }) {
  const [portfolioReview, setPortfolioReview] = useState(null);
  const [groupIdx, setGroupIdx] = useState(null);
  const [allTime, setAllTime] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const portflioReview = parseReview(await client.portfolioReviews.getOne({ id }));
        portflioReview.groupPortfolioReviews = portflioReview.groupPortfolioReviews.map((groupPortfolioReview) => {
          groupPortfolioReview.positionReviews = groupPortfolioReview.positionReviews.map(parseReview);
          return parseReview(groupPortfolioReview);
        });
        setPortfolioReview(portflioReview);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id]);

  const onChangeSegment = (segment) => {
    setAllTime(segment === 'allTime');
  }

  const onPrevious = () => {
    if (groupIdx === 0) {
      setGroupIdx(null);
    } else {
      setGroupIdx(groupIdx-1);
    }
  }

  const onNext = () => {
    if (groupIdx === null) {
      setGroupIdx(0);
    } else if (groupIdx === portfolioReview.groupPortfolioReviews.length - 1) {
      onComplete(portfolioReview);
    } else {
      setGroupIdx(groupIdx+1);
    }
  }

  if (!portfolioReview) return null;

  const getPercent = review => allTime ? review.percentReturnAllTime : review.percentReturn;
  const getPrettyPercent = (review) => {
    const percent = getPercent(review);
    return `${percent > 0 ? '+' : ''}${(percent * 100).toFixed(1)}%`;
  }

  const groupPortfolioReview = groupIdx === null ? null : portfolioReview.groupPortfolioReviews[groupIdx];

  const startAt = moment.utc(moment.unix(portfolioReview.startAt));
  const maxPercentReturn = (() => {
    const reviews = groupIdx === null ? portfolioReview.groupPortfolioReviews : groupPortfolioReview.positionReviews;
    return reviews.reduce((result, r) => {
      const val = Math.abs(getPercent(r) * 100);
      return result > val ? result : val;
    }, -100); 
  })();

  const Bar = ({ name, percentReturn }) => {
    const size = (Math.abs(percentReturn / maxPercentReturn) * 100).toFixed(2);
    const bg = (() => {
      if (percentReturn > 0) return { background: `linear-gradient(90deg, white 0%, white 50%, transparent 1px, #70f2f8 calc(50% + 5px), #70f2f8 100%)` };
      if (percentReturn < 0) return { background: `linear-gradient(90deg, #ff9900 0%, #ff9900 50%, transparent 1px, white calc(50% + 5px), white 100%)` };
      return {};
    })();

    return <div className="flex flex-row items-center justify-center center nowrap br3 tc f5 bold dark-gray" style={{ ...bg, height: 40, width: `${size}%`}}>
      {name}
    </div>
  }

  const sortReviews = (reviews) => {
    return reviews.sort((a, b) => {
      if (allTime) return parseFloat(a.percentReturnAllTime) > parseFloat(b.percentReturnAllTime) ? -1 : 1;
      return parseFloat(a.percentReturn) > parseFloat(b.percentReturn) ? -1 : 1;
    });
  }

  return <div className="w-100 h-100 center" style={{maxWidth: 700}}>
    <div className="w-100 h-100 flex flex-column">
      <div className="flex flex-row items-center justify-between">
        {groupIdx === null && <div style={{width: 14}}></div>}
        {groupIdx !== null && <img src={arrowLeftIcon} onClick={onPrevious} style={{width: 14}} />}
        <div className="f3 bold tc">
          {groupPortfolioReview ? groupPortfolioReview.group.name : 'Monthly Review'}
        </div>
        <img src={xIcon} onClick={onDismiss} style={{width: 14}} />
      </div>
      <div className="f6 mt2 mb3 gray tc">{portfolioReview.title}</div>
      <SegmentController
        defaultSegment={1}
        onChange={onChangeSegment}
        segments={['allTime', portfolioReview.title]}
      />
      {groupIdx === null && <>
        <div className="mt4 f3 bold tc">
          {getPrettyPercent(portfolioReview)}
        </div>
        {!allTime && <div className="f6 mt1 gray tc">in {startAt.format('MMMM')}</div>}
        {allTime && <div className="f6 mt1 gray tc">All time</div>}
        <div className="relative tc overflow-scroll z-1" style={{marginTop: 24}}>
          <div className="absolute top-0 br b--light-gray b--dashed" style={{borderWidth: 1, height: (64 * portfolioReview.groupPortfolioReviews.length - 24), width: 0, left: '50%'}}></div>
          {sortReviews(portfolioReview.groupPortfolioReviews).map((grp) =>
            <div key={`group-portfolio-review-${grp.id}`} className="flex flex-row items-center justify-between relative z-2" style={{marginBottom: 24}}>
              <div className="w-80 center">
                <Bar name={grp.group.name} percentReturn={getPercent(grp) * 100} />
              </div>
              <div className={`absolute right-0 mid-gray bold ${getPercent(grp) < 0 ? '' : 'right-0'}`}>
                {getPrettyPercent(grp)}
              </div>
            </div>)}
        </div>
      </>}
      {groupIdx !== null && <>
        <div className="mt4 f3 bold tc">
          {getPrettyPercent(groupPortfolioReview)}
        </div>
        {!allTime && <div className="f6 mt1 gray tc">in {startAt.format('MMMM')}</div>}
        {allTime && <div className="f6 mt1 gray tc">All time</div>}
        <div className="relative tc overflow-scroll z-1" style={{marginTop: 24}}>
          <div className="absolute top-0 br b--light-gray b--dashed" style={{borderWidth: 1, height: (64 * groupPortfolioReview.positionReviews.length - 24), width: 0, left: '50%'}}></div>
          {sortReviews(groupPortfolioReview.positionReviews).map((pr) =>
            <div key={`portfolio-review-${pr.id}`} className="flex flex-row items-center justify-between relative" style={{marginBottom: 24}}>
              <div className="w-80 center">
                <Bar name={pr.ticker} percentReturn={(getPercent(pr) * 100)} />
              </div>
              <div className={`absolute right-0 mid-gray bold ${getPercent(pr) < 0 ? '' : 'right-0'}`}>{getPrettyPercent(pr)}</div>
            </div>)}
        </div>
      </>}
      <div className="mt3">
        <Button width="w-100" onClick={onNext}>
          {groupIdx === portfolioReview.groupPortfolioReviews.length - 1 ? 'Done' : 'Next'}
        </Button>
      </div>
    </div>
  </div>;
}