import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from './partials/loading';
import View from './partials/template';

export default function Template({ client }) {
  const { id } = useParams();
  const history = useHistory();
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setTemplate(await client.templates.getOne({ id }));
      } catch (e) {
        console.error(e);
        history.push(`/`);
      }
    })();
  }, [id]);

  const onClone = () => history.push(`/groups/new?templateId=${template.id}`);

  if (!template) return <Loading />;
  return <View client={client} template={template} onBack={history.goBack} onClone={onClone} signUpGate />;
}