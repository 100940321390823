import React from 'react';
import { ROUTER } from '../../../utils';

export default function Link({ children, className, href, onClick, ...rest }) {
  return <a className={className || 'no-underline black hover-silver'} href={href} onClick={(e) => {
  	if (onClick) {
  		onClick(e);
  	}
    if (href.startsWith('/')) {
      e.preventDefault();
      e.stopPropagation();
      ROUTER.push(href);
    }
  }} {...rest}>{children}</a>;
}