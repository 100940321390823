import React from 'react';
import { Bar } from 'react-chartjs-2';
import BubbleNumber from './bubble-number';
import { MONEY } from '../../../utils';

export default function ReturnChart({ annualReturn = 0.05, monthlyInvestment = 20, numYears = 20 }) {
  const annualInvestment = monthlyInvestment * 12;
  const costBases = [];
  const marketValues = [];
  let marketValue = 0;
  for (let i = 1; i <= numYears; i++) {
    const costBasis = annualInvestment * i;
    costBases.push(costBasis);
    marketValues.push((marketValue * (1 + annualReturn)) + (annualInvestment * (1 + annualReturn/2)));
    marketValue = marketValues[marketValues.length - 1];
  }

  const options = {
    legend: {
      display: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    responsive: true,
  }

  const data = {
    labels: Array.from({ length: numYears }, (v, i) => i + 1),
    datasets: [
      {
        label: 'Your Contribution',
        data: costBases,
        backgroundColor: '#ef5da8',
        borderWidth: 0,
      },
      {
        label: 'Market Value',
        data: marketValues,
        backgroundColor: '#4fffb0',
        borderWidth: 0,
      },
    ],
  };

  return <div>
    <div>
      <div className="f6 gray mb1 mt tl">Simulated investment</div>
      <div className="flex flex-row w-100 mb1">
        <BubbleNumber
          color="cyan"
          number={`${MONEY.formattedDollars(monthlyInvestment, { wholeDollar: true })}`}
          subtitle="per month"
          width="w-50"
        />
        <BubbleNumber
          color="gold"
          number={`${(annualReturn * 100).toFixed(2)}%`}
          subtitle="annual return"
          width="w-50"
        />
      </div>
      <div className="flex flex-row w-100 mb1">
        <BubbleNumber
          color="pink"
          number={`${MONEY.formattedDollars(costBases[costBases.length - 1], { wholeDollar: true })}`}
          subtitle="total investment"
          width="w-50"
        />
        <BubbleNumber
          color="bright-mint"
          number={`${MONEY.formattedDollars(marketValues[marketValues.length - 1], { wholeDollar: true })}`}
          subtitle="projected value"
          width="w-50"
        />
      </div>
    </div>

    <div className="mt4">
      <Bar data={data} options={options} />
    </div>
  </div>;
}