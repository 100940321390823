import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import Nav from './partials/nav';
import TemplateCard from './partials/template-card';
import { Pills } from './partials/ui';
import { QUERY, S3 } from '../utils';
import info from './assets/img/info.svg';

const Filter  = {
  All: 'all',
  Popular: `popular`,
  Top: `top`,
  New: `new`,
  Stable: `stable`,
  Moderate: `moderate`,
  Aggressive: `aggressive`,
};

export default function Strategies({ client }) {
  const history = useHistory();
  const { filter: filterQuery = Filter.All } = QUERY.toObject(useLocation().search);
  const [filter, setFilter] = useState(filterQuery);
  const [groups, setGroups] = useState(null);
  const [simulations, setSimulations] = useState({});
  const [templates, setTemplates] = useState(null);

  useEffect(() => {
    (async () => {
      const templates = await client.templates.get();
      setTemplates(templates.filter(t => !t.isHidden && t.templateCompanies?.length > 0));
      setGroups(await client.groups.getMine());
    })();
  }, []);

  useEffect(() => {
    if (!templates) return;
    templates.forEach(async (template) => {
      const { bucket, path } = await client.simulations.get({ templateId: template.id });
      const meta = await S3.getMeta({ bucket, path });
      setSimulations((prevSimulations) => ({
        ...prevSimulations,
        [template.uuid]: meta,
      }));
    });
  }, [templates]);

  useEffect(() => {
    switch (filter) {
      case Filter.All:
        history.replace('/strategies');
        break;
      default:
        history.replace(`/strategies?filter=${filter.toLowerCase()}`);
    }
  }, [filter]);

  return <div>
    <Nav client={client} title="Strategies" />
    <div className="w-100 tc center ph ph0-ns pt6-ns mt4" style={{maxWidth: 500, paddingBottom: 80}}>
      <div className="mt3">
        <Pills values={Object.values(Filter)} selectedValue={filter} onSelect={setFilter} display={_.startCase} />
      </div>

      <div className="mt flex flex-row items-center justify-start bg-near-white f6 br4 pa3 mb3">
        <img src={info} style={{width: 16}} className="mr2"/>
        <div className="tl">
        These Strategies are created and maintained by Share.
        </div>
      </div>

      <div className="mt">
        <Templates filter={filter} groups={groups} templates={templates} simulations={simulations} />
      </div>
    </div>
  </div>;
}

function Templates({ filter, groups, simulations, templates }) {
  const history = useHistory();

  if (!templates) return null;

  const limit = filter === Filter.All ? Number.MAX_SAFE_INTEGER : 10;

  const simKey = (() => {
    switch (filter) {
      case Filter.Top:
        return `5y-percent-return`;
      default:
        return `5y-percent-return`;
    }
  })();

  const getSortKey = (template) => {
    switch (filter) {
      case Filter.All:
        return template.name;
      case Filter.Popular:
        return template.cloneCount;
      case Filter.New:
        return template.createdAt;
      case Filter.Stable:
        return parseFloat(template.beta) * -1;
      case Filter.Moderate:
          return parseFloat(template.beta) * -1;
      case Filter.Aggressive:
        return parseFloat(template.beta);
      default:
        if (!simulations.hasOwnProperty(template.uuid)) return -1 * Number.MAX_SAFE_INTEGER;
        return parseFloat(simulations[template.uuid][simKey]);
    }
  }

  const applyFilter = ({ template }) => {
    switch (filter) {
      case Filter.All:
        return true;
      case Filter.Popular:
        return template.cloneCount > 0;
      case Filter.New:
        return (new Date()).getTime() - template.createdAt * 1000 < 1000 * 60 * 60 * 24 * 90;
      case Filter.Stable:
        return parseFloat(template.beta) >= 0 && parseFloat(template.beta) <= 0.8;
      case Filter.Moderate:
        return parseFloat(template.beta) > 0.8 && parseFloat(template.beta) <= 1.2;
      case Filter.Aggressive:
        return parseFloat(template.beta) > 1.2;
      default:
        return simulations.hasOwnProperty(template.uuid);
    }
  }

  let toDisplay = [];
  templates?.forEach((template) => {
    toDisplay.push({
      isTemplate: true,
      key: template.uuid,
      sortKey: getSortKey(template),
      template,
    });
  })

  toDisplay = toDisplay.filter(applyFilter).sort((a, b) => {
    const direction = filter === Filter.All ? 1 : -1;
    return direction * (a.sortKey < b.sortKey ? -1 : 1);
  });

  return <div>
    {toDisplay.slice(0, limit).map(({ key, template }) =>
      <React.Fragment key={key}>
        <TemplateCard
          group={groups?.find(g => g.templateUuid === template.uuid)}
          template={template}
          simulation={simulations[key]}
          simulationKey={simKey}
          onSelectGroup={(group) => history.push(`/groups/${group.id}`)}
          onSelectTemplate={() => history.push(`/templates/${template.uuid}`)}
        />
      </React.Fragment>)}
  </div>
}
