import React, { useEffect, useRef, useState } from 'react';
import animationData from './loader-blue.json';

export default function Loader({ className, style }) {
  const ref = useRef(null);
  const [lottie, setLottie] = useState(null);

  useEffect(() => {
    let cancelled = false;
    (async () => {
      const l = await import('lottie-web');
      if (cancelled) return;
      setLottie(l);
    })();

    return () => cancelled = true;
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        animationData,
        container: ref.current,
        loop: true,
        autoplay: true,
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div className={className} style={style}>
    <div ref={ref}></div>
  </div>;
}