import React from 'react';
import Button from './button';

export default function DangerButton({ children, ...rest }) {
  const props = {
    ...rest,
    color: 'red',
  };

  return <Button {...props}>{children}</Button>;
}