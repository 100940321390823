import React, { useState } from 'react';
import AssetLogo from './asset-logo';
import { Button, Input } from './ui';
import logo from '../assets/img/logo.svg';

export default function SignUpModal({ client, companies, onDismiss, simulation, template }) {
  const [invitation, setInvitation] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      const phoneNumber = form.get('phoneNumber').replace(/[\s-()]+/g, '');
      if (phoneNumber.length < 10) {
        throw new Error(`Phone number must be at least 10 digits`);
      }

      setIsSending(true);
      setInvitation(await client.invitations.sendWithCode({
        code: simulation.code,
        phoneNumber,
        simulationId: simulation.id,
      }));

      if (process.env.ANALYTICS === '1') {
        analytics.track('Text Me The App');
      }
    } catch(e) {
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  if (template) {
    companies = template.templateCompanies.map((tc => tc.company));
  }

  const tenCompanies = companies?.slice(0, 10);
  const notShownCount = (companies?.length || 0) - 10;

  return <div className="tc">
    <div>
      <a href="https://www.tryshare.app" target="_blank">
        <img src={logo} style={{width: 100}}/>
      </a>
    </div>
    <div className="f4 bold mt3 mb2">
      {template && <>Invest in {template.name}</>}
      {!template && <>Sign up to get started.</>}
    </div>
    <div className="f5 mid-gray lh-copy">
      The simplest way to invest every week.
    </div>
    {tenCompanies?.length > 0 && <div className="flex flex-row items-center justify-center-ns mv4 overflow-scroll">
      {tenCompanies.map((c) => <div key={c.id} className="ba br4 b--light-gray pa2 mr3" style={{flexShrink: 0}}>
        <AssetLogo border={false} company={c} size={40} />
        <div className="tc bold f6 mt1 mb2">{c.ticker}</div>
      </div>)}
      {notShownCount > 0 && <div className="pa2 f4 bold">
        +{notShownCount}
      </div>}
    </div>}

    {!invitation && <form className="mv2 center mw5-ns" onSubmit={onSubmit}>
      <div>
        <Input type="tel" name="phoneNumber" label="" />
      </div>
      <div className="mb2 f7 red">{error}</div>
      <Button outline="true" width="w-100" isDisabled={isSending}>text me the app</Button>
      <div className="mv3 underline">or</div>
      <Button outline="true" width="w-100" href="https://www.tryshare.app">learn more</Button>
    </form>}
    {invitation && <div className="mv2 center tc" style={{maxWidth: 400}}>
      <div className="f5 lh-copy mb3">Got it! We've sent a sign up link to {invitation.phoneNumber}.</div>
      <Button className="mb2" outline={true} width="w-100" onClick={() => setInvitation(null)}>send another</Button>  
      <Button outline="none" width="w-100" onClick={onDismiss}>done</Button> 
    </div>}
  </div>;
}