import React from 'react';
import { useHistory } from 'react-router-dom';
import arrowLeft from '../assets/img/arrow-left.svg';

export default function BackButton({ root }) {
  const history = useHistory();
  const canGoBack = history.length > 1 || root;
  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace(root);
    }
  }
  return canGoBack ? <img src={arrowLeft} className="pointer" onClick={goBack} /> : null;
}