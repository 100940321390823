import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Button, FullscreenLoader } from './partials/ui';
import { QUERY } from '../utils';
import Nav from './partials/nav';
import BackButton from './partials/button-back';
import airplane from './assets/img/airplane.svg';
import xIcon from './assets/img/x.svg';

export default function Invite({ client, onDismiss }) {
  const history = useHistory();
  const { groupId } = useParams();
  const { returnTo } = QUERY.toObject(useLocation().search);
  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [didCopy, setDidCopy] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
        setGroup(await client.groups.getOne({ id: groupId }));
      } catch (e) {
        setError(e.message);
      }
    })();
  }, []);

  const LeftButton = useCallback(() => {
    if (onDismiss) return <img src={xIcon} onClick={onDismiss} />;
    return <BackButton />;
  }, [onDismiss]);

  if (!user || !group) return <FullscreenLoader />;

  const copyMessage = () => {
    navigator.clipboard.writeText(`Hey! I'm inviting you to join the ${group.name} strategy on Share, and help choose what to invest in together. Accept my invitation here: https://app.tryshare.app/join/${group.code}/sms`);
    setDidCopy('message');
  }

  const copyJoinLink = () => {
    navigator.clipboard.writeText(`https://app.tryshare.app/join/${group.code}/sms`);
    setDidCopy('joinLink');
  }

  return <div className="w-100 min-vh-100" style={{paddingBottom: returnTo ? 80 : 0}}>
    <Nav client={client} leftButton={LeftButton} footer={false} />
    <div className="w-100 tc pt5 pt6-ns center-ns ph" style={{maxWidth: 500}}>
        <div className="f5 bold">{group.name}</div>
        <img className="mv3" src={airplane} />
        <div className="f3 bold mb4">Invite others to join.</div>
        <div className="bg-near-white br3 pa3 tl f6 lh-copy" onClick={copyMessage}>
          <div className="bold tl mb1">Invite message</div>
          Hey! I'm inviting you to join the {group.name} strategy on Share, and help choose what to invest in together. Accept my invitation here: https://app.tryshare.app/join/{group.code}/sms
          <div className="mt1 tr bold blue">{didCopy === 'message' ? 'Copied' : 'Tap to copy'}</div>
        </div>
        <div className="mt3 bg-near-white br3 pa3 tl f6 lh-copy" onClick={copyJoinLink}>
          <div className="bold tl mb1">Invite link</div>
          https://app.tryshare.app/join/{group.code}/sms
          <div className="mt1 tr bold blue">{didCopy === 'joinLink' ? 'Copied' : 'Tap to copy'}</div>
        </div>
      </div>

    {returnTo && <div className="fixed w-100 bottom-0 bg-white pa3">
      <Button width="w-100" onClick={() => history.replace(returnTo)}>Done</Button>
    </div>}
  </div>;
}