import React, { useState } from 'react';

const fontSize = (ticker, size) => {
  return size / (3 + Math.max(ticker.length-3, 0));
}

export default function AssetLogo({ border = true, company, onClick = () => {}, ticker = company.ticker, size = 60, width }) {
  const [didFail, setDidFail] = useState(company?.iconUrl === '');
  const onError = () => setDidFail(true);

  const style = {};
  if (!width) { //width overrides size
    style.width = size;
    style.height = size;
  }

  return <>
    {!didFail && <img src={company?.iconUrl || `${process.env.API_HOST}/companies/${ticker}/icon`} style={style} className={`br3 ${border ? 'ba' : 'bn'} b--light-gray ${width}`} onError={onError} onClick={onClick} />}
    {didFail && <div className={`bg-light-blue black flex items-center justify-center ttu f5 bold ${border ? 'ba' : 'bn'} b--light-gray br3 truncate`} style={{boxSizing: 'content-box', fontSize: fontSize(ticker, size), width: size, height: size}} onClick={onClick}>{ticker}</div>}
  </>;
}