import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, DangerButton, FullscreenLoader, Input } from './partials/ui';
import Nav from './partials/nav';
import BackButton from './partials/button-back';

export default function GroupInfo({ client }) {
  const history = useHistory();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [user, setUser] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    (async () => {
      try {
        setUser(await client.users.getMe());
        const group = await client.groups.getOne({ id: groupId });
        if (!group.membership) {
          throw new Error(`no membership`);
        }
        setGroup(group);
        setIsFetching(false);
      } catch (e) {
        console.error(e);
        history.push(`/`);
      }
    })();
  }, []);

  if (isFetching) return <FullscreenLoader />;

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const name = form.get('name').trim();
    const description = form.get('description').trim();
    try {
      setIsSending(true);
      setGroup(await client.groups.update({
        groupId: group.id,
        name,
        description,
      }));
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  }

  const onLeaveGroup = async () => {
    if (isLeaving) return;
    try {
      setIsLeaving(true);
      await client.groups.leave({ groupId, userId: user.id });
      history.replace(`/`);
    } catch (e) {
      console.error(e);
    } 
    finally {
      setIsLeaving(false);
    }
  }

  const confirmLeaveGroup = () => {
    if (confirm('Are you sure? This will move all your positions in this strategy to your individual portfolio, and you cannot undo.')) {
      onLeaveGroup();
    }
  }

  return <div className="w-100 min-vh-100">
    <Nav client={client} leftButton={BackButton} title={group.name} footer={false} />
    <div className="w-100 flex align-center justify-around pt6-ns pt3" style={{paddingBottom: 80}}>
      <div className="tl w-100" style={{maxWidth: 500, paddingLeft: 24, paddingRight: 24}}>
        <form onSubmit={onSubmit}>
          <Input autoComplete="off" name="name" defaultValue={group.name} required /> 
          <Input autoComplete="off" name="description" type="textarea" defaultValue={group.description} required={Boolean(group.description)} />
          {error && <div className="mb2 tr w-100 red">{error}</div>}
          <Button width="w-100" isDisabled={isSending}>Update</Button>
        </form>
        {/* {group.ownerId !== user.id && <div className="fixed w-100" style={{bottom: 20, left: 0, paddingTop: 24, paddingLeft: 24, paddingRight: 24}}>
          <DangerButton width="w-100" onClick={confirmLeaveGroup}>Leave Strategy</DangerButton>
        </div>} */}
      </div>
    </div>
  </div>;
}