import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { useHistory, useParams } from 'react-router-dom';
import NotFound from './not-found'
import Nav from './partials/nav';
import { BigNumber, BottomCard, BubbleNumber, Button, FullscreenLoader, Input, TinyButton } from './partials/ui';
import { MONEY } from '../utils';
import heart from './assets/img/heart.svg';
import heartFilled from './assets/img/heart-filled.svg';
import BackButton from './partials/button-back';

export default function Company({ client }) {
  const history = useHistory();
  const { ticker } = useParams();

  //company state
  const [company, setCompany] = useState(null);
  const [companyError, setCompanyError] = useState(false);
  const [details, setDetails] = useState(null);
  const [dividend, setDividend] = useState(null);

  //user state
  const isAnonymous = !client.idToken;
  const [user, setUser] = useState(null);
  
  //portfolio state
  const [duration, setDuration] = useState('1m');
  const [stock, setStock] = useState(null);
  const [position, setPosition] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  //proposal state
  const [suggestable, setSuggestable] = useState(null);
  const [isSuggesting, setIsSuggesting] = useState(false);

  useEffect(() => {
    if (isAnonymous) return;
    (async () => {
      setUser(await client.users.getMe());
    })();
  }, [isAnonymous]);

  useEffect(() => {
    if (company && ticker !== company.ticker) {
      window.scrollTo(0, 0);
      setCompany(null);
      setCompanyError(false);
      setStock(null);
      setPosition(null);
      setDuration('1m');
      setIsExpanded(false);
    }
  }, [ticker]);

  useEffect(() => {
    (async () => {
      try {
        const company = await client.companies.get(ticker);
        if (company) {
          setCompany(company);
          setStock(await client.stocks.get(company.ticker));
          try {
            setDividend(await client.companies.getDividend({ ticker }));
          } catch {}
        }
      } catch (e) {
        console.error(e);
        setCompanyError(true);
      }
    })();
  }, [ticker]);

  useEffect(() => {
    (async () => {
      try {
        setPosition(await client.positions.getOne({ groupId: -1, ticker }));
      } catch {
        setPosition(null);
      }
    })();
  }, [ticker]);

  useEffect(() => {
    if (!stock) return;
    (async () => {
      try {
        let chart = await client.stocks.chart(company.ticker, duration);
        chart = {
          ...chart,
          data: chart.data.filter(d => d.close),
        };
        setStock({ ...stock, chart });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [duration]);

  useEffect(() => {
    if (!user) return;
    (async () => {
      setSuggestable(await client.companies.getIsSuggestable({ ticker }));
    })();
  }, [user, ticker]);

  const RightButton = useCallback(() => {
    return <WatchButton client={client} company={company} user={user} />;
  }, [company, user]);

  if (company === null) {
    if (!companyError) return <FullscreenLoader />;
    if (companyError) return <NotFound errorMessage="Company not found" showHomeButton={false} />;
  }

  if (!stock) return <FullscreenLoader />;

  const onAddToStrategy = () => {
    if (!suggestable.groups) {
      history.push(`/groups/new`);
    } else {
      setIsSuggesting(true);
    }
  }

  const hasSoloPosition = Boolean(parseFloat(position?.sellableQuantity));

  const chart = (() => {
    const data = [];
    stock.chart.data.forEach((d) => {
      if (d.hasOwnProperty('open') && d.open > 0) {
        data.push({
          label: `${d.label} open`,
          price: d.open
        });
      }

      if (d.hasOwnProperty('close') && d.close > 0) {
        data.push({
          label: `${d.label} close`,
          price: d.close,
        });
      }
    })

    if (!stock.quote.isUSMarketOpen && stock.quote.extendedPrice) {
      data.push({
        label: 'Extended price',
        price: stock.quote.extendedPrice,
      });
    }

    return {
      ...stock.chart,
      data,
    };
  })();

  const background = (() => {
    const isUp = stock.chart ? stock.chart.change >= 0 : stock.quote.change >= 0;
    return isUp ? `cyan` : `orange`;
  })();

  return <div className="relative overflow-x-hidden noselect">
    <Nav
    client={client}
    leftButton={BackButton}
    rightButton={RightButton} 
    title={company.displayName} />
    <div className="w-100 tc pt6-ns" style={{paddingBottom: 80}}>
      <div className={`bg-${background} black ma mb0 ph center-ns tl pv4 br5`} style={{maxWidth: 700}}>
        {company.listedOn && <div className="f6 ttu w-100 tc">{company.listedOn}: {company.ticker}</div>}
        {Boolean(stock) && <StockQuote stock={stock} duration={stock.chart.duration} />}
        {Boolean(chart) && <StockChart background={background} chart={chart} duration={duration} edgeToEdge={false} onChangeDuration={setDuration} />}
      </div>

      {/* {suggestable?.isSuggestable && <div className="mt3 mh center-ns" style={{maxWidth: 700}}>
        <TinyButton width={hasSoloPosition ? `w-50` : `w-100`} onClick={onAddToStrategy}>
          add to strategy
        </TinyButton>
        {hasSoloPosition && <TinyButton width="w-50 nl1" onClick={() => history.push(`/orders/new/sell/${company.ticker}`)}>
          sell
        </TinyButton>}
      </div>} */}

      <div className="tl ma center-ns" style={{maxWidth: 700}}>
        {position && <div className="mv2">
          <Position position={position} setDetails={setDetails} />
        </div>}
        <div className="mv2">
          {Boolean(stock?.quote.marketCap) && <div>
            <div className="f6 gray mb1">Stats</div>
            <div className="flex flex-row mb1">
              <BubbleNumber
                color="hot-pink"
                number={MONEY.formattedMarketCap(stock.quote.marketCap)}
                subtitle="market cap"
                onClick={() => setDetails({ title: 'Market Capitalization', text: `If you bought all the shares outstanding at the current market price, you'd pay this much. Market cap expresses how much the market currently thinks this company is worth.` })}
                width="w-50"
              />
              <BubbleNumber
                color="hot-pink"
                number={stock.quote.peRatio.toFixed(2).toString()}
                subtitle="p/e ratio"
                onClick={() => setDetails({ title: 'Price-to-Earnings Ratio', text: `Price per share divided by earnings (or profit) per share. If one share of this stock was a business, it would take this many years to get your money back. A negative number means the company was not profitable when it last reported earnings.` })}
                width="w-50"
              />
            </div>
            <div className="flex flex-row">
              <BubbleNumber
                color="gold"
                number={MONEY.formattedDollars(stock.quote.week52High)}
                subtitle="52-wk high"
                onClick={() => setDetails({ title: '52-Week High', text: `The highest price this stock has traded at in the past year, based on the market closing price.` })}
                width="w-50"
              />
              <BubbleNumber
                color="gold"
                number={MONEY.formattedDollars(stock.quote.week52Low)}
                subtitle="52-wk low"
                onClick={() => setDetails({ title: '52-Week Low', text: `The lowest price this stock has traded at in the past year, based on the market closing price.` })}
                width="w-50"
              /> 
            </div>
          </div>}
        </div>
        {Boolean(dividend) && <div className="mv2">
          <Dividend dividend={dividend} position={position} quote={stock?.quote} setDetails={setDetails} />
        </div>}
        <div className="mv2">
          {Boolean(company.shortDescription) && <>
            <div className="f6 gray mb1">About</div>
            <div className="f5 pa3 br5 bg-light-gray lh-copy measure mw-100-ns">
              {!isExpanded && company.shortDescription}
              {isExpanded && `${company.shortDescription} ${company.longDescription}`}
              {company.longDescription != '' && <a className="underline pointer ml2" onClick={() => setIsExpanded(!isExpanded)}>Read {isExpanded ? 'less' : 'more'}</a>}
            </div>
          </>}
        </div>
      </div>
      {Boolean(details) && <BottomCard dim>
        <div className="center-ns" style={{maxWidth: 500}}>
          <div className="f4 bold">{details.title}</div>
          <div className="f5 lh-copy mv3">{details.text}</div>
          <Button outline={true} width="w-100" onClick={() => setDetails(null)}>dismiss</Button>
        </div>
      </BottomCard>}
      {!details && isSuggesting && <BottomCard dim>
        <Propose client={client} company={company} onDismiss={() => setIsSuggesting(false)} suggestable={suggestable} />
      </BottomCard>}
    </div>
  </div>
}

function WatchButton({ client, company, user }) {
  const [watch, setWatch] = useState(null);

  const onToggleWatchlist = async () => {
    try {
      if (!watch) {
        setWatch(await client.watchlist.addCompany({ companyUuid: company.uuid })); 
      } else {
        await client.watchlist.removeCompany({ companyId: company.id });
        setWatch(null);
      }
    } catch {}
  };

  useEffect(() => {
    if (!company) return;
    (async () => {
      try {
        setWatch(await client.watchlist.getCompany({ companyId: company.id })); 
      } catch {}
    })();
  }, [company]);

  if (!company || !user) return null;
  return <img src={watch ? heartFilled : heart} onClick={onToggleWatchlist} />;
}

function Propose({ client, company, onDismiss, suggestable }) {
  const history = useHistory();
  const [proposalGroup, setProposalGroup] = useState(null);
  const [hasProposed, setHasProposed] = useState(false);

  const { groups } = suggestable;
  const groupCompanies = _.keyBy(suggestable.groupCompanies, 'groupId');
  const proposals = _.keyBy(suggestable.proposals, 'groupId');

  const onSelectGroup = (e) => {
    const { currentTarget: { dataset: { groupId } } } = e;
    if (proposals[groupId] || groupCompanies[groupId]) {
      //redirect to group to change the rating
      history.push(`/groups/${groupId}`);
      return;
    }

    const group = groups.find((g) => (g.id == groupId));
    if (group.membership.isPaused) {
      history.push(`/investments`);
      return;
    }

    setProposalGroup(group);
  }

  const isSolo = proposalGroup?.numMembers === 1;

  const onPropose = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSolo) {
      try {
        await client.groupCompanies.create({
          companyUuid: company.uuid,
          groupId: proposalGroup.id,
          type: 'buy',
        });
        setHasProposed(true);
      } catch (err) {
        console.error(err);
      }
      return;
    }

    const form = new FormData(e.target);
    try {
      await client.proposals.create({
        comment: form.get('comment'),
        groupId: proposalGroup.id,
        ticker: company.ticker,
        type: 'buy',
        weight: 1,
      });
      setHasProposed(true);
    } catch (err) {
      console.error(err);
    }
  }

  return <div className="flex flex-column center-ns" style={{maxWidth: 500}}>
    {!proposalGroup && <div className="f4 bold mv3">Choose a strategy</div>}
    {!proposalGroup && groups.map((g) =>
      <ProposalGroup
        key={g.id}
        group={g}
        groupCompany={groupCompanies[g.id]}
        hasProposal={proposals[g.id]}
        onSelectGroup={onSelectGroup}
      />
    )}
    {Boolean(proposalGroup) && <>
      <div>
        <div className="mv3 bold tc">{isSolo ? 'Add to' : 'Propose to'} {proposalGroup.name}</div>
        {!hasProposed && <form onSubmit={onPropose}>
          {!isSolo && <Input
          type="textarea"
          name="comment"
          label=""
          placeholder={`Why do you like ${company.ticker}?`} />}
          <Button width="w-100" data-value="1" data-ga-event="Suggest">{isSolo ? 'Add' : 'Propose Buy'}</Button>
        </form>}
        {hasProposed && <>
          <div className="mv3 tc">{company.ticker} has been {isSolo ? 'added' : 'proposed'}.</div>
          <Button width="w-100" outline={true} onClick={onDismiss} data-action="cancel">Done</Button>
        </>}
      </div>
    </>}
    {!hasProposed && <Button outline="none" onClick={onDismiss} data-action="cancel">Cancel</Button>}
  </div>;
}

function ProposalGroup({ group, groupCompany, proposal, onSelectGroup }) {
  const onClick = (e) => {
    onSelectGroup(e);
  }

  const isDisabled = proposal || groupCompany || group.membership.isPaused;

  return <div key={`group-${group.id}`} className={`pv3 bb b--moon-gray ${proposal ? 'o-50' : ''}`} onClick={onClick} data-group-id={group.id}>
    <div className="mt1 tl f6 dark-gray">
      <TinyButton isDisabled={isDisabled} ttl={false} width="w-100">
        {group.name}
      </TinyButton>
      {isDisabled && <div className="tc mt2 silver">
        {Boolean(proposal) && <>
          {group.name} has a pending proposal already.
        </>}
        {!proposal && <>
          {Boolean(groupCompany) && <>
            {groupCompany.ticker} is in {group.name} already.
          </>} 
          {!groupCompany && <>
            {group.membership.isPaused && <>
              Your investment in {group.name} is paused. Unpause to add.
          </>}
          </>}
        </>}
      </div>}
    </div>
  </div>;
}

export function StockQuote({ stock, duration }) {
  const [mode, setMode] = useState('percent');
  if (!stock) return '';
  const durations = {
    '1d': 'Past day',
    '1w': 'Past week',
    '1m': 'Past month',
    '3m': 'Past 3 months',
    '6m': 'Past 6 months',
    '1y': 'Past year',
    '5y': 'Past 5 years',
  };
  const { chart, quote } = stock;
  const {
    change,
    changePercent,
    changePeriod,
    isUp
  } = (() => {
    const change = _.get(chart, 'change', quote.change);
    const changePercent = _.get(chart, 'changePercent', quote.changePercent);
    const changePeriod = chart ? durations[duration] : 'Today';
    const isUp = chart ? chart.change >= 0 : quote.change >= 0;
    return { change, changePercent, changePeriod, isUp };
  })();
  return <div className="flex flex-column">
    <div className="tc"><BigNumber>{MONEY.formattedDollars(quote.latestPrice)}</BigNumber></div>
    <div className="flex flex-row" onClick={() => setMode(mode === 'percent' ? 'dollars' : 'percent')}>
      {mode === 'dollars' && <div className={`${isUp ? 'bg-cyan' : 'bg-orange'} mt2 f5-ns f6 center`}>{isUp ? '+' : ''}{MONEY.formattedDollars(change)} {changePeriod}</div>}
      {mode === 'percent' && <div className={`${isUp ? 'bg-cyan' : 'bg-orange'} mt2 f5-ns f6 center`}>{isUp ? '+' : ''}{(changePercent * 100).toFixed(2)}% {changePeriod}</div>}
    </div>
  </div>;
}

const quantityFormatter = (quantity) => {
  if (quantity.length > 5) return parseFloat(quantity).toFixed(5);
  return quantity;
}

export function Position({ position, setDetails }) {
  const isUp = parseFloat(position.percentReturn) > 0;
  const color = isUp ? 'cyan' : 'orange';
  const prefix = isUp ? '+' : '';

  return <div>
    <div className="f6 gray mb1">Your Position</div>
    <div className="mb1">
      <BubbleNumber
        color={color}
        number={`${prefix}${(position.percentReturn * 100).toFixed(2)}%`}
        subtitle="return"
        size="big"
        onClick={() => setDetails({ title: 'Percent Return', text: `The all time return on your investment.` })} />
    </div>
    <div className="flex flex-row mb1">
      <BubbleNumber
        color={color}
        number={MONEY.formattedDollars(position.costBasis)}
        subtitle="invested"
        onClick={() => setDetails({ title: 'Cost Basis', text: `The total you paid for these shares, less any sales.` })}
        width="w-50"
      />
      <BubbleNumber
        color={color}
        number={MONEY.formattedDollars(position.marketValue)}
        subtitle="market value"
        onClick={() => setDetails({ title: 'Market Value', text: `The latest market price multiplied by the number of shares you own.` })}
        width="w-50"
      />     
    </div>
    <div className="flex flex-row mb1">
      <BubbleNumber
        color={color}
        number={quantityFormatter(position.quantity)}
        subtitle="shares"
        onClick={() => setDetails({ title: 'Number of Shares', text: `The total number of shares you own, across all strategies.` })}
        width="w-50"
      />
      <BubbleNumber
        color={color}
        number={MONEY.formattedDollars(position.averagePricePerShare)}
        subtitle="avg price"
        onClick={() => setDetails({ title: 'Average Price per Share', text: `The average price you paid for all shares you currently own.` })}
        width="w-50"
      />
    </div>
  </div>
}

function Dividend({ dividend, position, quote, setDetails }) {
  const dividendYield = (() => {
    let amount;
    switch (dividend.frequency) {
      case 'weekly':
        amount = dividend.amountPerShare * 52;
        break;
      case 'monthly':
        amount = dividend.amountPerShare * 12;
        break;
      case 'quarterly':
        amount = dividend.amountPerShare * 4;
        break;
      case 'semi-annual':
        amount = dividend.amountPerShare * 2;
        break;
      case 'annual':
        amount = dividend.amountPerShare;
      default:
        return 0;
    }
    return (amount / quote.latestPrice) * 100;
  })();

  if (dividendYield === 0) return null;

  return <div>
    <div className="f6 gray mb1">Dividend</div>
    {position && position.dividendReturn != '0' && <div className="mb1">
      <BubbleNumber
        color="bright-mint"
        number={MONEY.formattedDollars(position.dividendReturn)}
        subtitle="total paid"
        size="big"
        onClick={() => setDetails({ title: 'Total Paid', text: `The total amount of dividends you've received, across all strategies for the lifetime of this investment.` })}
        width="w-100"
      />
    </div>}
    <div className="flex flex-row mb1">
      <BubbleNumber
        color="bright-mint"
        number={MONEY.formattedDollars(dividend.amountPerShare)}
        subtitle={dividend.frequency}
        onClick={() => setDetails({ title: 'Dividend Payment', text: `The most recently declared dividend payment by the company, expressed as a per share amount. For every full share you own, you'll be paid this much on the specified frequency.` })}
        width="w-50"
      />
      <BubbleNumber
        color="bright-mint"
        number={`${dividendYield.toFixed(2)}%`}
        subtitle="annual yield"
        onClick={() => setDetails({ title: 'Dividend Yield', text: `The annual yield from dividends expressed as a percent of the current share price. For every $100 invested in ${dividend.ticker} today, you will receive ${MONEY.formattedDollars(dividendYield)} per year.` })}
        width="w-50"
      />
    </div>
  </div>
}

export function StockChart({ background = 'white', color = 'black', chart, data = chart.data, duration, durations = ['1d', '1m', '3m', '1y', '5y'], onChangeDuration, edgeToEdge = true }) {
  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    tooltips: {
      enabled: false,
    },
  };

  const borderWidth = (() => {
    if (data.length > 200) {
      return 1;
    }
    if (data.length > 100) {
      return 2;
    }
    return 3;
  })();

  const config = {
    labels: data.map(d => d.label),
    datasets: [{
        fill: false,
        lineTension: 0.3,
        backgroundColor: color,
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        borderWidth,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: '#000',
        pointHoverBorderColor: '#000',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: data.map(d => d.price),
      }
    ]
  };

  const { max, maxPosition, min, minPosition } = (() => {
    if (!data || data.length <= 1) return {};

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let maxIdx, minIdx;
    data.forEach(({ price }, idx) => {
      if (price < min) {
        min = price;
        minIdx = idx;
      }
      if (price > max) {
        max = price;
        maxIdx = idx;
      }
    });

    const bound = (x) => Math.max(Math.min(x, 90), 5);
    const maxPosition = `${bound(maxIdx / (data.length - 1) * 100)}%`;
    const minPosition = `${bound(minIdx / (data.length - 1) * 100)}%`;

    return {
      max: MONEY.formattedDollars(data[maxIdx].price),
      maxPosition,
      min: MONEY.formattedDollars(data[minIdx].price),
      minPosition,
    };
  })();

  const isLoading = chart?.duration !== duration;

  return <div className={`mt4 ${edgeToEdge ? 'nl4 ml0-ns nr4 mr0-ns' : ''} ${isLoading ? 'o-50' : ''}`}>
    {maxPosition && <div className="f6 near-black tl" style={{paddingLeft: maxPosition}}>
      {max}
    </div>}
    <Line data={config} options={options} />
    {minPosition && <div className="f6 near-black tl" style={{paddingLeft: minPosition}}>
      {min}
    </div>}
    {duration && <div className="flex flex-row items-center justify-between mt3 mh3">
      {durations.map((d) =>
        <div key={d} className={`pointer f6 ph3 pv2 bb bw2 ${duration === d ? `b--black` : `b--${background}`}`} onClick={() => onChangeDuration(d)}>{d}</div>
      )}
    </div>}
  </div>
}