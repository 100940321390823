import * as JSONAPI from '../jsonapi';

export function request({
  url,
  headers = {},
  method = 'GET',
  data,
  json = true,
}) {
  const u = new URL(url);
  const options = {
    headers,
    method,
  };

  if (data) {
    if (method === 'GET' || method === 'HEAD') {
      for (let key in data) {
        if (data[key] === undefined) continue;
        if (Array.isArray(data[key])) {
          data[key].forEach((element) => {
            u.searchParams.append(key, element);
          });
        } else {
          u.searchParams.append(key, data[key]);
        }
      }
    } else {
      if (json) {
        options.body = JSON.stringify(data);
      } else {
        options.body = data;
      }
    }
  }
  
  return window.fetch(u.toString(), options)
    .then((response) => {
      if (response.ok && response.status === 204) {
        return Promise.resolve({
          response,
          data: {},
        });
      }

      if (json) {
        return response.json().then((data) => {
          if (JSONAPI.isValid(data)) {
            try {
              return Promise.resolve({
                response,
                data: JSONAPI.parse(data),
              });
            } catch {
              //we couldn't parse the json, this is a problem
              //window.location.href = '/error';  
            }
          }
          //remove this once we get all into jsonapi
          return Promise.resolve({
            response,
            data,
          });
        })
      }
      
      return response;
    });
}

export function sseRequest(
  url,
  { onError, onMessage, onOpen },
) {
  if (!onError || !onMessage) {
    throw new Error('Must provide onError and onMessage callbacks');
  }

  const sse = new EventSource(url);

  if (onOpen) {
    sse.onopen = (e) => {
      onOpen(e);
    }
  }

  sse.onmessage = (e) => {
    if (e.data === 'Hello world') return;

    const message = JSON.parse(e.data);
    if (JSONAPI.isValid(message)) {
      const jsonapiObj = JSONAPI.parse(message);
      return onMessage(jsonapiObj, message, e);
    }

    onMessage(message, e);
  }

  sse.onerror = (e) => {
    onError(e);
  }

  return sse;
}