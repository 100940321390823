import React, { useState } from 'react';
import _ from 'lodash';
import Button from './button';

export default function ActionButtons({ actions, onSelectAction, title }) {
  const [isOpen, setIsOpen] = useState(false);

  return <div>
    {!isOpen && <Button width="w-100" onClick={() => setIsOpen(true)}>{title}</Button>}
    {isOpen && <>
      {actions.filter(_.identity).map((action) => <React.Fragment key={action.value}>
        <Button width="w-100" onClick={() => onSelectAction(action.value)} isDisabled={action.isDisabled}>{action.label}</Button>
        {action.hint && <div className="pt1 f7 tc silver">{action.hint}</div>}
        <div className="pv1"></div>
      </React.Fragment>)}
      <Button width="w-100" outline="none" onClick={() => setIsOpen(false)}>Cancel</Button>
    </>}
  </div>;
}