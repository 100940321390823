import * as COOKIES from '../cookies';
import { methods } from './methods';


export const getClient = ({
  idToken,
  groupId,
  groupToken,
}) => {
  const getAuthHeaders = ({ idTokenOverride, strict = true } = {}) => {
    const token = idTokenOverride || COOKIES.get('id_token');
    if (!token) {
      if (strict) throw new Error('no id_token');
      return {};
    }
  
    const headers = {
      Authorization: token,
    };

    if (groupId) {
      headers['X-Group-Id'] = groupId;
    }
  
    if (groupToken) {
      headers['X-Group-Authorization'] = groupToken;
    }

    if (localStorage.getItem('impersonate_id')) {
      headers['X-Impersonate-Id'] = localStorage.getItem('impersonate_id');
    }
  
    return {
      headers,
    };
  }

  const getAuthQueryParam = ({ idTokenOverride } = {}) => {
    const token = idTokenOverride || COOKIES.get('id_token');
    if (!token) {
      throw new Error('no id_token');
    }

    return `token=${token}`;
  }

  return {
    ...methods(getAuthHeaders, getAuthQueryParam),
    idToken,
  }
}
