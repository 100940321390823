import React from 'react';
import _ from 'lodash';
import AssetLogo from './asset-logo';
import { TinyButton } from './ui';
import arrowRight from '../assets/img/arrow-black.svg';
import checkmark from '../assets/img/checkmark-green.svg';

export default function TemplateCard({ border="light-gray", cta, group, template: t, simulation, onSelectGroup, onSelectTemplate }) {
  const isNew = (new Date()).getTime() - t.createdAt * 1000 < 1000 * 60 * 60 * 24 * 30;
  const isUp = simulation && !simulation[`5y-percent-return`].startsWith('-');

  const onClick = (e) => {
    e.preventDefault();
    if (group && _.isFunction(onSelectGroup)) {
      onSelectGroup(group);
    } else {
      onSelectTemplate(t.id);
    }
  }

  return <div className={`relative ba b--light-gray br5 bg-white mb pv ph tl pointer grow`} style={{paddingBottom: 16, paddingRight: 0}} onClick={onClick}>
    <div>
      <div className="mr">
        <div className="flex flex-row items-center justify-start">
          <div className="bold f4">{t.name}</div>
          {isNew && <div className="bg-pink white ml2 pa1 ph2 br2 f7">New!</div>}
        </div>
        <div className="f6 mid-gray mt2 measure clamp-2">{t.description}</div>
      </div>

      {t.templateCompanies.length > 0 && <div className="flex flex-row justify-start items-center mt3 overflow-scroll pb2">
        {t.templateCompanies.map((tc) =>
          <div key={tc.uuid} className="mr2" style={{flexShrink: 0}}>
            <AssetLogo company={tc.company} size={40} />
          </div>)}
      </div>}
    </div>

    {cta && <>
      {Boolean(group) && <div className="mt">
        <div className="pa3 br3 bg-near-white f7 mb2 flex items-center justify-center">
          <img src={checkmark} className="mr2" style={{width: 20}} />
          You've invested in {t.name} already.
        </div>

        <TinyButton width="w-100">
          <div className="flex flex-row items-center justify-center">
            go to my strategy
            <img src={arrowRight} className="ml1 mt1" style={{width: 20}} />
          </div>
        </TinyButton>
      </div>}

      {!group && <div className="mt mr">
        <TinyButton width="w-100">{cta}</TinyButton>
      </div>}
    </>}

    {Boolean(simulation) && <div className={`absolute bg-purple white br4 pv1 ph2 f7 mono lh-copy shadow-4`} style={{top: -10, right: -10}}>
      {isUp ? '+' : '-'}{(Math.abs(parseFloat(simulation[`5y-percent-return`]) * 100)).toFixed(0)}% over 5y
    </div>}
  </div>;
}