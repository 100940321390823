import React, { useState } from 'react';
import { Button, Input } from './partials/ui';
import { Link } from 'react-router-dom';


export default function ForgotPassword({ client }) {
  const [isSending, setIsSending] = useState(false);
  const [didSend, setDidSend] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = new FormData(e.target);
    try {
      setIsSending(true);
      await client.users.forgotPassword(form.get('email'));
      setError(null);
      setDidSend(true);
    } catch (e) {
      console.log(e);
      setError(e.message);
    } finally {
      setIsSending(false);
    }
  };

  return <div className="absolute absolute--fill flex flex-column items-center justify-center">
    <div className="w-100 flex flex-column items-center align-start" style={{maxWidth: 500}}>
      <form className="w-100-ns w-90" onSubmit={onSubmit}>
        <div className="f2 tc mb5">Forgot Password</div>
        <Input type="email" name="email" required={true} />
        <div className="mt4">
          {didSend && <div className="mb2 f6">
            If this email is associated with an account, we've sent an email to it with a password reset link.
          </div>}
          {error && <div className="mb2 f6">
            We were unable to complete your request. If the problem persists, please contact <a href="mailto:help@useandex.com">support.</a>
          </div>}
          <Button isDisabled={isSending} width="w-100">Send Reset Email</Button>
        </div>
      </form>
      <div className="f6 silver mt3 tc"><Link className="light-silver" to="/sign-in">Back to login.</Link></div>
    </div>
  </div>;
}