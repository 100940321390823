import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import Disclosures from './disclosures';
import { Button, H2, Modal, TinyButton } from '../partials/ui';
import { COOKIES } from '../../utils';
import logo from '../assets/img/logo.svg';
import basketIcon from '../assets/img/nav/basket.svg';
import basketIconActive from '../assets/img/nav/basket-active.svg';
import calendarIcon from '../assets/img/nav/calendar.svg';
import calendarIconActive from '../assets/img/nav/calendar-active.svg';
import portfolioIcon from '../assets/img/nav/chart.svg';
import portfolioIconActive from '../assets/img/nav/chart-active.svg';
import searchIcon from '../assets/img/nav/search.svg';
import searchIconActive from '../assets/img/nav/search-active.svg';
import userIcon from '../assets/img/female-user.svg';
import walletIcon from '../assets/img/wallet.svg';
import walletIconActive from '../assets/img/wallet-active.svg';

export default function Nav({
  background = 'white',
  buttonWidth = 24,
  client,
  footer,
  header,
  links,
  leftButton: LeftButton,
  rightButton: RightButton,
  title,
}) {
  const history = useHistory();
  const location = useLocation();
  const [isDisclosuresOpen, setIsDisclosuresOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const logout = () => {
    COOKIES.remove('id_token');
    COOKIES.remove('user_id');
    history.push('/sign-in');
  }

  useEffect(() => {
    try {
      window.webkit?.messageHandlers.color?.postMessage(background);
    } catch {}
  }, [background]);

  useEffect(() => {
    if (!client) return;
    (async () => {
      try {
        setUser(await client.users.getMe());
      } catch {
        setUser(false);
      }
    })();
  }, [client]);

  useEffect(() => {
    if (!footer) return;
    (async () => {
      try {
        const inbox = await client.notifications.getInbox();
        if (!notifications || notifications.hasNew !== inbox.hasNew) {
          setNotifications(await client.notifications.getInbox());
        }
      } catch {}
    })();
  }, [footer, location]);

  const isAnonymous = user === false;
  const isLoggedIn = Boolean(user); 
  const isHome = location.pathname === '/' || location.pathname === '/portfolio' || location.pathname.startsWith('/groups');

  const navIconSize = 26;

  return <React.Fragment>
    {header !== false && <div className={`w-100 flex dn-ns items-center justify-between pt3 ph bg-${background}`} style={{height: 40}}>
      <div className="flex items-center" style={{width: buttonWidth, height: 24, left: 16, flexShrink: 0}}>{LeftButton && <LeftButton />}</div>
      <div className="center tc w-60">
        {Boolean(title) && <div className="f4 bold tc clamp-2">{title}</div>}
      </div>
      <div className="flex items-center" style={{minWidth: buttonWidth, height: 24, right: 16, flexShrink: 0}}>{RightButton && <RightButton />}</div>
    </div>}
    <div className="fixed bg-white w-100 bb b--light-gray flex-ns dn justify-center z-999" style={{top: 0, height: 70}}>
      <div className="pv1 flex flex-row items-center justify-between-ns justify-center w-100 relative" style={{maxWidth: 700}}>
        {isLoggedIn && <Link to="/">
          <img src={logo} style={{width: 100}} />
        </Link>}
        {isAnonymous && <a href="https://www.tryshare.app">
          <img src={logo} style={{width: 100}} />
        </a>}
        {links !== false && <div className="flex-ns flex-row items-center f5">
          {isAnonymous && <div className="dn db-ns pr4 pointer black hover-silver" onClick={() => setIsDisclosuresOpen(true)}>Disclosures</div>}
          {isAnonymous && <TinyButton onClick={() => history.push(`/sign-in`)}>Sign in</TinyButton>}
          {isLoggedIn && <NavLink exact activeStyle={{textDecoration: 'underline'}} className="dn db-ns pr4 pointer black no-underline hover-silver" to="/portfolio">
            <img src={isHome ? portfolioIconActive : portfolioIcon} style={{height: navIconSize, width: navIconSize}} />
          </NavLink>}
          {isLoggedIn && <NavLink exact activeStyle={{textDecoration: 'underline'}} className="dn db-ns pr4 pointer black no-underline hover-silver" to="/appointments">
            <img src={location.pathname === '/appointments' ? calendarIconActive : calendarIcon} style={{height: navIconSize, width: navIconSize}} />
          </NavLink>}
          {isLoggedIn && <NavLink activeStyle={{textDecoration: 'underline'}} className="dn db-ns pr4 pointer black no-underline hover-silver" to="/search">
            <img src={location.pathname === '/search' ? searchIconActive : searchIcon} style={{height: navIconSize, width: navIconSize}} />
          </NavLink>}
          {isLoggedIn && user.brokerageAccountId && <NavLink activeStyle={{textDecoration: 'underline'}} className="dn db-ns pr4 pointer black no-underline hover-silver" to="/my-account">
            <img src={location.pathname === '/my-account' ? walletIconActive : walletIcon} style={{height: navIconSize, width: navIconSize}} />
          </NavLink>}
          {isLoggedIn && <div className="dn db-ns relative pointer" onClick={() => setNavOpen(!navOpen)}>
            {user && <img className="br3"  src={user.profilePictureUrl || userIcon} style={{height: 36, width: 36}} />}
            {navOpen && <div className="absolute bg-white shadow-5 br2 pa4 f5 ttu" style={{top: 60, left: -140, width: 280}}>
              <div className="mb4 pointer hover-silver" onClick={() => history.push('/profile/me')}>Profile</div>
              <div className="mb4 pointer hover-silver" onClick={() => history.push('/settings')}>Settings</div>
              <div className="mb4 pointer hover-silver" onClick={() => history.push('/faq')}>Help</div>
              <div className="pointer hover-silver" onClick={logout}>Logout</div>
            </div>}
          </div>}
        </div>}
      </div>
    </div>
  {isLoggedIn && footer !== false && <div className="fixed bg-white bottom-0 w-100 flex flex-row items-center justify-around db dn-ns z-999 noselect" style={{height: 80, fontSize: 10}}>
    <div className="w-100 flex items-center">
      <div className={`center tc`}>
        <Link to="/portfolio" className="no-underline">
          <img src={isHome ? portfolioIconActive : portfolioIcon} style={{height: navIconSize, width: navIconSize}} />
        </Link>
      </div>
      <div className={`center tc`}>
        <Link to="/appointments" className="no-underline">
          <img src={location.pathname === '/appointments' ? calendarIconActive : calendarIcon} style={{height: navIconSize, width: navIconSize}} />
        </Link> 
      </div>
      <div className={`center tc`}>
        <Link to="/search" className="no-underline">
          <img src={location.pathname === '/search' ? searchIconActive : searchIcon} style={{height: navIconSize, width: navIconSize}} />
        </Link> 
      </div>
      <div className={`center tc`}>
        <Link to="/my-account" className="no-underline">
          <img src={location.pathname === '/my-account' ? walletIconActive : walletIcon} style={{height: navIconSize, width: navIconSize}} />
        </Link>
      </div>
    </div>
  </div>}
  {isDisclosuresOpen && <Modal position="start">
    <div className="pa4">
      <H2>Disclosures</H2>
      <Disclosures />
      <div className="tc">
        <Button onClick={() => setIsDisclosuresOpen(false)}>done</Button>
      </div>
    </div>
  </Modal>}
  </React.Fragment>
  ;
}