import React, { useEffect, useState } from 'react';

export default function OverlayCard({ children }) {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setHeight('100vh');
  }, []);

  return <>
     <div className="fixed w-100 bg-white bottom-0 z-999 overflow-scroll" style={{height, transition: `height 0.25s ease-out`}}>
      {children}
    </div>
  </>
}