import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { Button, TinyButton } from './ui';
import { MONEY } from '../../utils';
import arrowLeft from '../assets/img/arrow-left.svg';
import xIcon from '../assets/img/x.svg';

export default function Gifts({ client, onDismiss, promo = {}, user }) {
  const history = useHistory();
  const [didCopy, setDidCopy] = useState(false);
  const [gift, setGift] = useState(null);
  const [gifts, setGifts] = useState(null);
  const [userPromos, setUserPromos] = useState(null);

  useEffect(() => {
    if (!user) return;

    (async () => {
      setGifts(await client.gifts.get());
      setUserPromos(await client.userPromos.get({ userId: user.id }));
    })();
  }, [user]);
  
  const copyMessage = () => {
    navigator.clipboard.writeText(`Join Share with my link and we'll both get a ${MONEY.formattedDollars(promo.notional / 100, { wholeDollar: true })} bonus on our next order https://join.tryshare.app/${user.referralCode}`);
    setDidCopy('message');
  }

  const copyReferralCode = () => {
    navigator.clipboard.writeText(`https://join.tryshare.app/${user.referralCode}`);
    setDidCopy('referralCode');
  }

  if (gift) {
    return  (
      <div className="tc mw6 center">
        <div className="tl">
          <img src={arrowLeft} onClick={() => setGift(null)} />
        </div>
        <div className="f3">{String.fromCodePoint(gift.emoji)}</div>
        <div className="mt3 f5 bold lh-copy">
          {gift.fromName} has given you {MONEY.formattedDollars((gift.amount + gift.matchAmount) / 100, { wholeDollar: true })} to start investing!
        </div>
        {Boolean(gift.message) && <div className="mt3">
          &quot;{gift.message}&quot;  
        </div>}
        {!user.brokerageAccountId && <div className="f6 mt3 lh-copy">
          The funds will be automatically deposited into your Share account and available to invest once you complete your account setup. 
        </div>}
        {Boolean(user.brokerageAccountId) && <div className="f6 mt3 lh-copy">
          The funds will be automatically deposited into your Share account and available to invest when your account is approved.
        </div>}

        {Boolean(gift.template) && <div className="f6 mt3 lh-copy">
          {gift.fromName} suggested the <span className="bold">{gift.template.name}</span> strategy for you. You can invest in this strategy, or use the funds to invest in something else.
          <div className="mt3">
            <TinyButton onClick={() => history.push(`/templates/${gift.template.uuid}`)}>view {gift.template.name}</TinyButton>
          </div>
        </div>}
      </div>
    );
  }

  return <div className="w-100 pb4">
    <div className="tr">
      <img src={xIcon} onClick={onDismiss} />
    </div>
    <div className="pt3 tl w-100 tc" style={{maxWidth: 500}}>
      <div className="f1">{String.fromCodePoint(0x1F381)}</div>
      <div className="f3 bold tc mt2">Pay it forward</div>

      <div className="f5 mt3 lh-copy">
        Share will match up to $20 to help someone you care about start investing.
      </div>

      <div className="mt3">
        <Button onClick={() => history.push(`/gifts/new`)}>Give a Gift</Button>
      </div>

      {gifts?.length > 0 && <div className="tl">
        <div className="mt4 mb2 f4 bold tl">My gifts</div>

        <div className="mt2">
          {gifts.map((gift, idx) =>
            <div key={gift.uuid} className="pv3 bb b--light-gray flex items-center justify-between">
              <div>
                <div className="f4 bold">{MONEY.formattedDollars((gift.amount + gift.matchAmount)/100)}</div>
                <div className="mt1 f6 mid-gray">From {gift.fromName}</div>
              </div>
              <TinyButton onClick={() => setGift(gift)}>View</TinyButton>
            </div>
          )}
        </div>
      </div>}
     
      {userPromos?.length > 0 && <div className="tl">
        <div className="mb mt4 f4 bold tl">Your rewards</div>
        {userPromos.map((userPromo, idx) =>
          <div key={userPromo.uuid} className={`ph3 ba br5 b--light-gray flex flex-row items-center justify-between pv`} style={{marginTop: idx > 0 ? -1 : 0}}>
            <div>
              <div>{moment.unix(userPromo.createdAt).format('MMM D, YYYY')}</div>
              <div className="f6 mv2">
                {userPromo.promo.code}  
              </div>
              <div className="f6 mid-gray flex flex-row items-center justify-start">
                <div>{_.startCase(userPromo.status)}</div>
              </div>
            </div>
            <div className="f4 mono">
              {MONEY.formattedDollars(userPromo.promo.notional/100)}
            </div>
          </div>)}
      </div>}
      
    </div>
  </div>;
}