import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Nav from './partials/nav';
import AssetLogo from './partials/asset-logo.js';
import Loading from './partials/loading';
import checkIcon from './assets/img/check-blue.svg';
import plusIcon from './assets/img/plus-circle.svg';
import xIcon from './assets/img/x.svg';
import { BottomCard, Button, Input, TinyButton } from './partials/ui';

export default function AddCompaniesToGroup({ client, onDone }) {
  const history = useHistory();
  const { groupId: id } = useParams();
  const [group, setGroup] = useState(null);
  const [tags, setTags] = useState(null);
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isProposing, setIsProposing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [groupCompanies, setGroupCompanies] = useState([]);
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    (async () => {
      const [
        group,
        groupCompanies,
        proposals,
        tags,
      ] = await Promise.all([
        client.groups.getOne({ id }),
        client.groupCompanies.get({ groupId: id }),
        client.proposals.get({ groupId: id, isLive: true }),
        client.tags.get(),
      ]);

      setGroup(group);
      setGroupCompanies(groupCompanies);
      setProposals(proposals);
      setTags(tags);
    })();
  }, [id]);

  useEffect(() => {
    if (query) {
      (async () => {
        const { companies } = await client.search.companies({ query, limit: 10 });
        setCompanies(companies);
      })();
    } else {
      setCompanies(null);
    }
  }, [query]);

  const isSolo = group?.numMembers === 1;
  const groupCompanyLookup = _.keyBy(groupCompanies, 'ticker');
  const proposalLookup = _.keyBy(proposals, 'ticker');

  const icon = (ticker) => {
    if (_.has(groupCompanyLookup, ticker) || _.has(proposalLookup, ticker)) {
      return <img src={checkIcon} />;
    }
    return <img src={plusIcon} />;
  }

  const clear = () => {
    setIsFocused(false);
    setQuery('');
  }

  const onClickCompany = async (company) => {
    if (_.has(groupCompanyLookup, company.ticker) || _.has(proposalLookup, company.ticker)) {
      return;
    }
    setIsProposing(company);
  }

  const _onDone = () => {
    if (onDone) {
      onDone();
    } else {
      history.replace(`/groups/${id}`);
    }
  }

  if (!group) return <Loading />;
  if (!group.membership) return <Redirect to="/" />;

  return <div className={isProposing ? 'vh-100 overflow-hidden' : ''}>
    <Nav client={client} title="Add Stocks" footer={false} />
    <div className="w-100 ph4 pt6-ns center" style={{maxWidth: 700, paddingBottom: 100}}>
      <div className="relative mb4">
        <input
          type="text"
          onFocus={() => setIsFocused(true)}
          autoComplete="off"
          id="company-search"
          className="mt3 ba br2 b--moon-gray w-100 border-box pa3 f3-ns f5"
          placeholder="Search by ticker or name"
          onChange={e => setQuery(e.target.value)}
          value={query} />
        {isFocused && <img className="absolute right-1" src={xIcon} style={{top: '50%', height: 14}} onClick={clear} />}
      </div>
      {Array.isArray(companies) && <>
        {companies.map(c =>
          <div key={c.ticker} className="pv3 flex flex-row items-center justify-between w-100">
            <div className="mr3">
              <AssetLogo company={c} size={50} onClick={() => history.push(`/companies/${c.ticker}`)} />
            </div>
            <div className="w-60">
              <div className="tl f7 near-black lh-copy">{c.ticker}</div>
              <div className="tl f5 bold near-black lh-copy truncate">{c.displayName}</div>
            </div>
            <TinyButton outline="none" onClick={() => onClickCompany(c)}>
              {icon(c.ticker)}
            </TinyButton>
          </div>)}
      </>}
      {!companies && tags?.map(t =>
        <div key={t.id} className="mb3">
          <div className="f4 dark-gray bold">{t.name} {t.emoji}</div>
          <div className="flex flex-row items-center justify-start mt4 pb3 overflow-scroll" style={{marginRight: -24}}>
            {t.companies.map(c =>
              <div key={c.id} className="flex flex-column items-center justify-center mr3" style={{flexShrink: 0}}>
                <AssetLogo company={c} size={50} onClick={() => history.push(`/companies/${c.ticker}`)} />
                <div className="tc bold mt2">{c.ticker}</div>
                <TinyButton outline="none" onClick={() => onClickCompany(c)}>
                  {icon(c.ticker)}
                </TinyButton>
              </div>)}
          </div>
        </div>)}
    </div>
    <div className="fixed w-100 center bg-white bottom-0 pa dn-ns">
      <Button width="w-100" onClick={_onDone}>Done</Button>
    </div>
    {Boolean(isProposing) && <BottomCard dim onDismiss={() => setIsProposing(false)}>
      <Propose
        client={client}
        company={isProposing}
        group={group}
        isSolo={isSolo}
        onDismiss={() => setIsProposing(null)}
        onPropose={proposal => setProposals([...proposals, proposal])}
        onRate={groupCompany => setGroupCompanies([...groupCompanies, groupCompany])}
      />
    </BottomCard>}
  </div>;
}

function Propose({ client, company, group, isSolo, onDismiss, onPropose, onRate }) {
  const [suggestable, setSuggestable] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setSuggestable(await client.companies.getIsSuggestable({ groupId: group.id, ticker: company.ticker }));
      } catch (err) {
        setError(err.message);
      }
    })();
  }, [company]);

  if (!suggestable) {
    if (error) return <div className="f5 lh-copy red">Error: {error}</div>
    return null;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    try {
      setIsSending(true);
      setError(null);
      if (isSolo) {
        const groupCompany = await client.groupCompanies.create({
          companyUuid: company.uuid,
          groupId: group.id,
          type: 'buy',
        });
        onRate(groupCompany);
      } else {
        const proposal = await client.proposals.create({
          comment: form.get('comment'),
          groupId: group.id,
          ticker: company.ticker,
          type: 'buy',
          weight: 1,
        }); 
        onPropose(proposal);
      }
      onDismiss();
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsSending(false);
    }
  }

  if (!suggestable.isSuggestable) {
    return <div>
      <div className="mb3 f1 tc">
        {String.fromCodePoint(0x1F614)}
      </div>
      <div className="f5 lh-copy mb3">
        Sorry, {company.displayName} is not currently available for fractional share investing.
      </div>
      <Button width="w-100" onClick={onDismiss}>ok</Button>
    </div>
  }

  return <div>
    <div className="flex flex-column items-center justify-center">
      <AssetLogo company={company} />
      <div className="bold mt2">{company.displayName}</div>
    </div>
    <div className="mv3 bold tc">{isSolo ? 'Add' : 'Propose'} to {group.name}</div>
    <form onSubmit={onSubmit}>
      {!isSolo && <Input
        type="textarea"
        name="comment"
        label=""
        placeholder={`Why do you like ${company.displayName}?`} />}
      {error && <div className="mb2 f7 red tc">{error}</div>}
      <Button width="w-100" isDisabled={isSending} data-value="1" data-ga-event="Suggest">
        {isSolo ? 'Add' : 'Propose Buy'}
      </Button>
    </form>
    <div className="mt2">
      <Button width="w-100" isDisabled={isSending} outline="none" onClick={onDismiss}>Cancel</Button>
    </div>
  </div>;
}