import React, { useState } from 'react';
import { Button } from '../partials/ui';
import infoIcon from '../assets/img/info.svg';
import OverlayCard from '../partials/ui/overlay-card';

function InfoCard({ children, onDismiss, title }) {
  return <OverlayCard>
      <div className="w-100 center pa3" style={{maxWidth: 500}}>
        <div className="flex flex-row items-center justify-center pa3">
          <div className="f4 bold tc">{title}</div>
        </div>
        <div className="pt3">
          {children}
          <Button width="w-100" className="mt3" onClick={onDismiss}>ok got it</Button>
        </div>
      </div>
  </OverlayCard>;
}

export default function useInfoCard(title) {
  const [isOpen, setIsOpen] = useState(false);

  return [
    ({ children }) => {
      if (!isOpen) return null;
      return <InfoCard onDismiss={() => setIsOpen(false)} title={title}>
        {children} 
      </InfoCard>;
    },
    () => <img src={infoIcon} onClick={() => setIsOpen(true)} />,
  ];
}